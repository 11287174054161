@import (reference) "styles/theme.less";

.menuContainer {
  .menu {
    background-color: transparent;
    border: none;

    :global(.ant-menu-submenu-expand-icon) {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 10px;
        height: 10px;
        padding-left: 4px;
        padding-top: 3px;
        background-image: url("/icons/icon_triangle.svg");
        background-size: 100%;
        background-position: center;
        border-radius: 50%;
        z-index: 6;
      }
    }

    .label {
      text-transform: uppercase;
      line-height: 12px;
      letter-spacing: 0em;
      font-size: 10px;
      font-weight: 700;
      color: var(--nepal-blue);
    }

    .subMenu {
      margin-bottom: 24px;

      .titleContainer {
        display: flex;
        flex-direction: column;
        width: 175px;
        flex-wrap: wrap;

        .activeItem {
          max-width: 100%;
          text-transform: capitalize;
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          letter-spacing: 0em;
          color: var(--dark-blue);
          margin-top: 3.5px;
        }
      }
    }
  }
}

.multiSelectContainer {
  .menuContainer();
  position: relative;
  margin-bottom: 24px;
  :global(.ant-menu-submenu-title) {
    padding: 0;
    display: flex;
    :global(.ant-menu-title-content) {
      .center();
    }
  }

  .activeItem {
    max-width: 100%;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    color: var(--font-color-eastern-blue);
    margin-top: -12px;
  }

  .menuItem {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .menuItemText {
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0em;
    color: var(--gothic-blue);
    font-size: 10px;
    font-weight: 700;
    color: var(--gothic-blue);
  }

  :global(.ant-checkbox-group) {
    padding-left: 18px;
    label {
      display: flex;
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 8px;
      padding-top: 8px;
      span {
        text-transform: capitalize;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }

  .checkbox {
    display: block;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .checkboxControl {
    .checkbox();
    padding-left: 18px;
    background: var(--alabaster-white);
    border-radius: 12px 12px 0px 0px;
    display: flex;
    &:hover {
      color: var(--primary-color);
    }
  }
}

.multiselectPopup {
  ul {
    display: flex;
    flex-direction: column;

    .menuShow {
      background-color: var(--white) !important;

      :global(.ant-checkbox-group) {
        display: flex;
        height: auto;
        flex-direction: column;
        padding: 8px 12px;

        :global(.ant-checkbox-group-item) {
          margin: 4px 0;
          span {
            text-transform: capitalize;
          }
        }
      }
    }

    :global(.ant-menu-item) {
      height: fit-content;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;