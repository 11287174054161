@import (reference) "styles/theme.less";

.header {
  display: flex;
  height: 48px;
  background: linear-gradient(270.54deg, var(--picton-blue) 49.88%, var(--blue-ribbon) 135.73%);
  width: 100%;
  overflow: hidden;
  padding: 0;
  position: fixed;
  z-index: 1000;

  .row {
    width: 100%;
  }

  .title {
    color: var(--white);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
  }

  .titleCaret {
    .title();
    padding-top: 2px;
  }

  .headerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    :global(.ant-tooltip-inner) {
      width: 367px;
      height: 163.5px;
    }

    .backButton {
      width: 54px;
      height: 22px;
      background: var(--white);
      border-radius: 6px;
      border: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      margin-right: 15px;
      color: var(--blue-ribbon);
      font-weight: 700;
    }

    .title {
      color: var(--white);
      font-size: 16px;
      margin: 0;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease-in-out;
      font-weight: 550 !important;
      letter-spacing: 0.1px;
      @media (max-width: 768px) {
        display: none;
      }
    }

    .logoContainer {
      height: 100%;
      width: 48px;
      .center();
      cursor: pointer;
      transition: 0.2s all;
      margin-right: 6px;

      svg {
        pointer-events: none;
      }
    }

    .logoContainerOpen {
      .logoContainer();
      background-color: var(--dark-blue);
    }
  }

  .activateButtonContainer {
    margin-right: 24px;
  }

  .headerActions {
    display: flex;
    align-items: center;

    > *:last-child {
      margin-left: 4vw;
    }
  }

  .headerRight {
    display: flex;
    align-items: center;
    z-index: 100;
    cursor: pointer;
    position: relative;
    top: 0;
    right: 40px;
    height: 48px;
    min-width: 123px;

    .reminderNotificationsBell {
      margin-right: 6px;
      height: 48px;
      .center();
    }

    .display {
      display: flex;
      align-items: flex-start;
      z-index: 102;
      height: 100%;
      padding: 0 15px 0 10px;
      width: 100%;
      min-width: 132px;
      transition: all 0.3s cubic-bezier(0.7, 0.4, 0.4, 0.6);

      .userDisplay {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;

        .name {
          .title();
          font-family: Poppins;
          font-size: 16px;
          line-height: 50px;
          max-height: 50px;
          margin-left: 8px;
          font-weight: 550;
          letter-spacing: 0.1px;
        }

        @media (max-width: 500px) {
          .name {
            display: none;
          }
        }
      }

      .caretContainer {
        margin-left: 10px;
        padding-bottom: 2px;
        height: 46px;
        .center();
        div {
          height: 46px;

          svg {
            margin-bottom: 7px;
          }
        }
        @media (max-width: 500px) {
          padding-bottom: 0px;
        }
      }
      @media (max-width: 500px) {
        justify-content: flex-end;
        min-width: unset;
      }
    }

    .hoverDisplay {
      background-color: var(--dark-blue);
    }

    @media (max-width: 500px) {
      right: 4px;
    }
  }

  @media (max-width: 500px) {
    padding: 0;
  }
}

.bellContainer {
  height: 100%;
  .center();

  .bellBox {
    .center();
    position: relative;
    width: 32px;
    height: 32px;
    background: rgba(41, 92, 160, 0.25);
    border-radius: 6px;
  }

  .bellBoxTransparent {
    .bellBox();
    background: transparent;
  }

  .counter {
    position: absolute;
    min-width: 12px;
    height: 12px;
    padding: 3px;
    border-radius: 12px;
    background: linear-gradient(45deg, var(--canary-yellow) 0.83%, var(--lime) 100.83%);
    top: 3px;
    left: 18px;
    .center();

    span {
      color: var(--dark-blue);
      font-size: 8px;
      font-weight: 700;
      line-height: 11px;
    }
  }

  // svg {
  //   // margin-bottom: 2px;
  // }
}

.platformPageDropdown {
  background-color: var(--dark-blue);
}

.hide {
  display: none;
}

:global(.interstitialNotification) {
  :global(.ant-notification-notice-message) {
    margin-right: 48px;
  }

  :global(.ant-notification-notice-description) {
    margin-right: 48px;
  }
  // :global(.ant-notification-notice-btn) {
  //   margin-right: 48px;
  // }
}

.logoutDropdownOverlay {
  z-index: 100;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  .menu {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
    cursor: default;
    background-color: var(--dark-blue);
    transform: translateY(-4px);

    .inner {
      padding: 2px 6px 6px 6px;
      display: flex;
      flex-direction: column;
      position: relative;

      .itemRow {
        height: 36px;
        display: flex;
        align-items: center;
        margin: 1px 0;
        color: var(--white);
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s;

        .iconContainer {
          .center();
          border-radius: 6px;
          height: 36px;
          width: 36px;
        }

        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
          margin-left: 11px;
        }

        @media (max-width: 500px) {
          flex-direction: row-reverse;
          span {
            margin-right: 11px;
          }
        }

        &:hover {
          background: var(--dark-navy);
        }
      }

      .itemRowActive {
        .itemRow();
        background: var(--primary-color);

        .iconContainer {
          background: var(--primary-color);
        }
        &:hover {
          background: var(--primary-color);
        }
      }

      @media (max-width: 500px) {
        padding-right: 14px;
        padding-left: 0px;
      }
    }
  }
}

.reminderNotificationsContainer {
  padding: 12px;

  .notificationBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px;
    height: 36px;
    background: var(--white);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    width: 100%;
    margin: 4px 0;
    cursor: pointer;
    border: none;

    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      color: var(--darker-blue);
      padding-bottom: 2px;
      text-transform: capitalize;
    }

    .left {
      display: flex;
      align-items: center;

      .dot {
        height: 12px;
        width: 12px;

        background: linear-gradient(45deg, var(--canary-yellow) 0.83%, var(--lime) 100.83%);
        border-radius: 100%;
        margin-right: 12px;
      }

      .viewedDot {
        .dot();
        background: var(--botticelli-blue);
      }

      .viewedTitle {
        .title();
        font-weight: 400;
        color: var(--darker-blue);
      }
    }

    .right {
      display: flex;
      align-items: center;

      .time {
        .title();
        font-weight: 600;
      }

      .viewedTime {
        font-weight: 400;
        color: var(--gothic-blue);
      }
    }

    &:hover {
      background: var(--springtime-dew);

      .title,
      .viewedTitle,
      .time,
      .viewedTime {
        color: var(--dark-blue);
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;