@import (reference) "styles/theme.less";

.pageContainer {
  height: 100vh;
}

.outerLayout {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  overflow: hidden !important;
}

.topLevelSider {
  z-index: 100;
  min-height: 1200px;
  position: absolute;
  bottom: 0;
  background-color: var(--dark-blue);
}

.innerLayout {
  margin-left: 48px;
  min-height: calc(100vh);
  background: var(--table-edit-mode);

  @media (max-width: 500px) {
    margin-left: 0;
  }
}

.onlyForProject {
  :global(.ant-tabs) {
    width: 100%;
    max-width: 1152px;
    flex-grow: 1;
  }

  @media (min-width: 900px) {
    padding-right: 26px !important;
  }
}

.contentLayout {
  overflow: hidden;
}

.content {
  height: auto;
  overflow-y: auto;
  scrollbar-gutter: stable;
  width: 100%;
  padding: 20px 40px 40px 40px;
  background-color: var(--table-edit-mode) !important;

  @media (max-width: 665px) {
    padding: 20px 10px;
  }
}

.contentWithBackground {
  .content();
  padding: 24px 28px 40px 28px;
  background-position-x: 105%;
  background-position-y: -40px;
  background-repeat: no-repeat;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;