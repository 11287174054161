@import (reference) "styles/theme.less";
html {
  // override the hidden overflow change to html in global.css
  overflow: auto !important;
  // Hide Scrollbar on Firefox, IE and Edge
  -ms-overflow-style: none;
  scrollbar-width: none;
  // Hide Scrollbar on Chrome etc.
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.pageLayout {
  padding-top: 64px;
}

.section {
  width: 100%;
  height: 50vw;
  min-height: 700px;
}

.heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  margin: 40px 0;
  /* identical to box height, or 179% */

  text-align: center;
  text-transform: uppercase;

  color: var(--table-grey);
  &:after {
    position: absolute;
    content: "";
    margin-top: 35px;
    left: 49vw;
    width: 30px;
    height: 3px;
    background-color: var(--primary-lite);
    border-radius: 4px;
  }
}

.subheading {
  font-style: normal;
  font-weight: 100;
  font-size: 28px;
  line-height: 36px;
  width: 68%;
  /* or 129% */

  text-align: center;

  color: var(--table-grey);
}

.counters {
  background-image: url("@{s3-asset-path}/img/ambassador-join/ambassador_tree_bg.png");
  background-position: 50% 0%;
  background-size: cover;
  // opacity: 0.9;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // @media (max-width: 992px) {
  //   max-height: 97px;
  //   line-height: 97px;
  // }
  // @media (max-width: 768px) {
  //   max-height: 82px;
  //   line-height: 82px;
  //   // margin-right: 5px;
  // }
  // @media (max-width: 576px) {
  //   max-height: 75px;
  //   line-height: 75px;
  // }
  // @media (max-width: 480px) {
  //   width: 27px;
  //   max-height: 52px;
  //   line-height: 52px;
  //   margin-right: 3px;
  // }
}

.counterSubtext {
  width: 370px;
  height: 20px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: var(--table-grey);
}

.countersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;