@import (reference) "styles/theme.less";

.header {
  height: 145px;
  background: var(--primary-lite);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  .headerText {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--white);
    padding: 0;
  }

  .headerSubtext {
    max-width: 80%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: var(--white);
    font-weight: 300;
  }
}

.footer {
  height: 116px;
  background: var(--cream);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .footerText {
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: var(--table-grey);
    font-weight: 300;

    .linkContainer {
      display: flex;
      margin: 0 auto;
      width: 350px;
      justify-content: space-around;
    }
  }
}

.submitButton {
  margin-top: 10px;
  height: 40px;
  width: 200px;
  text-transform: uppercase;
  border: none;
  background-color: var(--primary-lite);
  border-radius: 96px;
  font-size: 12px;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;