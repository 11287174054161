@import (reference) "styles/theme.less";

.content {
  padding-top: 50px;
  background-color: rbg(243, 246, 248);

  // Hero Image
  .heroImageContainer {
    position: relative;
    .heroImage {
      width: 100%;
      height: 720px;
      background-image: url("@{s3-asset-path}/img/team-mission/about_Header.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .titleOverlay {
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      z-index: 3;
      display: flex;
      align-items: center;

      .textContainer {
        height: 100%;
        padding-bottom: 132px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        margin: auto 0;
        max-width: 1100px;
        width: 80%;
        margin: 0 auto;
        .mainText {
          font-family: Poppins;
          font-size: 54px;
          font-weight: 600;
          line-height: 50px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--white);
          margin-bottom: 13px;
          text-transform: uppercase;
          width: 100%;
          max-width: 660px;
          @media (max-width: 505px) {
            font-size: 40px;
          }
        }
        .subText {
          font-size: 21px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          color: var(--white);
          width: 100%;
          max-width: 400px;
          @media (max-width: 505px) {
            font-size: 16px;
          }
        }
        .zipButton {
          .center();
          border-radius: 6px;
          text-transform: uppercase;
          background-color: var(--primary-lite);
          height: 40px;
          min-width: 144px;
          border-radius: 96px;
          border: none;
          font-size: 14px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          &:hover {
            background-color: var(--primary-lite-highlighted);
          }
        }
      }
    }
  }

  // SubNav
  .subNavContainer {
    height: 60px;
    max-width: 500px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .subNav {
      width: 500px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .link {
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .navButton {
          cursor: pointer;
          color: var(--lead-black);
          margin-bottom: 4px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0em;
        }
        .underline {
          visibility: hidden;
          height: 3px;
          width: 40px;
          margin: 0 auto;
          border-radius: 2px;
          background-color: var(--picton-blue);
        }
        .navButton:hover + .underline {
          visibility: visible;
        }
      }
      @media (max-width: 500px) {
        max-width: 300px;
      }
    }
  }

  // SectionHeader
  .header {
    width: 100%;
    scroll-margin-top: 48px;
    min-height: 80px;
    background-color: var(--lead-black);
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    .text {
      color: var(--white);
      text-transform: none;
      font-size: 27px;
      font-weight: 300;
      line-height: 36px;
      letter-spacing: 0em;
    }
    @media (max-width: 800px) {
      min-height: 70px;
      .text {
        font-size: 23px;
      }
    }
    @media (max-width: 580px) {
      min-height: 60px;
      .text {
        font-size: 20px;
      }
    }
  }
  // SectionHeader with join button
  .joinHeader {
    .header();
    padding-top: 55px;
    padding-bottom: 47px;
    background-color: var(--tangaroa);
    @media (max-width: 580px) {
      .text {
        padding: 0 20px;
        text-align: center;
      }
    }
    .subText {
      .text();
      padding: 15px 0 27px 0;
      font-weight: 100;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
    }
    .joinButton {
      background-color: transparent;
      color: var(--white);
      border-radius: 96px;
      width: 180px;
      height: 40px;
      text-transform: uppercase;
      margin-bottom: 30px;
      border: 1px solid #f3f6f8;
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
      &:hover {
        color: var(--picton-blue);
        border: 1px solid var(--picton-blue);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  // Leadership
  .leadershipContainer {
    background-color: var(--white);
    .innerContainer {
      width: 90%;
      max-width: 860px;
      margin: 0 auto;
      padding-top: 50px;
      .leadBlurbContainer {
        padding-top: 10px;
        padding-bottom: 50px;
        .leadBlurb {
          text-align: center;
          font-size: 23px;
          font-weight: 300;
          line-height: 30px;
          letter-spacing: 0em;
          color: var(--table-grey);
        }
      }
      .name {
        margin-top: 10px;
        display: block;
        font-weight: 600;
        color: var(--table-grey);
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
      }

      .title {
        display: block;
        margin-top: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0em;
        color: var(--table-badge-unread);
      }

      .professionalLink {
        color: var(--botticelli-blue);
        font-size: 10px;
      }

      .spacer {
        padding: 0 4px;
        color: var(--lead-black);
        font-size: 10px;
        font-weight: 200;
      }

      .JBCardContainer {
        width: 100%;
        display: flex;
        margin-bottom: 30px;
        padding: 0 10px;

        @media (max-width: 950px) {
          flex-direction: column;
          padding: 0;
        }

        .JBImage {
          max-width: 53%;
          border-radius: 3px;
          @media (max-width: 950px) {
            width: 100%;
          }
        }

        .sideText {
          margin: 30px 0 30px 30px;
          @media (max-width: 580px) {
            margin: 20px 0;
          }

          .boldName {
            .name();
            text-transform: uppercase;
            font-size: 16px;
            margin: 0;
            line-height: 25px;
          }

          .boldTitle {
            .title();
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 20px;
            margin-top: 0;
            color: var(--table-badge-unread);
            line-height: 23px;
          }

          .paragraph {
            .boldTitle();
            font-size: 14px;
            font-weight: 200;
            margin-bottom: 16px;
          }
        }
      }

      .userCardContainer {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        .userCard {
          flex: 1 0 21%;
          max-width: 25%;
          margin-bottom: 50px;
          display: flex;
          flex-direction: column;
          @media (max-width: 800px) {
            flex: 1 0 30%;
            max-width: 32%;
          }
          @media (max-width: 580px) {
            flex: unset;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            max-width: unset;
          }
          .imageContainer {
            display: flex;
            justify-content: center;
            width: 100%;

            .userImage {
              width: calc(100% - 20px);
              border-radius: 3px;
            }
          }
          .textContainer {
            width: 100%;
            padding-left: 14px;
            @media (max-width: 580px) {
              margin-top: 10px;
              padding-left: 0;
            }
            .name {
              text-transform: uppercase;
            }
            .title {
              width: 90%;
              line-height: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  // Mission
  .missionContainer {
    background-color: var(--lightest-grey);
    .innerContainer {
      width: 60%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 1080px) {
        width: 80%;
      }
      .verticalLine {
        width: 2px;
        background-color: var(--light-grey);
        height: 60px;
        float: left;
      }
      section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 18px 0;
        .boldTitle {
          color: var(--lead-black);
          text-transform: uppercase;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: 0em;
        }
        .vision {
          margin: 0;
          color: var(--table-grey);
          text-transform: none;
          text-align: center;
          font-size: 26px;
          font-weight: 300;
          line-height: 30px;
          letter-spacing: 0em;
          &:first-of-type {
            margin-bottom: 12px;
          }
          @media (max-width: 1080px) {
            font-size: 23px;
          }
        }
      }
      .valuesContainer {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        @media (max-width: 580px) {
          display: block;
        }
        .value {
          margin: 12px 0;
          flex: 1 0 48%;
          color: var(--table-grey);
          min-width: 80px;
          font-size: 26px;
          font-weight: 300;
          line-height: 30px;
          letter-spacing: 0em;
          @media (max-width: 1080px) {
            flex: unset;
            display: block;
            padding: 8px;
            width: 100%;
            margin: 8px 0;
            font-size: 23px;
          }
        }
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;