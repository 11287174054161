@import (reference) "styles/theme.less";

.getQuoteModal {
  width: 720px !important;

  :global(.ant-input-number) {
    border: none;
    border-bottom: 2px solid black;
    caret-color: var(--primary-lite);
    :global(.ant-input-number-input) {
      color: var(--table-grey);
      text-align: center;
      font-family: Poppins;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 30px;
      text-align: center;
    }
  }
  :global(.ant-input-number-focused) {
    box-shadow: none;
  }
  :global(.ant-modal-content) {
    border-radius: 8px;
  }
  :global(.ant-input-number-handler-wrap) {
    display: none;
  }
  .formContainer {
    font-family: "Poppins", Sans-serif;
    background-color: var(--white);
    border-radius: 8px 8px 8px 8px;
    text-align: left;

    .inner {
      padding: 60px;
      padding-top: 0px;
      @media (max-width: 1024px) {
        padding: 20px;
        padding-top: 0px;
      }
    }

    .quoteForm {
      padding: 10px 10px;
    }

    .sectionLabel {
      display: flex;
      justify-content: center;
    }

    .sectionNumber {
      .sectionLabel();
      padding-top: 36px;
    }

    .label {
      color: var(--mineshaft-gray);
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.3;
      margin: 0 0 0 4px;
      padding: 0;
      text-transform: uppercase;
      padding-top: 10px;
      text-align: center;
    }

    .billSection {
      margin-bottom: 20px;
    }

    .billInput {
      width: 170px;
    }

    .billLabel {
      .label();
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 9px;
      padding-top: 8px;
    }

    .inputLabel {
      font-weight: bold;
      font-size: 10px;
      line-height: 9px;
      color: var(--hit-gray);
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .inputPrefix {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .emailIcon {
      color: var(--botticelli-blue);
      font-size: 20px;
    }

    .phoneIcon {
      .emailIcon();
      transform: rotate(98deg);
    }

    .underline {
      background: var(--primary-lite);
      width: 36px;
      height: 3px;
      margin-top: 8px;
      margin-bottom: 20px;
    }

    .rightLabel {
      .label();
      margin-left: 4%;
    }

    .centeredLabel {
      .label();
      text-align: center;
    }

    .formItem {
      margin: 0px 8px;
      @media (max-width: 600px) {
        margin: 0px 4px;
        padding-bottom: 10px;
      }
      :global(.ant-input-group-addon) {
        border: 1px solid var(--botticelli-blue);
        border-right: none;
        background-color: transparent;
        border-radius: 3px 0px 0px 3px;
      }

      input {
        border: 1px solid var(--botticelli-blue);
        border-radius: 0px 3px 3px 0px;
        &:focus {
          border: 1px solid var(--primary-lite);
          box-shadow: none;
        }
      }

      .input {
        border: 1px solid var(--botticelli-blue);
        border-radius: 3px 3px 3px 3px;
        &:focus {
          border: 1px solid var(--primary-lite);
          box-shadow: none;
        }
      }
    }

    .inputRow {
      padding-top: 6px;
      padding-bottom: 20px;
      @media (max-width: 600px) {
        padding-bottom: 2px;
      }
    }

    .buttonContainer {
      margin: auto;
      padding-top: 10px;
      padding-bottom: 30px;
      height: 100px;
    }

    .footerText {
      font-weight: 300;
      color: var(--dusty-gray);
      font-family: Oswald;
      font-size: 12px;
      line-height: 16px;
    }

    .centeredCol {
      display: flex;
      justify-content: center;
    }

    .radioCol {
      width: 100%;

      .inputRow();
      margin: auto;
      .radioFormItem {
        margin: auto;
      }

      .radioContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
      }

      .container {
        display: block;
        position: relative;
        margin-bottom: 10px;
        margin-top: 6px;
        width: 100px;
        height: 30px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .radioLabel {
          font-size: 14px;
          line-height: 25px;
          font-weight: 300;
          margin-top: 3px;
          position: absolute;
          top: 0;
          left: 30px;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        input:checked ~ .checkmark {
          background-color: var(--primary-lite);
          border: none;
        }

        input:checked ~ .checkmark:after {
          display: block;
        }

        .checkmark:after {
          left: 6px;
          top: 2px;
          width: 7px;
          height: 12px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .checkmark {
        position: absolute;
        top: 4px;
        left: 0;
        border: 0.5px solid var(--loblolly-gray);
        border-radius: 2px;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        text-align: center;
        &:hover {
          border: 1px solid var(--primary-lite);
        }
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }
  .unavailableContainer {
    .formContainer();
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .unavailableText {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      margin: 57px 20px 0px 20px;
      text-align: center;
    }
  }
}

.outerCircle {
  width: 50px;
  height: 50px;
  left: 551px;
  top: 280px;
  background: var(--primary-lite);
  border-radius: 39px;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-family: Poppins;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    color: var(--white);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;