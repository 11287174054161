@import (reference) "styles/theme.less";

.billModal {
  width: 720px !important;

  :global(.ant-modal-content) {
    border-radius: 8px;
  }

  .container {
    font-family: "Poppins", Sans-serif;
    background-color: var(--white);
    border-radius: 8px 8px 8px 8px;
    text-align: left;
  }

  .inner {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .paragraph {
    text-align: center;
    max-width: 80%;
    font-size: 16px;
    line-height: 23px;
    margin: 60px auto 24px auto;
  }

  .largeText {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--primary-lite);
    margin-bottom: 50px;
  }

  .button {
    height: 54px;
    width: 280px;
    text-transform: uppercase;
    background-color: var(--primary-lite);
    border-color: var(--primary-lite);
    border-radius: 96px;
    font-size: 18px;
    font-weight: 500;
    // line-height: 28px;
    margin-bottom: 62px;
    color: var(--white);

    &:hover {
      background-color: var(--primary-lite-highlighted);
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;