@import (reference) "styles/theme.less";

.join {
  padding-top: 64px;
  .contentContainer {
    .buttonStyle {
      height: 50px;
      text-transform: uppercase;
      border-radius: 0;
    }

    .headerText {
      color: var(--text-dark);
      color: var(--white);
      font-size: 24pt;
      font-weight: 400;
      line-height: 28pt;
      padding-bottom: 25px;
      text-align: center;
      text-align: left;
    }

    .subHeaderText {
      text-align: center;
      font-size: 18pt;
      line-height: 22pt;
      font-weight: 300;
      color: var(--text-dark);
      text-align: left;
      color: var(--white);
    }

    .bodyText {
      font-size: 12pt;
      font-weight: 300;
      text-align: left;
      color: var(--white);
    }

    .header,
    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      background-color: var(--primary-color);

      .title {
        color: var(--white);
        font-size: 14pt;
        text-transform: uppercase;
        font-weight: 100;
        margin: 0;
        .bold {
          font-weight: 600;
        }
      }

      &.header {
        justify-content: space-between;

        .linkContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 50%;

          .link {
            .title {
              font-size: 14px;
              line-height: 1.5;
              font-weight: 500;
              padding: 0px 16px;
            }
          }
        }
      }
    }

    .heroImage {
      height: 720px;
      position: relative;

      .videoContainer {
        width: 100%;
        height: 100%;
        z-index: 2;
        overflow: hidden;

        .video {
          object-fit: cover;
          object-position: center top;
          position: relative;
          height: 100%;
          width: 100%;
        }
      }

      .background {
        background-repeat: no-repeat;
        background-position-x: 50%;
        background-size: cover;
        background-image: url("@{s3-asset-path}/img/join/powur-consultant_carousel_screenshot.jpg");
        width: 100%;
        height: 100%;
        position: absolute;
      }

      .heroOverlay {
        position: absolute;
        width: 100%;
        height: 720px;
        padding-top: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 3;

        .heroText {
          color: var(--white);
          text-align: center;
          width: 60%;
          font-family: Poppins;
          font-size: 54px;
          font-weight: 600;
          line-height: 50px;
          letter-spacing: 0em;
          margin-bottom: 14px;
          text-transform: uppercase;

          @media (max-width: 800px) {
            font-size: 40px;
          }

          @media (max-width: 580px) {
            font-size: 30px;
          }
        }

        .heroSubText {
          .heroText();
          text-transform: unset;
          margin-bottom: 40px;
          font-size: 21px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          text-align: center;

          @media (max-width: 800px) {
            font-size: 18px;
          }

          @media (max-width: 580px) {
            font-size: 16px;
          }
        }

        .joinButton {
          height: 40px;
          width: 208px;
          border-radius: 96px;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;
        }

        .watchButton {
          .joinButton();
          background: rgba(0, 0, 0, 0.15);
          border: 1px solid var(--white);
          margin-top: 20px;
          margin-bottom: 110px;
          &:hover {
            border-color: var(--primary-lite);
            color: var(--primary-lite);
          }
        }
      }
    }

    .section {
      min-height: 400px;
      height: 50vw;
      display: flex;
      flex-wrap: wrap;
      &:nth-child(odd) {
        flex-direction: row-reverse;
      }
      @media (max-width: 900px) {
        min-height: 520px;
        height: unset;
        align-items: flex-start;
      }
      .imageContainer {
        width: 65%;
        @media (max-width: 1150px) {
          width: 60%;
        }
        @media (max-width: 900px) {
          flex: unset;
          width: 100%;
          height: 300px;
        }
        .image {
          background-repeat: no-repeat;
          background-position: 0px 0px;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 100%;
        }
      }
      .textContainerOuter {
        width: 35%;
        position: relative;
        overflow: hidden;
        @media (max-width: 1150px) {
          width: 40%;
        }
        @media (max-width: 900px) {
          width: 100%;
          flex: unset;
          height: 300px;
        }
        .angledAccent {
          width: 120%;
          height: 211px;
          background-color: var(--cultured-white);
          position: absolute;
          transform: rotate(-9deg);
          top: -168px;
          right: -28px;
          @media (max-width: 1150px) {
            height: 203px;
          }
        }
        .angledAccentBottom {
          .angledAccent();
          transform: rotate(-11deg);
          top: unset;
          left: unset;
          bottom: -168px;
          right: -28px;
        }
        .textContainer {
          height: 100%;
          padding: 0 134px 0 72px;
          @media (max-width: 1150px) {
            padding: 0 104px 0 52px;
          }
          @media (max-width: 400px) {
            padding: 0 40px 0 30px;
          }
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;

          .textRow {
            .contentHeader {
              color: var(--lead-black);
              text-transform: uppercase;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 700;
              line-height: 25px;
              letter-spacing: 0em;
            }
            .underline {
              height: 3px;
              width: 40px;
              border-radius: 1px;
              background-color: var(--primary-color);
              margin: 11px 0 18px 0;
            }
            .contentSubHeader {
              margin-bottom: 18px;
              color: var(--lead-black);
              font-size: 23px;
              line-height: 29px;
              letter-spacing: 1px;
              font-size: 28px;
              font-weight: 300;
              line-height: 36px;
              letter-spacing: 0em;
            }
            .bodyText {
              color: var(--table-grey);
              font-size: 14px;
              font-weight: 400;
              line-height: 23px;
              letter-spacing: 0em;
            }
          }
        }
      }
    }
    .extraMargin {
      background-color: var(--cultured-white);
      height: 70px;
      width: 100%;
    }

    .powurIntroRow {
      .section();
      &:nth-child(odd) {
        flex-direction: unset;
      }
      background-color: var(--tangaroa);
      min-height: 289px;
      height: 289px;
      display: flex;
      align-items: center;
      @media (max-width: 992px) {
        min-height: 340px;
        padding-top: 60px;
        align-items: flex-start;
      }
      .header {
        color: var(--white);
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        background-color: transparent;
        text-transform: uppercase;
      }
      .underline {
        height: 3px;
        width: 40px;
        border-radius: 1px;
        background-color: var(--primary-color);
        margin-bottom: 20px;
      }
      .subHeader {
        min-width: 335px;
        font-size: 28px;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: 0em;
        color: var(--white);
        margin-bottom: 20px;
      }
      .buttonContainer {
        display: flex;
        flex-direction: column;
        height: 80%;
        justify-content: center;
        align-items: flex-start;
        .button {
          .buttonStyle();
          width: 80%;
          height: 40px;
          width: 234px;
          border-radius: 96px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          font-size: 12px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          @media (max-width: 1200px) {
            width: 100%;
          }
        }
        .infoButton,
        .linkButton {
          .button();
          color: var(--primary-color);
          border-color: var(--primary-color);
          max-width: 234px;
        }

        .primaryButton {
          .button();
          color: var(--white);
          margin-bottom: 18px;
          max-width: 234px;
        }

        .linkButton {
          .button();
          border-color: var(--white);
          color: var(--white);
          background: transparent;
          &:hover {
            color: var(--primary-lite);
            border-color: var(--primary-lite);
          }
        }
      }
      .imgContainer {
        height: 100%;
        position: relative;
        cursor: pointer;
        img {
          position: absolute;
          height: 120%;
          width: auto;
          left: -40px;
          top: -30px;
        }
        @media (max-width: 1220px) {
          img {
            left: 80px;
          }
        }
        @media (max-width: 992px) {
          display: none;
        }
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;