.verificationContainer {
  width: 100%;
  height: 325px;
  padding: 0px 25px;

  .verificationLabel {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 20px;
    color: var(--blue-ribbon);
  }

  .customPasswordInput {
    border: none;
    border-radius: 3px;
    height: 45px;
    padding: 10px 15px;
  }
  ::placeholder {
    font-family: "Barlow";
    font-weight: 500;
    font-size: 16px;
    color: var(--trolley-grey);
  }

  .passwordInput {
    margin: 5px 0px;

    :global(.ant-input-affix-wrapper) {
      .customPasswordInput();

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .passwordRepeat {
    margin: 5px 0px;

    :global(.ant-input) {
      .customPasswordInput();
    }
  }

  :global(.ant-form-item-with-help .ant-form-item-explain) {
    min-height: 0px;

    :global(.ant-form-item-explain-error) {
      font-size: 10px;
      position: absolute;
      margin-right: 5px;
      text-align: right;
      width: 100%;
    }
  }

  .passwordRuleList {
    margin: 12px 0px;

    .passwordRule {
      margin: 3px;
      font-family: "Poppins";
      color: var(--dark-grey);
      display: flex;
      align-items: center;

      .validationIcon {
        margin: 0px 10px;
        display: flex;
      }
    }
  }

  .passwordSubmit {
    margin-top: 20px;
    width: 150px;
    height: 45px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;