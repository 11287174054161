@import (reference) "styles/theme.less";

.heroImage {
  height: 720px;
  position: relative;

  .videoContainer {
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;

    .video {
      object-fit: cover;
      object-position: center top;
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .background {
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: cover;
    background-image: url("@{s3-asset-path}/img/ambassador-join/ambassador_header.png");
    width: 100%;
    height: 100%;
    position: absolute;
    @media (min-width: 1600px) {
      background-position-y: bottom;
    }
  }

  .heroOverlay {
    position: absolute;
    width: 100%;
    height: 720px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;

    .heroText {
      color: var(--white);
      text-align: center;
      width: 80%;
      font-family: Poppins;
      font-size: 54px;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: 0em;
      margin-bottom: 14px;
      text-transform: uppercase;

      @media (max-width: 800px) {
        font-size: 40px;
      }

      @media (max-width: 580px) {
        font-size: 30px;
      }
    }

    .heroSubText {
      .heroText();
      text-transform: unset;
      margin-bottom: 40px;
      font-size: 21px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;

      @media (max-width: 800px) {
        font-size: 18px;
      }

      @media (max-width: 580px) {
        font-size: 16px;
      }
    }

    .joinButton {
      height: 40px;
      width: 208px;
      border-radius: 96px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
    }

    .watchButton {
      .joinButton();
      background: rgba(0, 0, 0, 0.15);
      border: 1px solid var(--white);
      margin-top: 20px;
      margin-bottom: 110px;
      &:hover {
        border-color: var(--primary-lite);
        color: var(--primary-lite);
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;