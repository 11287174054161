@import (reference) "styles/theme.less";

.billModal {
  width: 720px !important;

  :global(.ant-modal-content) {
    border-radius: 8px;
  }

  .container {
    font-family: "Poppins", Sans-serif;
    background-color: var(--white);
    border-radius: 8px 8px 8px 8px;
    text-align: left;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .headerBg {
    padding-top: 40px;
    width: 100%;
    height: 248px;
    background-color: var(--primary-lite);
    border-radius: 8px 8px 0px 0px;

    @media (max-width: 700px) {
      height: 300px;
    }

    @media (max-width: 400px) {
      height: 340px;
    }
  }

  .header {
    justify-content: start;
    background: inherit;

    .headerText {
      color: var(--white);
      padding-bottom: 2px;
    }

    .headerSubtext {
      color: var(--white);
      max-width: 70%;
    }
  }

  .image {
    max-width: 245px;
    max-height: 260px;
    width: 245px;
    height: 260px;
    background-image: url("/icons/icon_bill.svg");
    margin-top: 40px;
    margin-bottom: 20px;
    position: absolute;
    top: 100px;

    @media (max-width: 700px) {
      top: 150px;
    }

    @media (max-width: 400px) {
      top: 180px;
    }
  }

  .paragraph {
    text-align: center;
    max-width: 80%;
    font-size: 16px;
    line-height: 23px;
    margin: 140px auto 50px auto;
  }

  .text {
    text-align: center;
    max-width: 80%;
    font-size: 16px;
    line-height: 23px;
    margin: 24px auto 44px auto;
  }

  .button {
    height: 54px;
    width: 320px;
    color: var(--white);
    text-transform: uppercase;
    background-color: var(--primary-lite);
    border-color: var(--primary-lite);
    border-radius: 96px;
    font-size: 16px;
    font-weight: 500;
    // line-height: 28px;
    margin-bottom: 42px;

    &:hover {
      background-color: var(--primary-lite-highlighted);
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;