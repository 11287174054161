@import (reference) "styles/theme.less";
@import "~animate.less/animate.less";

.hover() {
  border: 1px solid #0000;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%) border-box;
  box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.15);

  span {
    color: var(--picton-blue);
    // background: black;
    font-weight: 600;

    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.gradient {
  // border-image-source: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
  min-width: 32px;
  height: 32px;
  padding: 0px 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #0000;
  border-radius: 6px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%) border-box;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  text-shadow: none;

  span {
    color: var(--picton-blue);
    background: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    transform: translate(0px, -1px);
  }

  &:disabled {
    span {
      -webkit-text-fill-color: unset;
      -webkit-background-clip: unset;
      background-clip: unset;
      background: unset;
      color: var(--dark-blue);
    }
  }

  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover {
    .hover();
  }
  &:active {
    .hover();
  }
  &:focus {
    .hover();
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;