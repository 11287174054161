@import (reference) "styles/theme.less";

.drawerContainer {
  :global(.ant-drawer-content-wrapper) {
    box-shadow: -4px 0px 40px rgba(0, 0, 0, 0.2) !important;
  }

  :global(.ant-drawer-body) {
    height: 100vh;
    overflow: hidden;
  }

  .drawerLayout {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: var(--table-edit-mode);

    .header {
      height: 54px;
      width: 100%;
      padding: 0 12px;
      background: linear-gradient(270.54deg, var(--picton-blue) 49.88%, var(--blue-ribbon) 135.73%);
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 425px) {
        padding: 0 15px;
        width: 100%;
      }

      .icon {
        .title();
        margin-right: 10px;
      }
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 25px;
      padding-top: 20px;
      padding-bottom: 40px;
      overflow-y: auto;
      scrollbar-color: var(--picton-blue) transparent;
    }

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--picton-blue);
      border-left: 7px solid transparent;
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-thumb:hover {
      border-radius: 10px;
      border: none;
    }

    .drawerFooter {
      .header();
      position: fixed;
      bottom: 0;
      width: 700px;
      background-color: rgb(250, 251, 252, 0.3);

      .buttonContainer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.detailsHeader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .detailTopRow {
    margin-bottom: 3px;
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .detailBottomRow {
    .detailTopRow();
    height: 12px;
    align-items: flex-start;
  }
}

.title {
  font-size: 14pt;
  color: var(--white);
  margin-bottom: 0;
}

.detailTitle {
  .title();
  line-height: 14pt;
}

.detail {
  font-size: 11px;
  line-height: 14px;
  height: 100%;
  color: var(--white);
}

.handsomeHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: transparent;
  width: 100%;
  padding: 0px 10px;

  .close {
    .center();
    cursor: pointer;
    height: 100%;
    margin-right: 15px;
  }

  .title {
    color: var(--white);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    margin-left: 15px;

    span {
      font-weight: 300;
      margin: 0 4px;
    }

    &blue {
      color: var(--blue-ribbon);
      line-height: 16px;
      text-align: center;
    }
  }
  .subtitle {
    .title();
    margin: 0;
  }

  .subtitleBlue {
    color: var(--blue-ribbon);
    line-height: 16px;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    margin-bottom: 0px;
  }

  .backButton {
    position: relative;
    display: flex;
    justify-content: center;
    border: none;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    background: var(--adamantine-blue);
    border-radius: 21px;
    width: 79px;
    height: 26px;
    color: var(--white) !important;
    > .arrow {
      margin-top: 2px;
      margin-right: 12px;
      transform: rotate(180deg);
    }
  }
  .rightTextNumber {
    display: flex;
    position: absolute;
    right: 110px;
    justify-content: center;
    align-items: center;
    border: none;
    color: var(--white) !important;
    font-size: 26px;
    font-weight: bold;
  }
  .rightTextContent {
    display: flex;
    position: absolute;
    right: 27px;
    justify-content: center;
    align-items: center;
    border: none;
    color: var(--white) !important;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    word-wrap: break-word;
    line-height: 1;
    width: 80px;
    height: 38px;
    text-align: left;
  }
  .rightAction {
    display: flex;
    position: absolute;
    right: 10px;
    justify-content: center;
    align-items: center;
    border: none;
    width: 114px;
    height: 38px;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    background: var(--slashee-blueberry);
    border-radius: 21px;
    color: var(--white) !important;
    > span {
      white-space: break-spaces;
      width: 50px;
      text-align: left;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;