@import (reference) "styles/theme.less";

.subHeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 24px 0;

  .subHeader {
    text-transform: uppercase;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
  }

  .underline {
    height: 3px;
    width: 36px;
    border-radius: 1px;
    background-color: var(--blue-ribbon);
    margin-top: 6px;
  }
}

.formContainer {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;

  :global(.ant-form-item-with-help .ant-form-item-explain) {
    min-height: 0px;

    :global(.ant-form-item-explain-error) {
      position: absolute;
      font-size: 10px;
      width: 100%;
      text-align: right;
      flex-wrap: wrap;
      margin-top: -5px;
    }
  }

  :global(.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
      .ant-select-selection-search-input) {
    padding: 0 11px;
    font-weight: 500;
  }

  :global(.ant-select-single .ant-select-selector .ant-select-selection-search) {
    right: 0px;
    left: 0px;
  }

  .checkboxGroup {
    :global(.ant-checkbox-group) {
      width: 100%;
    }

    :global(.ant-checkbox + span) {
      line-height: 17px;
    }

    :global(.ant-checkbox-inner) {
      border: 1px solid var(--nepal-blue);
    }
  }
  .checkboxBottomRow {
    margin-top: -13px;
    .checkboxGroup();
  }

  .inputLabel {
    font-size: 10px;
    font-weight: 700;
    line-height: 9px;
    letter-spacing: 0em;
    color: var(--dark-periwinkle);
    text-transform: uppercase;
  }

  .inputSubLabel {
    .inputLabel();
    font-size: 14px;
    line-height: 17px;
    color: var(--table-grey);
    font-weight: 500;
    text-transform: none;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  :global(.ant-input-group-addon) {
    background-color: var(--white);
    width: 42px;
    border-top: 1px solid var(--botticelli-blue);
    border-left: 1px solid var(--botticelli-blue);
    border-bottom: 1px solid var(--botticelli-blue);
  }

  .inputPrefix {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input {
    color: var(--dark-blue);
    margin-top: 3px;
    margin-bottom: 20px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    caret-color: var(--primary-lite);
    border: 1px solid var(--botticelli-blue);
    margin-bottom: 8px;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 7%);

    input {
      height: 36px;
      border: 1px solid var(--botticelli-blue);
    }
  }

  .iconInput {
    .input();
    border: none;

    :global(.ant-input) {
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0em;
      color: var(--dark-blue);
    }
  }

  .select {
    .input();
    border: none;
    margin-bottom: 10px;

    :global(.ant-select-arrow) {
      height: 100%;
      width: 42px;
      top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      border-left: 1px solid var(--botticelli-blue);
    }

    :global(.ant-select-selector) {
      border: 1px solid var(--botticelli-blue);
    }

    :global(.ant-select-selection-item) {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
  }

  .stateSelect {
    .select();
    :global(.ant-select-selection-item) {
      border-radius: 4px;
      border: none;
      background: rgba(0, 112, 244, 0.07);
    }
  }

  .city {
    padding: 0px 10px 0px 0px;
  }

  .state {
    padding: 0px 10px 0px 10px;
  }

  .zip {
    padding: 0px 0px 0px 10px;
  }

  .addButtonContainer {
    display: flex;
    justify-content: center;

    .addButton {
      color: var(--blue-ribbon);
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding: 0 12px;
      height: 22px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      border-color: var(--blue-ribbon);
      width: 127px;
      height: 22px;

      &:hover {
        background: var(--table-edit-mode);
      }

      .icon {
        margin-right: 5px;
      }
    }
  }

  .fieldsContainer {
    background: var(--table-edit-mode);
    border-radius: 3px;
    padding: 12px;
    padding-bottom: 14px;
    margin-bottom: 18px;

    .row {
      margin-top: 12px;

      &:first-of-type {
        margin-top: 0px;
      }

      input {
        padding-left: 10px;
        box-shadow: 0px 2px 6px rgb(0 0 0 / 7%);
        border-radius: 4px;
      }
    }

    :global(.ant-input-affix-wrapper) {
      padding: 0;
    }

    :global(.ant-input-prefix) {
      margin: 0;
    }

    .positionHeldRow {
      .row();
      :global(.ant-form-item-with-help .ant-form-item-explain) {
        min-height: 0px;

        :global(.ant-form-item-explain-error) {
          position: absolute;
          font-size: 10px;
          width: 100%;
          text-align: right;
          flex-wrap: wrap;
          margin-top: 0px;
        }
      }
    }
  }
}

.laborCheckbox {
  width: 80%;
  margin: 0 auto;
  padding: 30px 25px 0px 25px;
  font-size: 14px;
  color: var(--table-grey);
}

.confirmationText {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: var(--table-grey);
  margin: 10px;

  .boldText {
    font-weight: 700;
  }

  .confirmEmail {
    margin: 15px;
  }
}

.cancelButton {
  margin-top: 20px;
  color: var(--primary-lite);
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.confirmation {
  width: 100%;
  padding: 75px 10% 50px 10%;
  .center();
  font-size: 16px;
  color: var(--table-grey);
  font-weight: 200;
  text-align: center;
}

.laborPartnerCard {
  display: flex;
  padding: 15px;
  border-radius: 8px;
  width: 65%;
  margin: 10px;
}

.iconContainer {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.promptCard {
  .laborPartnerCard();
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 161, 207, 0.2);

  .iconContainerBlue {
    background-color: var(--primary-lite);
    .iconContainer();
  }

  .laborPartnerPrompt {
    font-size: 16px;
    color: var(--darker-blue);
    margin: 5px;
  }

  .laborPartnerLink {
    color: var(--font-color-eastern-blue);
    font-weight: 700;
    cursor: pointer;
  }
}

.continueCard {
  .laborPartnerCard();
  padding: 18px;
  background-color: var(--primary-lite);
  justify-content: space-between;

  .iconContainerWhite {
    .iconContainer();
    background-color: var(--white);
    height: 56px;
    min-width: 56px;
    margin-right: 18px;
  }

  .detailsTitle {
    text-transform: uppercase;
    font-weight: 700;
    color: var(--light-cyan);
    text-align: left;
    padding-left: 6px;
  }

  .detailsLink {
    display: flex;
    font-weight: 700;
    color: var(--white);
    cursor: pointer;
    text-align: left;

    .arrow {
      margin: 5px 0px 0px 18px;
    }
  }
}

.laborAppConfirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 300;
  color: var(--table-grey);

  .details {
    width: 85%;
    text-align: center;
    margin: 30px;
  }
}

.buildPartnerFormContainer {
  .formContainer();

  .radioGroup {
    margin-bottom: 10px;

    :global(.ant-radio-inner) {
      border-color: var(--nepal-blue);
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;