.chevronVertical {
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  transition: 0.2s all;
}
.chevronVerticalRotated {
  .chevronVertical();
  transform: rotate(180deg);
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;