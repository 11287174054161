@import (reference) "styles/theme.less";

.header {
  height: 64px;
  line-height: 50px;
  width: 100vw;
  background-color: var(--dark-charcoal);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 101;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 475px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.innerContainer {
  height: 50px;
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
}

.linksCol {
  height: 50px;
  display: flex;
  align-items: center;
  padding-top: 22px;

  @media (max-width: 865px) {
    width: 100%;
    justify-content: space-between;
  }

  div,
  a {
    margin: 0 24px;
    white-space: nowrap;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }

    @media (max-width: 1200px) {
      margin: 0 16px;
    }

    @media (max-width: 768px) {
      margin: 0 8px;
    }
  }
}

.agentTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 24px;
  height: 104%;

  .label {
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0.25px;
    color: var(--silver);
    white-space: nowrap;
    display: block;
  }

  .agentName {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    color: var(--white);
    width: 100%;
    text-align: justify;
    max-width: 120px;
  }

  .agentTitle {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 0;
  }

  .caretContainer {
    display: flex;
    align-items: center;
    height: 30px;
  }
}

.agentCard {
  position: absolute;
  top: 60px;
  right: 70px;
  background-color: var(--gallery-white);
  border-radius: 8px;
  height: 66px;
  min-width: 144px;
  padding: 8px 12px;
  transition: all 0.3s;

  .label {
    font-size: 9px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    color: var(--dark-charcoal);
    text-transform: uppercase;
    display: block;
    margin-bottom: 4px;
  }

  .contact {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    color: var(--primary-lite);
    display: block;
    margin-bottom: 1px;
  }

  @media (max-width: 475px) {
    right: 50px;
  }
}

.agentCardWithOrg {
  position: absolute;
  top: 60px;
  right: 70px;
  background-color: var(--gallery-white);
  border-radius: 8px;
  height: 115px;
  min-width: 150px;
  padding: 2px 12px;
  transition: all 0.3s;

  .label {
    font-size: 9px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    color: var(--dark-charcoal);
    text-transform: uppercase;
    display: block;
    margin-bottom: 2px;
  }

  .contact {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    color: var(--primary-lite);
    display: block;
    margin-bottom: 1px;
  }

  .orgName {
    font-size: 13px;
    font-style: italic;
    font-weight: bold;
    line-height: 1em;
    margin-top: 0px;
  }

  @media (max-width: 475px) {
    right: 50px;
  }
}

.invisibleCard {
  .agentCard();
  opacity: 0;
  top: 50px;
}

.logo {
  width: 100px;
  height: 64px;
  cursor: pointer;

  @media (max-width: 425px) {
    display: none;
  }
}

.link {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  color: var(--white);
  text-transform: uppercase;

  &:hover {
    color: var(--primary-lite);
  }

  @media (max-width: 990px) {
    font-size: 14px;
  }
}

.activeLink {
  cursor: default;

  &::after {
    content: "";
    width: 70%;
    height: 3px;
    background: var(--picton-blue);
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.15s ease-in-out;
    border-radius: 1px;
  }

  &:hover {
    color: var(--white);
  }
}

.phone {
  padding-top: 1px;

  a {
    color: var(--primary-lite);
    text-transform: uppercase;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;

    &:hover {
      color: var(--goldenrod);
    }
  }

  @media (max-width: 1120px) {
    display: none;
  }
}

.zipCol {
  height: 64px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  margin-left: 20px;

  .input {
    color: var(--regent-gray);

    &::placeholder {
      color: var(--regent-gray);
      font-size: 9px;
    }
  }
}

.input {
  width: 144px;
  height: 32px;
  .center();
  border-radius: 2px;
  background-color: transparent;
  border: none;
  border: 1px solid var(--regent-gray);
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
}

.zipButton {
  margin-left: 12px;
  .center();
  border-radius: 6px;
  text-transform: uppercase;
  background-color: var(--primary-lite);
  height: 32px;
  width: 175px;
  border-radius: 96px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;

  &:hover {
    background-color: var(--primary-lite-highlighted);
  }
}

.divider {
  background-color: var(--binrouji-black);
  height: 34px;
  width: 1px;
  margin: 0 20px;
}

.loginButton {
  .zipButton();
  height: 32px;
  width: 177px;
  border-radius: 96px;
  color: var(--white);
  background-color: var(--black);
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;

  &:hover {
    background-color: lighten(#333, 10%);
  }
}

.mobileMenuButton {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  .center();

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }

  .icon {
    width: 24px;
    height: 21px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .mobileMenuOpen {
    .icon();
    background-image: url("/icons/icon_mobile_menu_open.svg");
  }

  .mobileMenuClose {
    .icon();
    background-image: url("/icons/icon_mobile_menu_close.svg");
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;