@import (reference) "styles/theme.less";

// Little bar under active tab
:global(.ant-tabs-ink-bar) {
  background: var(--blue-ribbon);
}

:global(.ant-tabs) {
  width: 100%;
  flex-grow: 1;
}

.tabBarContainer {
  position: fixed;
  z-index: 99;
  width: 100%;
  background-color: white;
  transition: all 0.5s ease;

  .tabs {
    width: auto;
  }

  :global(.ant-tabs-nav) {
    height: 56px;
    width: auto;
    padding-left: 40px;
    margin-bottom: 0;

    &:before {
      border-bottom: unset;
    }
  }
}

.tabLabel {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins";
  line-height: 17px;
  color: var(--darkest-blue);
  letter-spacing: 0.1px;
  transition: color 0.2s linear;

  &:hover {
    color: var(--dark-blue);
  }
}

.tabLabelActive {
  .tabLabel();
  color: var(--blue-ribbon);
  text-shadow: none;
  border-bottom: none;

  &:hover {
    // stay the same on hover
    color: var(--blue-ribbon);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;