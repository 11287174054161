@import (reference) "styles/theme.less";

.createAccountContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(
    106.59deg,
    var(--congress-blue) 2.67%,
    var(--blue-ribbon) 46.33%,
    rgba(81, 242, 203, 0.83) 107.46%
  );

  .powurLogo {
    width: 115px;
    cursor: pointer;
  }

  .registrationLoading {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .language {
    font-weight: 600;
    color: var(--picton-blue);
    font-family: "Poppins";
    letter-spacing: 0.03em;
    line-height: 14px;

    .languageSelect {
      cursor: pointer;
    }
  }

  :global(.ant-radio-group) {
    display: flex;

    :global(.ant-radio-button-wrapper) {
      margin: 5px;
      width: 162px;
      height: 190px;
      border-radius: 10px;
      border: none;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
    }

    :global(.ant-radio-button-wrapper:hover) {
      box-shadow: 0px 4px 10px rgb(0 0 0 / 45%);
    }

    :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
      background-color: transparent;
    }

    :global(.ant-radio-button-wrapper:not(:first-child)::before) {
      background-color: transparent;
    }
  }

  .joinRadioButtonCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
    justify-content: space-between;

    .iconContainer {
      width: 102px;
      background-color: var(--polar-blue);
      height: 102px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .radioButtonText {
      font-size: 16px;
      line-height: 19px;
      font-family: "Barlow";
      font-weight: 500;
      margin: 10px;
      padding: 0px 15px;
      text-align: center;
      color: var(--dark-blue);
    }

    .speechBubbleArrow {
      width: 33px;
      position: absolute;
      bottom: 75px;
      left: 35px;
      height: 20px;
      background: var(--polar-blue);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      transform: rotate(-27.54deg);
    }
  }
}

.joinPowurDesktopView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("/img/background-lines-top-right.png");
  background-position: right top;
  background-repeat: no-repeat;

  .joinPowurDesktopContainer {
    position: relative;
    margin: 12px;

    .joinPowurTermsContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 35px;
    }

    .joinPowurDesktopCard {
      background: linear-gradient(60.72deg, var(--gallery-gray) -9.09%, var(--distant-horizon) 95.77%);
      width: 765px;
      min-height: 457px;
      border-radius: 15px;

      .createAccountContent {
        display: flex;
        flex-direction: column;
        padding: 35px;
        align-items: center;
        height: fit-content;
      }

      .photoContainer {
        display: flex;
        justify-content: flex-end;

        .photo {
          width: 100%;
          height: 85%;
          border-radius: 0px 15px 0px 120px;
          object-fit: cover;
        }
      }
    }

    .joinPowurDesktopBadge {
      position: absolute;
      right: -40px;
      bottom: -20px;
    }
  }

  .language {
    width: 765px;
    margin-left: 20px;
  }
}
.joinPowurMobileView {
  .mobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px 8px;
  }

  .joinPowurMobileContainer {
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    align-items: center;
    background: url("/img/background-lines-bottom-left.png"),
      linear-gradient(60.72deg, var(--gallery-gray) -9.09%, var(--distant-horizon) 95.77%);
    width: 95vw;
    height: 80vh;
    position: relative;
    border-radius: 15px;
    background-position: left bottom;
    background-repeat: no-repeat;

    .joinPowurMobileCard {
      width: 100%;
    }

    .joinPowurMobileBadge {
      position: absolute;
      bottom: -45px;
    }
  }
}

.joinPowurBadgeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  .joinPowurBadgePhotoContainer {
    margin-top: -157px;
    width: 142px;
    height: 142px;
    border-radius: 50%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;

    .joinPowurBadgeContent {
      width: 100px;
      display: flex;
      justify-content: center;

      .logo {
        max-width: 102px;
        max-height: 102px;
      }
    }
  }
}

.joinPowurHeader {
  width: 100%;

  .titleText {
    display: flex;
    align-items: flex-end;

    .titleNumber {
      font-family: "Poppins";
      font-weight: 700;
      font-size: 12px;
      margin: 0px 8px;
      color: var(--goldenrod);
    }

    .titleLabel {
      font-family: "Barlow";
      font-size: 16px;
      font-weight: 500;
      color: var(--blue-ribbon);
    }
  }

  .headerProgressBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 344px;
    padding-left: 25px;
    padding-right: 15px;
    justify-content: flex-start;

    .progressItemContainer {
      display: inline-block;
      flex-grow: 2;

      .progressButtonIncomplete {
        width: calc(100% - 4px);
        border: 1px solid var(--silver);
        background-color: var(--silver);
        margin: 2px;
        height: 3px;
      }

      .progressButtonComplete {
        width: calc(100% - 4px);
        border: 1px solid var(--goldenrod);
        background-color: var(--goldenrod);
        margin: 2px;
        height: 3px;
        cursor: pointer;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;