@import (reference) "styles/theme.less";

.wrapper {
  background-color: rgba(38, 165, 210, 0.4);
  :global(.ant-modal-body) {
    padding: 0;
  }
  :global(.ant-modal-content) {
    border-radius: 18px; // overstated to reduce extra border
  }
  :global(.ant-modal-close) {
    height: 64px;
    width: 64px;
    color: var(--white);
    span {
      font-size: 21px;
    }
  }
}

.wrapperLight {
  .wrapper();
  :global(.ant-modal-close) {
    display: flex;
    .center();
    :global(.ant-modal-close-x) {
      height: 100%;
    }
    span {
      color: var(--darker-blue);
    }
  }
}

.modalContainer {
  width: 100%;
}

.header {
  font-family: "Poppins", "Barlow", sans-serif;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  background-color: var(--raisin-black);
  color: var(--white);
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  @media (max-width: 550px) {
    font-size: 16px;
  }
}

.headerLight {
  .header();
  height: 58px;
  background-color: var(--athens-gray);
  color: var(--darker-blue);
}

.body {
  width: 100%;
  height: 100%;
  background-color: var(--raisin-black);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  .center();
}

.videoContainer {
  width: 100%;
  height: 100%;
  video {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;