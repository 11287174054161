@import (reference) "styles/theme.less";

.solarFormContainer {
  max-width: 600px;
  font-family: "Poppins", Sans-serif;
  background-color: var(--white);
  border-radius: 8px 8px 8px 8px;
  text-align: left;

  .inner {
    padding: 60px;
    @media (max-width: 1024px) {
      padding: 20px;
    }
  }

  .solarForm {
    padding: 10px 10px;
  }

  .label {
    color: var(--mineshaft-gray);
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    margin: 0 0 0 4px;
    padding: 0;
  }

  .rightLabel {
    .label();
    margin-left: 4%;
  }

  .centeredLabel {
    .label();
    text-align: center;
  }

  .ast {
    color: red;
    font-weight: 400;
  }

  .formItemLeft {
    width: 96%;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .formItemRight {
    margin-left: 4%;
  }

  .inputRow {
    padding-top: 6px;
    padding-bottom: 20px;
  }

  .centeredRow {
    .inputRow();
    text-align: center;
    padding-bottom: 0px;
  }

  .buttonContainer {
    margin: auto;
    padding-top: 10px;
    padding-bottom: 30px;
    height: 100px;
  }

  .bottomText {
    color: var(--darker-blue);
    font-size: 11px;
    font-weight: 400;
    line-height: 1.5em;
    padding: 10px;
  }

  .radioCol {
    .inputRow();
    margin: auto;
    .radioFormItem {
      margin: auto;
    }

    .radioContainer {
      display: flex;
      flex-direction: row;
    }

    .container {
      display: block;
      position: relative;
      margin: 7px;
      margin-bottom: 12px;
      margin-top: 6px;
      width: 80px;
      height: 36px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .radioLabel {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        margin-top: 3px;
        position: absolute;
        top: 0;
        left: 40px;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      input:checked ~ .checkmark {
        background-color: var(--white);
      }

      input:checked ~ .checkmark:after {
        display: block;
      }

      .checkmark:after {
        left: 8px;
        top: 2px;
        width: 9px;
        height: 15px;
        border: solid black;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid #c2c8ca;
      vertical-align: middle;
      width: 28px;
      height: 28px;
      text-align: center;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;