@import (reference) "styles/theme.less";

.dropdown {
  .select {
    width: 100%;
  }

  :global(.ant-select-single .ant-select-selector .ant-select-selection-item) {
    line-height: 0px;
  }

  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: 18px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    padding-left: 16px;
    padding-right: 40px;
  }

  :global(.ant-select-arrow) {
    color: var(--primary-color);
    font-weight: bolder;
    right: 16px;
  }

  .selected {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-color);
    height: 100%;
    display: flex;
    align-items: center;
  }

  .subOption {
    padding-left: 20px;
  }
}

.dropdownDark {
  .dropdown();

  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background-color: var(--primary-color);
    color: var(--white);
    font-weight: 600;
  }

  :global(.ant-select-selection-placeholder) {
    color: var(--white);
    font-weight: 400;
    padding-right: 0 !important;
  }

  :global(.ant-select-arrow) {
    color: var(--white);
    font-weight: 600;
    right: 16px;
  }

  .selected {
    font-size: 12px;
    line-height: 18px;
    color: var(--white);
    font-weight: 600;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;