@import (reference) "styles/theme.less";

.alertButton {
  .center();
  background: linear-gradient(95.7deg, var(--lemon-chiffon) 12.17%, var(--canary-yellow) 93.24%) !important;
  height: 32px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 4px 0px #00000026;
  cursor: pointer;

  .alertText {
    background: linear-gradient(95.7deg, var(--maya-blue) 12.17%, var(--blue-ribbon) 93.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-right: 9px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;