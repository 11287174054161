@import (reference) "styles/theme.less";

.pageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  .center();
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-position: left top;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/img/ambassador/bg.jpg");
}

.contentContainer {
  flex: none;
  margin: 60px 30px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 570px) {
    max-width: calc(100% - 20px);
  }
}

.contentLayout {
  background-color: transparent;
  overflow: hidden;
}

.header {
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;

  .logo {
    margin-left: -21px;
    margin-right: 16px;
    height: 100%;
    width: 300px;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("/img/ambassador/logo.png");

    @media (max-width: 450px) {
      width: 175px;
      margin-left: -12px;
    }
    @media (max-width: 375px) {
      width: 125px;
      margin-left: -8px;
    }
  }

  .headerContent {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.content {
  flex: none;
  height: calc(100% - 150px);

  .tabWindow {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
}

.footer {
  background-color: transparent;
  padding: 0;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;