.buildTeamCard {
  background: var(--picton-blue);
  opacity: 0.8;
  padding: 60px;
  width: 100%;

  .headerContainer {
    .sectionTitle {
      color: var(--white);
      font-family: Poppins;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
    }

    .line {
      margin: 15px 0 15px 0;
      position: relative;
      width: 36px;
      height: 3px;
      border-radius: 1px;
      background: var(--white);
    }

    .description {
      width: 35%;
      color: var(--white);
      font-size: 28px;
      font-weight: 600;
      line-height: 38px;

      @media (max-width: 900px) {
        width: 70%;
      }
      @media (max-width: 460px) {
        width: 100%;
      }
    }
  }

  .rolesContainer {
    position: relative;
    width: 100%;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 40px 0 40px;

    @media (max-width: 550px) {
      padding: 0;
    }

    @media (max-width: 460px) {
      flex-direction: column;
    }
    .role {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .text {
        bottom: 0;
        margin-top: 10px;
        font-family: Poppins;
        color: var(--white);
        font-size: 22px;
        white-space: nowrap;
        font-weight: 600;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;