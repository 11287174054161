@import (reference) "styles/theme.less";

@footer-grey: var(--loblolly-gray);

.footer {
  background-color: var(--white);
  display: flex;
  justify-content: center;
}

.innerContainer {
  width: 100%;
  max-width: 1280px;
  padding-top: 50px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.infoRow {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.links {
  display: flex;
  justify-content: space-between;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 475px) {
    flex-direction: column;
    align-items: center;
  }
}

.header {
  font-family: "Oswald", "Barlow", sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.linkCol {
  margin-right: 16px;
  color: rgb(32, 32, 32);

  .linksList {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 14px;
    line-height: 25px;
    text-transform: capitalize;

    li > a,
    div {
      color: var(--table-grey);
      cursor: pointer;
      &:hover {
        color: var(--primary-lite);
      }
    }
    li > div > a {
      color: var(--table-grey);
      text-transform: capitalize;
      &:hover {
        color: var(--primary-lite);
      }
    }

    .emailLink {
      color: var(--primary-lite);
      &:hover {
        color: var(--primary-lite-highlighted);
      }
    }
  }

  @media (max-width: 475px) {
    text-align: center;
    margin-bottom: 16px;
  }

  &:last-child {
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
    }
    @media (max-width: 475px) {
      display: block;
      text-align: center;
    }
  }
}

.solarCol {
  @media (max-width: 768px) {
    margin-top: 16px;
  }
  @media (max-width: 475px) {
    text-align: center;
  }
}

.lang {
  margin-top: 19px;
  :global(.ant-select-selection-item) {
    height: 32px;
  }
}

.quoteContainer {
  display: flex;

  .input {
    height: 36px;
    width: 144px;
    border-radius: 2px;
    margin-right: 8px;
    border: 0.5px solid @footer-grey;
    .center();
    font-size: 12px;
    line-height: 28px;
    text-transform: uppercase;
  }

  .zipButton {
    background-color: var(--primary-lite);
    height: 36px;
    width: 165px;
    border-radius: 96px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    &:hover {
      background-color: var(--primary-lite-highlighted);
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: @footer-grey;
  margin-top: 40px;
  margin-bottom: 15px;
}

.disclaimerRow {
  display: flex;

  @media (max-width: 475px) {
    flex-direction: column;
  }
}

.disclaimer {
  width: calc(75% - 50px);
  color: @footer-grey;
  margin-right: 50px;
  font-family: "Oswald", "Barlow", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;

  @media (max-width: 475px) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.copyright {
  width: 25%;
  color: @footer-grey;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  text-align: right;
  @media (max-width: 475px) {
    width: 100%;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;