@import (reference) "styles/theme.less";

.content {
  padding-top: 64px;
  background-color: rgb(243, 246, 248);

  h2 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 100;
  }

  h3 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 100;
  }

  h4 {
    position: relative;
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 32px;

    &:after {
      content: "";
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 30px;
      height: 3px;
      background-color: var(--primary-lite);
      border-radius: 4px;
    }

    &:after &.centered {
      content: "";
      position: absolute;
      bottom: -16px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 30px;
      height: 3px;
      background-color: var(--primary-lite);
      border-radius: 4px;
    }
  }

  h5 {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    text-transform: uppercase;
  }

  h6 {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.solarIntro {
  position: relative;
  width: 100%;
  height: 720px;
  max-height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  background-color: #000;

  .videoContainer {
    width: 100%;
    z-index: 2;
    overflow: hidden;

    .video {
      object-fit: cover;
      object-position: center top;
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-image: url("@{s3-asset-path}/img/why-solar/solar-carousel_video_screenshot.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;

    @media (max-width: 768px) {
      background-position-x: 30%;
    }

    @media (max-width: 475px) {
      background-position-x: 37%;
      background-position-y: 0%;
    }
  }

  .imageOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(32, 32, 32, 0.4);
    z-index: 2;
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    position: absolute;
    width: calc(100% - 128px);
    max-width: 1280px;
    height: 100%;
    padding: 0 64px;

    @media (max-width: 768px) {
      width: calc(100% - 32px);
      padding: 0 16px;
    }
  }

  .headline {
    font-family: "Poppins", "Barlow", sans-serif;
    font-size: 54px;
    line-height: 50px;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 32px;
    }
    @media (max-width: 475px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  .subHeading {
    color: var(--white);
    font-size: 21px;
    line-height: 27px;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 475px) {
      font-size: 14px;
      line-height: 19px;

      br {
        display: none;
      }
    }
  }

  .zipInputContainer {
    display: flex;

    .input {
      height: 40px;
      width: 200px;
      .center();
      border-radius: 2px;
      background-color: var(--white);
      border: 1px solid var(--primary-lite);
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0em;
      margin-bottom: 20px;
      margin-right: 20px;
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      color: var(--primary-lite);

      &::placeholder {
        color: var(--primary-lite);
      }
    }

    .zipButton {
      width: 264px;
      height: 40px;
      background-color: var(--primary-lite);
      border-radius: 96px;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      &:hover {
        background-color: var(--primary-lite-highlighted);
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .input {
        margin-bottom: 20px;
        width: 228px;
      }
    }
  }
}

.bgDarkenZoomOnHover {
  .bgDarkenZoomOnHover();
}

.powurStories {
  width: 100%;
  background-color: var(--cream);
  display: flex;
  flex-direction: column;
  align-items: center;

  .titleContainer {
    position: relative;
    width: 100%;
    max-width: 720px;
    padding: 70px 16px;
    .center();
    flex-direction: column;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 2px;
      height: 60px;
      background-color: var(--light-grey);
    }

    .headline {
      font-weight: 700;
      margin-bottom: 24px;
    }

    .subtitle {
      text-align: center;
    }
  }

  .headlineHideBorder::before {
    display: none;
  }

  .cardsContainer {
    width: calc(100% - 100px);
    max-width: 1280px;
    .center();
    flex-direction: column;
    margin-bottom: 64px;

    @media (max-width: 768px) {
      width: calc(100% - 64px);
    }

    @media (max-width: 475px) {
      width: calc(100% - 32px);
    }
  }

  .story {
    display: flex;
    width: 100%;
    position: relative;

    &:nth-child(2) {
      margin-top: 64px;
      justify-content: flex-end;

      .card {
        left: 0;
        right: auto;
      }
    }

    .storyContent {
      @media (max-width: 998px) {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
      }
    }

    .img {
      position: relative;
      overflow: hidden;
      z-index: 1;
      border-radius: 6px;
      height: 36vw;
      max-height: 520px;
      width: 74vw;
      max-width: 1060px;

      .bg {
        z-index: 1;
        height: 100%;
        width: 100%;
      }

      @media (max-width: 998px) {
        width: 100%;
        height: 50vw;
      }

      @media (max-width: 768px) {
        height: 60vw;
      }

      @media (max-width: 475px) {
        height: 65vw;
      }

      .juan {
        background-image: url("@{s3-asset-path}/img/why-solar/whysolar_Juan.jpg");
        background-position-x: 50%;
        background-position-y: 50%;

        @media (max-width: 768px) {
          // background-position-x: 75%;
          background-position-y: 75%;
        }

        @media (max-width: 475px) {
          background-image: url("@{s3-asset-path}/img/why-solar/whysolar_Juan_mobile.jpg");
          background-position-y: 50%;
        }
      }

      .pujaAmish {
        background-image: url("@{s3-asset-path}/img/why-solar/whysolar_PujaAmish.jpg");

        @media (max-width: 998px) {
          background-position-x: 25%;
          background-position-y: 25%;
        }

        @media (max-width: 475px) {
          background-image: url("@{s3-asset-path}/img/why-solar/whysolar_PujaAmish_mobile.jpg");
          background-position-x: 35%;
        }
      }

      .john {
        background-image: url("@{s3-asset-path}/img/why-solar/whysolar_John.jpg");
        background-position-x: 20%;
        background-position-y: 20%;

        @media (max-width: 768px) {
          background-position-x: 30%;
          background-position-y: 0%;
        }

        @media (max-width: 475px) {
          background-image: url("@{s3-asset-path}/img/why-solar/whysolar_John_mobile.jpg");
          background-position-x: 50%;
        }
      }

      .kristy {
        background-image: url("@{s3-asset-path}/img/why-solar/whysolar_Kristy.jpg");
        background-position-x: 50%;

        @media (max-width: 768px) {
          background-position-x: 60%;
        }

        @media (max-width: 475px) {
          background-image: url("@{s3-asset-path}/img/why-solar/whysolar_Kristy_mobile.jpg");
          background-position-x: 50%;
        }
      }
    }

    .card {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;
      background-color: var(--white);
      z-index: 2;
      max-height: 324px;
      height: 36vw;
      width: 410px;
      padding: 50px;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @media (max-width: 998px) {
        position: static;
        .center();
        flex-direction: column;
        width: 100%;
        height: 300px;
        max-height: none;
        background-color: var(--cream);
      }

      .titleBox {
        width: 293px;

        .title {
          font-size: 28px;
          font-weight: 400;
          line-height: 33px;
          letter-spacing: 0em;
          color: var(--table-grey);
          margin-bottom: 40px;
        }

        @media (max-width: 998px) {
          width: 100%;
          margin-bottom: 33px;
        }
      }

      .videoButton {
        width: 229px;
        height: 52px;
        min-height: 52px;
        background-color: var(--primary-lite);
        border-radius: 96px;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-transform: uppercase;

        .icon {
          font-size: 21px;
          margin-left: 12px;
        }
        &:hover {
          background-color: var(--primary-lite-highlighted);
        }
      }
    }
  }
}

.solarChart {
  width: 100%;
  // background-color: rgb(0, 12, 17);
  .center();

  img {
    width: 100vw;
    height: 43vw;
  }
}

.process {
  width: 100%;
  background-color: var(--tangaroa);
  padding: 64px 32px;
  .center();

  .innerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1280px;
  }

  .heading {
    margin-bottom: 16px;
    color: var(--white);
  }

  .subheading {
    color: var(--white);
    font-size: 23px;
    line-height: 30px;
    font-weight: 300;
    width: 100%;
    max-width: 768px;
    margin-bottom: 32px;
    text-align: center;
  }

  .cardsContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    .card {
      position: relative;
      overflow: hidden;
      width: 193px;
      height: 254px;
      border-radius: 6px;
      box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
      padding: 32px 24px;
      z-index: 1;
      background-color: var(--white);
      margin-top: 8px;

      &:not(:last-child) {
        margin-right: 12px;

        @media (max-width: 768px) {
          margin-right: 0;
        }
      }
    }

    .bg {
      background-position: center 50%;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 70%);
      z-index: 2;
    }

    .cardTitle {
      z-index: 3;
      font-size: 14px;
      line-height: 17px;
      width: 70%;
    }
  }
}

.savings {
  width: 100%;
  padding: 46px;
  background-color: var(--tangaroa);
  .center();

  .innerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: var(--white);
    text-align: center;
    margin-bottom: 15px;
    font-size: 27px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0em;
  }
  .subheading {
    color: var(--white);
    text-align: center;
    margin-bottom: 37px;
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
  }
  .buttonContainer {
    display: flex;

    .input {
      height: 36px;
      width: 144px;
      border-radius: 2px;
      margin-right: 12px;
      .center();
      font-size: 12px;
      line-height: 28px;
      text-transform: uppercase;
      border: 1px solid var(--regent-gray);
      background-color: transparent;
      color: var(--white);
      margin-right: 16px;
      text-align: center;
      font-weight: 400;
      &::placeholder {
        color: var(--white);
      }
    }

    .zipButton {
      background-color: var(--primary-lite);
      height: 36px;
      width: 165px;
      border-radius: 96px;
      font-size: 14px;
      line-height: 28px;
      font-weight: 600;
      text-transform: uppercase;
      border: none;
      &:hover {
        background-color: var(--primary-lite-highlighted);
      }
    }
  }
}

.savingsLight {
  .savings();
  background-color: var(--white);

  .innerContainer .title,
  .innerContainer .subheading,
  .buttonContainer .input,
  .buttonContainer .input::placeholder {
    color: var(--table-grey);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;