@import (reference) "styles/theme.less";

.loginCredContainer {
  width: 100%;
  height: 350px;
  padding: 0px 25px;

  .verificationLabel {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 20px;
    color: var(--blue-ribbon);
  }

  .emailForm {
    width: 100%;
    padding: 10px 0px;

    input {
      width: calc(100% - 52px);
      height: 42px;
      border: none;
      outline: 2px solid white;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    ::placeholder {
      font-family: "Barlow";
      font-weight: 500;
      font-size: 16px;
      color: var(--trolley-grey);
    }

    .emailSubmitButton {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      height: 45px;
    }
  }

  .otherOptions {
    display: flex;
    flex-direction: column;
    align-items: center;

    .altVerifyButton {
      width: 270px;
      margin: 5px 0px;
    }

    .languagePreferenceContainer {
      width: 100%;
      padding: 5px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .languagePreferenceLabel {
        font-family: "Poppins";
        color: var(--blue-ribbon);
        width: 100%;
        margin-bottom: 5px;
      }

      :global(.ant-radio-wrapper) {
        color: var(--dark-blue);
        font-weight: 500;
        font-size: 16px;
      }

      :global(.ant-radio-inner) {
        border-color: var(--light-silver);
        background: var(--light-silver);
        width: 20px;
        height: 20px;
      }

      :global(.ant-radio-inner::after) {
        width: 30px;
        height: 30px;
        margin-top: -15px;
        margin-left: -15px;
        background-color: var(--blue-ribbon);
      }
    }

    .existingAcct {
      width: 100%;
      font-family: "Poppins";
      font-size: 12px;
      font-weight: 500;
      color: var(--trolley-grey);
      margin: 12px 20px;
      padding-left: 12px;

      .linkToLogin {
        cursor: pointer;
      }
    }
  }

  .emailVerifyDivider {
    width: 100px;
    margin: -8px;

    :global(.ant-divider-horizontal.ant-divider-with-text) {
      border-top-color: var(--dark-grey);
    }

    :global(.ant-divider-horizontal) {
      border-top-color: var(--explosive-gray);
    }

    :global(.ant-divider-inner-text) {
      color: var(--dark-grey);
      font-size: 12px;
      font-weight: 600;
    }
  }

  .linkSentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px;
    font-family: "Poppins";
    color: var(--dark-grey);

    .linkSent {
      font-size: 20px;
    }

    .linkInstructions {
      font-size: 14px;
    }
  }

  .verificationContainer {
    .customPasswordInput {
      border: none;
      border-radius: 3px;
      height: 45px;
      padding: 10px 15px;
    }
    ::placeholder {
      font-family: "Barlow";
      font-weight: 500;
      font-size: 16px;
      color: var(--trolley-grey);
    }

    .passwordInput {
      margin: 5px 0px;

      :global(.ant-input-affix-wrapper) {
        .customPasswordInput();

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .passwordRepeat {
      margin: 5px 0px;

      :global(.ant-input) {
        .customPasswordInput();
      }
    }

    :global(.ant-form-item-with-help .ant-form-item-explain) {
      min-height: 0px;

      :global(.ant-form-item-explain-error) {
        font-size: 10px;
        position: absolute;
        margin-right: 5px;
        text-align: right;
        width: 100%;
      }
    }

    .passwordRuleList {
      margin: 12px 0px;

      .passwordRule {
        margin: 3px;
        font-family: "Poppins";
        color: var(--dark-grey);
        display: flex;
        align-items: center;

        .validationIcon {
          margin: 0px 10px;
          display: flex;
        }
      }
    }
  }

  .passwordSubmit {
    margin-top: 20px;
    width: 150px;
    height: 45px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;