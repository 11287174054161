@import (reference) "styles/theme.less";

.blue {
  color: var(--primary-color);
}

.roundButton {
  height: 50px;
  text-transform: uppercase;
  border-radius: 25px;
}

.squareButton {
  .roundButton();
  border-radius: 0;
}

.clearButton {
  .roundButton();
  .blue();
  .buttonShadow();
  height: 35px;
  width: 85px;
  text-transform: uppercase;
  border: 1px solid var(--primary-color);
}

.goAway {
  display: none;
}

.topLevelSider {
  background: transparent;
}

.pageContainer {
  height: 100vh;
  overflow: hidden;
}

.container {
  position: relative;
  background-color: var(--white);
  height: 100%;

  .siderOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50px;
    left: 0;
    overflow: hidden;
    background-color: var(--primary-color);
    opacity: 0.4;
    z-index: 1001;
  }

  .siderOverlayShow {
    .siderOverlay();
  }

  .siderOverlayHide {
    .siderOverlay();
    display: none;
  }
}

.body {
  height: calc(100vh - 50px);
  overflow-y: hidden;
  background-color: var(--white);

  .sideBar {
    position: relative;
    background-color: var(--errigal-white);
    padding: 15px 25px;
    .collapse {
      position: absolute;
      top: 18px;
      right: -18px;
      height: 36px;
      width: 36px;
      font-size: 18px;
      background: var(--white);
      border: 1px solid var(--errigal-white);
      color: rgba(0, 0, 0, 0.65);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .icon {
        color: rgba(0, 0, 0, 0.35);
        transition: transform 0.3s linear;
      }
      .iconSideBarCollapsed {
        .icon();
        transform: rotate(180deg);
      }
    }
  }

  .sideBarSmall {
    padding-top: 100px;
  }

  .squishOnSmallScreen {
    @media (max-width: 878px) {
      width: 0;
      padding: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  .sideBarContainer {
    max-width: 300px;
    background-color: transparent;
  }

  .addLeadButton {
    .roundButton();
    .blue();
    .buttonShadow();
    height: 40px;
    width: 160px;
    text-transform: uppercase;
    border: 1px solid var(--primary-color);

    .buttonContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .icon {
        font-size: 22px;
        transition: transform 0.2s linear;
      }
      .iconDrawerOpen {
        .icon();
        transform: rotate(45deg);
      }
    }
  }

  .searchContainer {
    margin-top: 21px;
    width: 100%;

    .label {
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.65);
      font-size: 10px;
    }

    .inputContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .search {
        width: 100%;
        padding: 0;
        background: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid var(--primary-color);
        .blue();

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }

    .buttonContainer {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        width: 50px;
        border-radius: 25px;
        .buttonShadow();
      }
      .searchButton {
        .button();
      }
      .clearButton {
        .button();
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        text-transform: uppercase;
      }
    }
  }

  .sideMenuContainer {
    width: 100%;
  }

  .clearButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;

    .clearButton {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      width: 100px;
      border-radius: 25px;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      .buttonShadow();
    }
  }
}

.content {
  max-height: 100%;
  padding: 20px 30px;
  overflow-y: hidden;

  .collapseSmallScreen {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    .buttonShadow();

    .icon {
      font-size: 24px;
      color: var(--white);
    }

    @media (min-width: 879px) {
      display: none;
    }
  }

  .menuContainer {
    height: 100%;
    overflow-y: hidden;
    z-index: 3;

    .tabLabel {
      font-size: 16px;
    }

    .tabs {
      height: 100%;

      .tab {
        height: 100%;

        .statisticLabel {
          font-size: 12px;
          color: rgba(120, 120, 120, 0.65);
          text-transform: uppercase;
        }
      }
    }
  }
}

.contentSmall {
  .content();
  background: linear-gradient(to bottom, var(--primary-color) 50px, var(--errigal-white) 50px 100%);
  background-color: var(--errigal-white);
  padding-top: 0;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;