@import (reference) "styles/theme.less";

.videoTestimonials {
  width: 100%;
  padding: 100px 16px;
  .center();
}

.innerContainer {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bgDarkenZoomOnHover {
  .bgDarkenZoomOnHover();
}

.header {
  margin-bottom: 16px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 25px;
  letter-spacing: 0em;
  text-transform: uppercase;
}

.subheading {
  margin-bottom: 64px;
  max-width: 768px;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.cardsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
}

.testimonialCard {
  max-width: 410px;
  width: 100%;
  height: 350px;
  position: relative;
  margin: 0 6px;
  cursor: pointer;

  @media (max-width: 900px) {
    margin-bottom: 100px;
  }

  .title {
    font-family: "Poppins";
    position: relative;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 32px;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -16px;
      margin: 0 auto;
      width: 30px;
      height: 3px;
      padding-left: 4px;
      padding-top: 3px;
      background-color: var(--primary-lite);
      border-radius: 4px;
      color: transparent;
      transition: all 0.15s ease-in-out;
      bottom: -16px;
    }
  }

  &:hover {
    .hoverBg {
      background-color: rgba(32, 194, 241, 0.15) !important;
    }

    .title::after {
      content: "►";
      bottom: -32px;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: rgba(32, 194, 241, 1);
      color: var(--white);
      font-size: 18px;
      .center();
    }
  }

  .topCard {
    width: 100%;
    height: 260px;
    position: relative;
    overflow: hidden;
    background-color: var(--white);
    border-radius: 4px;
    z-index: 1;

    @media (max-width: 998px) {
      height: 300px;
    }
  }

  .bottomCard {
    position: absolute;
    width: 85%;
    min-height: 210px;
    top: 210px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 26px 32px;
    border-radius: 6px;
    z-index: 2;
    background-color: var(--white);
    .center();
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.08);

    .title {
      font-size: 14px;
      margin-top: 10px;
      white-space: nowrap;
    }

    .text {
      font-size: 16px;
      line-height: 25px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;

      @media (max-width: 1280px) {
        font-size: 14px;
      }

      @media (max-width: 1016px) {
        font-size: 12px;
      }
    }

    @media (max-width: 1280px) {
      top: auto;
      bottom: -40px;
      height: 173px;
    }
  }

  @media (max-width: 1280px) {
    width: 100%;
    max-width: 500px;
    margin-bottom: 50px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;