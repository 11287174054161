@import (reference) "styles/theme.less";

.joinRow {
  width: 100%;
  height: 50vw;
  min-height: 800px;
  .consultantCol {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("@{s3-asset-path}/img/ambassador-join/ambassador_solaragent_bg.png");
    .avatar {
      height: 214px;
      width: 214px;
      border-radius: 50%;
      background-color: grey;
      margin-bottom: 11px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      @media (max-width: 768px) {
        height: 140px;
        width: 140px;
      }
    }
    .title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
    }
    .name {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-transform: uppercase;
      color: var(--white);
    }
  }

  .infoCol {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 60%;
    background-color: var(--cream);
    padding: 84px 157px 84px 101px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .underline {
      height: 3px;
      width: 36px;
      border-radius: 1px;
      background-color: var(--picton-blue);
      margin-bottom: 20px;
    }
    h3 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 15px;
    }
    h2 {
      font-size: 28px;
      font-weight: 200;
      line-height: 36px;
      margin-bottom: 37px;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      margin-bottom: 20px;
      span {
        font-weight: 600;
      }
    }
    .button {
      height: 40px;
      width: 208px;
      border-radius: 96px;
      background-color: var(--primary-lite);
      color: var(--white);
      font-size: 14px;
      font-weight: 700;
      line-height: 27px;
      text-transform: uppercase;
      margin-top: 40px;
      border: none;
      &:hover {
        background-color: var(--primary-lite-highlighted);
      }
    }
    .angledAccent {
      width: 150%;
      position: absolute;
      height: 200px;
      background-color: var(--white);
      opacity: 0.5;
      transform: rotate(-7deg);
      bottom: -168px;
      right: -28px;
      @media (max-width: 1150px) {
        height: 195px;
        width: 130%;
      }
    }
    @media (max-width: 900px) {
      padding: 84px 60px 84px 80px;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    .consultantCol {
      width: 100%;
      height: 320px;
    }
    .infoCol {
      width: 100%;
      height: 480px;
      padding: 0;
      padding: 84px 80px 84px 40px;
    }
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    .consultantCol {
      width: 100%;
      height: 320px;
    }
    .infoCol {
      width: 100%;
      height: 480px;
      padding: 0;
      padding: 84px 40px 84px 40px;
      h3 {
        margin-bottom: 0px;
      }
      h2 {
        margin-bottom: 18px;
      }
      p {
        margin-bottom: 18px;
      }
      .button {
        margin-top: 18px;
      }
    }
  }
}

.textContainerOuter {
  width: 35%;
  position: relative;
  overflow: hidden;
  @media (max-width: 1150px) {
    width: 40%;
  }
  @media (max-width: 900px) {
    width: 100%;
    flex: unset;
    height: 300px;
  }
  .angledAccent {
    width: 120%;
    height: 211px;
    background-color: var(--cultured-white);
    position: absolute;
    transform: rotate(-11deg);
    bottom: -168px;
    right: -28px;
    @media (max-width: 1150px) {
      height: 203px;
    }
  }

  .textContainer {
    height: 100%;
    padding: 0 134px 0 72px;
    @media (max-width: 1150px) {
      padding: 0 104px 0 52px;
    }
    @media (max-width: 400px) {
      padding: 0 40px 0 30px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .textRow {
      .contentHeader {
        color: var(--lead-black);
        text-transform: uppercase;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
      }
      .underline {
        height: 3px;
        width: 40px;
        border-radius: 1px;
        background-color: var(--primary-color);
        margin: 11px 0 18px 0;
      }
      .contentSubHeader {
        margin-bottom: 18px;
        color: var(--lead-black);
        font-size: 23px;
        line-height: 29px;
        letter-spacing: 1px;
        font-size: 28px;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: 0em;
      }
      .bodyText {
        color: var(--table-grey);
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;