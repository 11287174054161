@import (reference) "styles/theme.less";

.ant-badge-count {
  z-index: auto;
  min-width: 14px;
  height: 14px;
  padding: 0 4px;
  color: var(--white);
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  text-align: center;
  background: var(--sunset-orange);
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 1px var(--white);
  box-shadow: 0 0 0 1px var(--white);
}

.ant-badge {
  padding-right: 10px;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;