@import (reference) "styles/theme.less";

.selectInput {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--dark-blue);

  .caretIcon {
    .center();
    color: var(--dark-blue);
    padding-left: 4px;
    margin: 4px 0 4px 4px;
    padding-left: 6px;
    border-left: 1px solid var(--primary-color);
    height: 18px;
  }

  :global(.ant-select-selection-placeholder) {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--dark-blue);
  }

  :global(.ant-select-arrow) {
    height: 100%;
    .center();
    margin-top: -16px;
    width: 16px;

    :global(.anticon:not(.ant-select-suffix)) {
      pointer-events: none;
    }
  }

  :global(.ant-select-suffix) {
    color: var(--font-color-eastern-blue);
  }

  :global(.ant-select-selection-item) {
    height: 32px !important;
    padding-right: 24px !important;
  }

  :global(.ant-select-selector) {
    border: 1px solid var(--botticelli-blue) !important;
    height: 30px;
    border-radius: 3px !important;
  }

  :global(.ant-select) {
    color: var(--dark-blue);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;