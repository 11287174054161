@import (reference) "styles/theme.less";

.mainContainer {
  height: 100%;
  flex-wrap: nowrap;

  .backButtonContainer {
    position: absolute;
    top: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--font-color-eastern-blue);
    width: 65px;
    height: 29px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    .backButton {
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      color: var(--white);
      margin-left: 4px;
    }
    .iconContainer {
      background-color: red;
    }
  }

  .cancelButton {
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: var(--font-color-eastern-blue);
    background: var(--white);
    border: 0.5px solid var(--font-color-eastern-blue);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-size: 14pt;

    &[disabled] {
      cursor: not-allowed;
      background: var(--botticelli-blue);
      color: var(--white);
      box-shadow: none;
      border: none;
    }
  }

  .saveButton {
    .cancelButton();
    font-size: 12px;
    width: 107px;
    height: 32px;
    background: var(--font-color-eastern-blue);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-weight: 600;
    text-align: center;
    color: var(--white);
    border: none;

    &[disabled] {
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  .additionalItemsNoNav {
    margin-bottom: 0;
  }
  .additionalItems {
    margin-bottom: 10px;
  }
  .buttonRow {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
  }

  .sider {
    width: 200px;
    overflow: scroll;
    overflow-x: hidden;
    max-width: 200px;
    margin-top: 25px;
    overflow-y: auto;
    scrollbar-color: var(--primary-color) transparent;
    scrollbar-width: thin;

    .linkRow {
      margin-bottom: 10px;
    }

    .navItem {
      border-radius: 38px;
      width: 185px;
      height: fit-content;
      min-height: 36px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-right: 5px;
      margin-bottom: 3px;
      color: var(--dark-blue);
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: var(--bright-gray);
      }
    }

    .navItemActive {
      .navItem();
      background-color: var(--white);
      color: var(--blue-ribbon);
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-color);
      border-left: 9px solid transparent;
      background-clip: padding-box;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb:hover {
      border-radius: 10px;
      border: none;
    }
  }

  .siderNoNav {
    .sider();
    padding-top: 25px;
  }

  .colCenter {
    padding: 0px 20px 0px 20px;
    margin-left: 200px;
    max-width: calc(100% - 200px);

    .stripeContainer {
      .stripeCard {
        width: 100%;
        background-color: var(--white);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
      }
    }
  }

  .colLeft {
    position: fixed;
  }

  .colRight {
    height: 300px;
    max-width: 150px;
  }
}

.detailPageFooter {
  height: 72px;
  width: 100%;
  background: var(--athens-gray);
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.03);
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: none;

  .sider {
    display: flex;
    align-items: center;
    .additionalItems {
      .center();
    }
  }

  .navItem {
    margin-bottom: 12px;

    .inactive {
      color: var(--dark-blue);
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      cursor: pointer;
      &:hover {
        color: var(--darker-blue);
      }
    }

    .active {
      .inactive();
      font-weight: 700;
      cursor: default;
      color: var(--darker-blue);
    }
  }

  .buttonRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cancelButton {
    position: relative;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: var(--font-color-eastern-blue);
    background: var(--white);
    border: 0.5px solid var(--font-color-eastern-blue);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-size: 14pt;
    &[disabled] {
      cursor: not-allowed;
      background: var(--botticelli-blue);
      color: var(--white);
      border: none;
    }
    margin-left: 20px;
  }

  .saveButton {
    .cancelButton();
    font-size: 12px;
    width: 107px;
    height: 32px;
    background: var(--font-color-eastern-blue);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-weight: 600;
    text-align: center;
    color: var(--white);
    border: none;
    margin-left: unset;
  }

  @media (min-width: 501px) {
    width: calc(100% - 48px); // account for sidebar
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;