@import (reference) "styles/theme.less";

.navIconContainer {
  .center();
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background-color: transparent;
  transition: all 0.2s;
  cursor: pointer;
  * {
    fill: var(--primary-color);
  }
  .brainLines {
    fill: var(--dark-blue);
  }
  &:hover {
    background-color: var(--dark-navy);
    * {
      fill: white;
    }
    .brainLines {
      fill: var(--dark-navy);
    }
    .enterpriseIcon {
      path {
        fill: var(--dark-navy);
      }
    }
  }
}

.navIconContainerActive {
  .navIconContainer();
  background-color: var(--primary-color);
  * {
    fill: white;
  }
  &:hover {
    background-color: var(--primary-color);
    * {
      fill: white;
    }
  }
}

.gettingStartedHovered {
  path {
    fill: var(--blue-ribbon);
  }
}

.enterpriseIcon {
  path {
    cursor: pointer;
    fill: var(--dark-navy);
  }
  &:hover {
    path {
      fill: var(--primary-color);
    }
  }
}

.gettingStartedNavIconContainer {
  .navIconContainer();
  background: linear-gradient(95.7deg, var(--lemon-chiffon) 12.17%, var(--canary-yellow) 93.24%);

  &:hover {
    path {
      fill: var(--blue-ribbon);
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;