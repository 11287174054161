.hover() {
  background-position: 100% 0;

  background-image: linear-gradient(
    95.7deg,
    var(--picton-blue),
    var(--blue-ribbon),
    var(--blue-ribbon),
    var(--blue-ribbon)
  );
  background-size: 300% 100%;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: var(--white);
}

.gradientButton {
  color: var(--white);
  border: none;
  border-radius: 6px;
  background-size: 300% 100%;
  -moz-transition: all 0.3s ease-in-out;
  font-weight: 600;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

  &:hover {
    color: var(--white);
    background: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  &:focus {
    color: var(--white);
    background: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
  }
  &:disabled {
    color: var(--white);
    background: var(--botticelli-blue);
    box-shadow: none;
    &:hover {
      color: var(--white);
      background: var(--botticelli-blue);
      box-shadow: none;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;