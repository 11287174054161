@import (reference) "styles/theme.less";

.container {
  width: 100%;
  height: 75px;
  display: flex;
}

.containerR {
  .container();
  flex-direction: row-reverse;
}

.mentorContainer {
  .container();
  height: 84px;
}

.mentorContainerR {
  .containerR();
  .mentorContainer();
}

.avatarContainer {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.avatar {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}

.nameContainer {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nameContainerR {
  .nameContainer();
  padding-left: 0px;
  padding-right: 10px;
  text-align: right;
  width: auto;
}

.nameContainerL {
  .nameContainer();
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  width: auto;
}

.name {
  padding-top: 2px;
  font-size: 14px;
  .ellipsis();
}

.id {
  font-size: 11px;
  .ellipsis();
}

.role {
  color: var(--nepal-blue);
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  .ellipsis();
  text-transform: uppercase;
}

.small {
  font-size: 10px;
  line-height: 14px;
  .ellipsis();
}

.medium {
  .ellipsis();
  font-size: 12px;
  line-height: 17px;
}

.bold {
  .name();
  font-weight: 600;
}

.nameBold {
  .name();
  font-family: Poppins, "Barlow", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 2px;
  color: var(--darkest-blue);
}

.mentorNameBold {
  .nameBold();
  font-size: 16px;
  line-height: 17px;
}

.totalCount {
  .name();
  color: var(--gothic-blue);
  font-size: 12px;
  line-height: 16px;
}

.ratedText {
  .totalCount();
  font-size: 14px;
  font-weight: 400;
  color: var(--graham-grey);
}

.email {
  .small();
  color: var(--nepal-blue);
}

.mentorEmail {
  .small();
  font-size: 14px;
  line-height: 18px;
}

.phone {
  .small();
  font-size: 14px;
  line-height: 18px;
  color: var(--table-grey);
}

.rating {
  height: 18px;
}

.score {
  color: var(--goldenrod);
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}

.stars {
  display: inline-flex;
  align-items: center;
  position: relative;

  .star {
    margin-right: 2px;
    display: flex;
    color: var(--goldenrod);
  }

  .overlay {
    background-color: var(--botticelli-blue);
    position: absolute;
    mix-blend-mode: color;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.projectPeopleCard {
  height: 100%;
  display: flex;
  .ellipsis();

  .infoContainer {
    display: flex;
    flex-direction: column;
    margin-left: 6px;

    .text {
      font-size: 0.75rem;
      color: var(--white);
    }

    .name {
      .text();
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 17px;
    }

    .id {
      .text();
      line-height: 12px;
    }

    .titleRow {
      font-size: 10px;
      line-height: 14px;
      height: 14px;
      font-weight: 600;
      color: var(--dark-blue);
      text-transform: uppercase;
    }

    .nameRow {
      display: flex;
      flex-direction: column;
      height: 34px;
      .ellipsis();
    }

    .phoneMobile {
      height: 19px;
    }
  }

  .infoMobile {
    .infoContainer();
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0;
    width: 100%;
  }
}

.cardMobile {
  .projectPeopleCard();
  width: 100%;
  padding: 2px 8px;
}

.teamInfoCard {
  display: flex;
  align-items: center;

  .avatarCol {
    margin-right: 8px;
  }

  .infoCol {
    display: flex;
    flex-direction: column;

    .name {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
    }

    .title {
      font-weight: 600;
      color: var(--nepal-blue);
      font-size: 10px;
      text-transform: uppercase;
    }

    .text {
      font-size: 12px;
      line-height: 17px;
    }

    .lead {
      .text();
    }

    .phone {
      .text();
    }

    .email {
      .text();
      .blue();
    }
  }
}

.count {
  margin: 2px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: var(--white);
  text-align: center;
  font-size: 11px;
  font-weight: 600;
}

.badgeContainer {
  display: flex;
  align-items: center;
}

.peopleBadge {
  .count();
  margin: 0px;
  display: inline-block;
  background-color: var(--botticelli-blue);
  color: var(--nepal-blue);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0em;
  text-transform: uppercase;
  line-height: 16px;
  margin-left: 0px;
  margin-right: 8px;
  height: 22px;
  width: 22px;
  .center();
}

.teamBadge {
  .peopleBadge();
  background-color: var(--table-badge-team);
  color: var(--white);
  .center();
}

.teamCard {
  padding: 8px;
  width: auto;
}

.peopleColumn {
  display: flex;
  flex-direction: column;
  .ellipsis();

  .nameContainer {
    width: 100%;

    .name {
      color: var(--table-grey);
      font-size: 12px;
      .ellipsis();
    }

    .bold {
      .name();
      font-weight: 600;
    }

    .projectName {
      font-weight: 600;
      font-size: 14px;
      color: var(--primary-color);
    }
  }
}

.peopleColumnMobile {
  display: flex;
  flex-direction: column;

  .peopleBadge {
    .peopleBadge();
    margin-right: 4px;
  }
  .teamBadge {
    .teamBadge();
    margin-right: 4px;
  }

  .name {
    color: var(--table-grey);
    font-size: 10px;
    .ellipsis();
  }

  .bold {
    .name();
    font-weight: 600;
  }
}

.ticket {
  color: var(--white);
  font-size: 10px;
  padding: 0;
  margin: 0px 0 3px 0;
  line-height: 13px;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;