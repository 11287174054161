@import (reference) "styles/theme.less";

.outer {
  background-color: transparent;
  position: absolute;
  top: 78px;
  right: 30px;
  height: 137px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  .referrerInfoContainer {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    height: 140px;
    width: 340px;
    border-radius: 8px;
    z-index: 100;
    background-color: var(--dark-charcoal);
    box-shadow: 10px 81px 24px blue;
    @media (max-width: 500px) {
      right: 5%;
    }
    .image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    .textContainer {
      padding: 14px;
      height: 100%;
      h5 {
        font-size: 9px;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: 0em;
        color: var(--silver);
        margin-bottom: 2px;
        text-transform: uppercase;
        letter-spacing: 0.75px;
      }
      .h2 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--white);
        margin-bottom: 10px;
      }
      .h4 {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        margin-bottom: -1px;
        cursor: pointer;
        a {
          color: var(--picton-blue);
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          &:hover {
            color: var(--goldenrod);
          }
        }
      }
      .orgName {
        display: block;
        font-size: 13px;
        font-style: italic;
        line-height: 20px;
        color: var(--white);
        margin: 0px;
      }
      .h2WithOrg {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--white);
        margin-bottom: 0px;
      }
      .h5WithOrg {
        font-size: 9px;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: 0em;
        color: var(--silver);
        margin-bottom: 2px;
        margin-top: 10px;
        text-transform: uppercase;
        letter-spacing: 0.75px;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;