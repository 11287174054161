/* .animate-enter {
  opacity: 0.01;
  height: 0px;
  transform: translateZ(25px) rotateX(-90deg) scaleY(0);
}

.animate-enter.animate-enter-active {
  opacity: 1;
  height: 100%;
  transform: translateZ(0) rotateX(0deg) scaleY(1);
  transition: opacity 500ms ease-in-out, height 500ms ease-in-out, transform 500ms ease-in-out;
}

.animate-exit {
  opacity: 1;
  height: 100%;
  transform: translateZ(0) rotateX(0deg) scaleY(1);
}

.animate-exit.animate-exit-active {
  opacity: 0.01;
  height: 0px;
  transform: translateZ(25px) rotateX(90deg) scaleY(0);
  transition: opacity 500ms ease-in-out, height 500ms ease-in-out, transform 500ms ease-in-out;
} */
