@import (reference) "styles/theme.less";

.conferenceIcon_light {
  position: absolute;
  z-index: 999;
  height: 45px;
  width: 45px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/img/conference_badge_light.png");
}

.conferenceIcon_dark {
  position: absolute;
  z-index: 999;
  height: 45px;
  width: 45px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/img/conference_badge_dark.png");
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;