@import (reference) "styles/theme.less";
.homeFallback {
  height: 100vh;
  width: 100vw;
  .content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-image: url("/img/work-anywhere-bg.jpeg");
    background-position: 25% top;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .promptEnter,
  .promptAppear {
    opacity: 0.01;
    transform: scale(0.85) translate(-80px, -10px);
  }
  .promptEnterActive,
  .promptAppearActive {
    opacity: 1;
    transform: scale(1) translate(0, 0);
    transition: all 300ms ease-in-out;
  }
  .promptExit {
    opacity: 1;
    transform: scale(1);
  }
  .promptExitActive {
    opacity: 0.01;
    transform: scale(1.1);
    transition: all 300ms;
  }
}

.drawerFallback {
  background-color: transparent;
  height: calc(100vh - 50px);

  .closeDrawer {
    text-transform: uppercase;
    font-size: 12px;
  }
}

.body {
  position: absolute;
  width: 100%;
  max-width: 400px;
  height: 500px;
  max-height: calc(100vh - 100px);
  background-color: var(--white);
  border-radius: 3px;
  border: 1px solid var(--slate-grey);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 24px;

  .oops {
    text-align: center;
    font-size: 24px;
    .blue();
    .bold();
  }
  .message {
    height: 85%;
    margin-top: 24px;
    text-align: justify;

    a {
      .bold();
    }
    .devDetails {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50%;

      details {
        font-size: 10px;
        li {
          font-size: 10px;
        }
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;