@import (reference) "styles/theme.less";

.button {
  width: 100%;
  height: 28px;
  margin-bottom: 40px;
  background: var(--white);
  border-radius: 6px;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  .innerContainer {
    .center();
    .iconContainer {
      .center();
      padding-bottom: 2px;
    }
    .buttonLabel {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      margin-left: 6px;
      color: var(--font-color-eastern-blue);
    }
  }
  &:hover {
    box-shadow: 0px 1px 4px rgba(22, 138, 177, 0.6);
  }
}

.buttonHidden {
  .button();
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;