@import (reference) "styles/theme.less";

#introLayout {
  overflow-y: hidden;
  height: auto;

  .section {
    display: flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;

    .outerContainer {
      width: 100%;
      max-width: 1190px;
    }

    .powurHeader {
      background-color: var(--white);
      height: 182px;
      display: flex;
      align-items: center;
      padding: 10px;
      width: 100%;

      .logo {
        height: 75px;
        width: 188px;
      }

      @media (max-width: 992px) {
        justify-content: center;
      }
      @media (max-width: 768px) {
        height: 120px;
      }
    }

    .innerContainer {
      display: flex;
      justify-content: center;
      padding: 10px;
      .leftBorder {
        border-color: rgb(31, 50, 80);
        border-width: 0px;
        border-style: solid;
        border-left-width: 6px;
        padding-left: 10px;

        @media (max-width: 768px) {
          border-left-width: 0px;
          border-top-width: 4px;
        }
      }

      .marginBottomMed {
        margin-bottom: 26px;
      }

      h1 {
        font-size: 66px;
        line-height: 1.2em;
        font-weight: 600;
        color: rgb(31, 50, 80);

        @media (max-width: 992px) {
          font-size: 48px;
        }
        @media (max-width: 768px) {
          font-size: 40px;
        }
      }
      h2 {
        font-size: 31px;
        line-height: 37px;
        font-weight: 600;

        @media (max-width: 992px) {
          font-size: 34px;
        }
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
      h3 {
        font-size: 27px;
        font-weight: 600;
        line-height: 32px;
        color: rgb(31, 50, 80);

        @media (max-width: 992px) {
          font-size: 24px;
        }
      }
      h4 {
        font-size: 28px;
        line-height: 40px;
        font-weight: 300;
        color: rgb(31, 50, 80);
        margin: 0;

        @media (max-width: 992px) {
          font-size: 17px;
          line-height: 24px;
        }
      }
      h5 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
        color: rgb(31, 50, 80);

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
      p {
        color: var(--outer-space);
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.4px;
        color: rgb(38, 50, 56);
        margin: 0;

        @media (max-width: 992px) {
          font-size: 17px;
        }
      }

      .leftCol {
        @media (max-width: 992px) {
          width: 100%;
        }
      }

      .centeredWhenSmall {
        @media (max-width: 992px) {
          text-align: center;
          .center();
        }
      }
      .centeredWhenExtraSmall {
        @media (max-width: 768px) {
          text-align: center;
          .center();
        }
      }

      .bodyTextContainer {
        margin-bottom: 40px;

        @media (max-width: 768px) {
          padding-top: 23px;
          margin-bottom: 38px;
        }
      }

      .videoContainer {
        width: 100%;
        position: relative;
        padding-bottom: 56%;
        overflow: hidden;
        border-radius: 10px;
        z-index: 10;

        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 9;
          border-radius: 10px;
          background-color: black;
        }

        @media (max-width: 768px) {
          margin-bottom: 36px;
        }
      }

      .emailContainer {
        width: 100%;
        padding: 0 0px;
        margin-top: 16px;
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }

      .insights {
        background-color: rgb(248, 248, 248);
        width: 100%;

        .insightsTopCard {
          margin-top: -200px;
          width: 100%;
          background-color: rgb(248, 248, 248);
          padding: 0 30px;
          border-radius: 6px;
          display: flex;
          align-items: center;

          @media (max-width: 768px) {
            margin-top: -60px;
            padding-top: 30px;
          }
        }

        .titleCol {
          order: 1;

          @media (max-width: 768px) {
            order: 2;
            .center();
            text-align: center;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 16px;
          }
        }

        .imgCol {
          order: 2;

          @media (max-width: 768px) {
            order: 1;
          }
        }

        .insightsIcon {
          background-image: url("/img/education-insights.svg");
          background-repeat: no-repeat;
          background-position-x: right;
          background-position-y: center;
          background-size: contain;
          height: 200px;

          @media (max-width: 768px) {
            height: 150px;
          }
        }

        .cardsTitleRow {
          margin: 0 30px;
        }

        .cardsContainer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 20px;

          @media (max-width: 768px) {
            flex-direction: column;
          }

          .card {
            background-color: var(--white);
            padding: 68px 30px 68px 30px;
            width: calc((100% - 60px) / 3);
            border-radius: 6px;
            box-shadow: 0 0 27px 3px rgba(11, 11, 11, 0.09);

            @media (max-width: 992px) {
              padding: 10px;
            }

            @media (max-width: 768px) {
              width: 100%;
              margin-bottom: 30px;
              padding: 20px;
            }

            .title {
              margin-bottom: 40px;

              @media (max-width: 992px) {
                margin-bottom: 10px;
              }
            }

            p {
              font-size: 19px;
              font-weight: 400;
              color: rgb(38, 50, 56);
              line-height: 34px;

              @media (max-width: 992px) {
                font-size: 14px;
                line-height: 28px;
              }
              @media (max-width: 768px) {
                font-size: 18px;
                line-height: 33px;
              }
            }
          }
        }
      }

      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
      }
    }

    .flexColumn {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .blue {
      color: rgb(32, 194, 241);
    }
  }

  .section1 {
    padding-bottom: 400px;
    background-image: url("/img/education-intro-bg.jpg");
    background-position-x: 50%;
    background-position-y: 150px;
    background-repeat: no-repeat;
    @media (max-width: 990px) {
      padding-bottom: 250px;
      background-image: url("/img/education-intro-bg-md.jpg");
      background-position: bottom center;
      background-size: cover;
    }
  }

  .section2 {
    background-color: rgb(248, 248, 248);
    padding-bottom: 100px;
    border-bottom: 1px solid rgb(238, 238, 238);
  }

  .section3 {
    padding: 30px 0;

    .arrowRow {
      width: 100%;
      height: 100px;
      .center();
    }

    .titleRow {
      max-width: 1000px;
      text-align: center;
      .title {
        font-size: 48px;
        line-height: 58px;
        color: rgb(31, 50, 80);

        @media (max-width: 992px) {
          font-size: 34px;
          line-height: 40px;
        }
      }
    }

    .conclusionRow {
      max-width: 900px;
      .center();
      flex-direction: column;
    }

    .emailRow {
      margin: 50px 0 100px 0;
      width: 100%;
      max-width: 1000px;
      box-shadow: 0 0 27px 3px rgba(11, 11, 11, 0.09);
    }
  }
}

// WHY SOLAR STYLES
.whySolarContainer {
  overflow-y: hidden !important;

  .h4 {
    color: var(--darker-blue);
    font-family: comfortaa, Sans-serif;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.5px;
  }

  .h1 {
    color: var(--darker-blue);
    font-size: 48px;
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: -2.8px;
    margin: 5px 0px 15px 0px;
  }

  .h2 {
    color: var(--outer-space);
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5em;
  }

  .p {
    color: var(--outer-space);
    font-family: "Poppins", Sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 1.5em;
  }

  .headerContainer {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;

    .headerItem {
      flex: 1 1 500px;
      margin: 20px 0px 20px 0px;

      .emptyButton {
        border: none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1000px) {
          display: none;
        }
      }

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logo {
      padding: 50px 0px 50px 0px;
      height: 14px;
      width: 16px;
      background: url("/img/logo.svg") center/auto 70% no-repeat;
    }
  }

  .contentContainer {
    background: var(--lightest-grey) url("/img/education-videos-bg.jpg") center top / auto 1030px no-repeat !important;

    text-align: center;
    justify-content: center;
    width: 100%;

    .videoDescriptionContainer {
      width: 100%;
      max-width: 1200px;
      min-height: 235px;
      margin: auto;
      padding: 0px 40px;

      @media (max-width: 800px) {
        background-color: rgba(248, 248, 248, 0.8);
        background: rgb(248, 248, 248);
        background: linear-gradient(
          180deg,
          rgba(248, 248, 248, 0) 0%,
          rgba(248, 248, 248, 1) 30%,
          rgba(248, 248, 248, 1) 95%,
          rgba(248, 248, 248, 0) 100%
        );
      }
    }
    .consultationDescriptionContainer {
      width: 100%;
      max-width: 1200px;
      margin: auto;
      padding: 0px 40px;
      padding-bottom: 30px;
      background-color: rgba(248, 248, 248, 1);
    }

    // .videoContainer {
    //   // width: 100%;
    //   justify-content: center;

    //   .iframe {
    //     background-color: black;
    //     width: 940px;
    //     height: 0;
    //     padding-bottom: 56.25%;

    //     margin: 20px 10px 20px 10px;
    //     box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3),
    //       0 0 20px 20px rgba(0, 0, 0, 0.2);
    //     border-radius: 10px;
    //   }
    // }
    .videoContainerMargin {
      max-width: 960px;
      margin: auto;
      padding: 0px 10px;
      display: flex;
      justify-content: center;
    }

    .videoContainer {
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3), 0 0 20px 20px rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      width: 100%;
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;
      border-radius: 10px;
      z-index: 10;

      .iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 9;
        border-radius: 10px;

        background-color: black;
      }
    }

    .previewContainer {
      max-width: 1200px;
      margin: 20px 10px;
      display: flex;
      justify-content: space-between;
      background-color: var(--lightest-grey);
      box-shadow: 0 -5px 5px 0px rgba(0, 0, 0, 0.2), 0 -10px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .previewCol {
        flex: 1 0 200px;
        .previewButton {
          padding: 15px;
          background-color: transparent;
          border: none;
          height: max-content;
          box-shadow: none;

          img {
            width: 100%;
            min-width: 200px;
          }
        }
      }
    }

    .centerChevrons {
      background-color: var(--lightest-grey);
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 60px;
    }

    .formContainer {
      display: flex;
      justify-content: space-around;
      width: 100%;
      max-width: 1300px;
      margin: 0px 10px 50px 10px;
      text-align: center;

      .numberAvatar {
        text-align: center;
      }

      .formContainerItem {
        flex: 0 1 600px;
        padding-top: 40px;
        max-width: 600px;

        p {
          padding: 0px 60px 0px 60px;
          text-align: left;
          @media (max-width: 1024px) {
            padding: 0px 20px 0px 20px;
          }
        }
      }

      .boxShadow {
        background-color: var(--white);
        box-shadow: 0 0 26.68px 2.38px rgba(11, 11, 11, 0.09);
        border: 1px solid #ebebeb;
        border-radius: 10px;
      }

      .topTenList {
        text-align: left;

        ul {
          padding: 0px 60px 0px 60px;
          @media (max-width: 1024px) {
            padding: 0px 20px 0px 20px;
          }
        }

        li {
          list-style: none;
          display: flex;
          align-items: center;
          padding: 16px 0px 16px 0px;
        }

        li::before {
          content: "";
          display: inline-block;
          height: 28px;
          width: 28px;
          background-image: url("/icons/icon_yellow_circle_chevron_edu.svg");
          margin-right: 10px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

// TOP TEN STYLES
.topTenQuestionsContainer {
  .whySolarContainer();

  .contentContainer {
    background-image: url(/img/education_catalogpage_bg_new_2.jpg) !important;
    background-position-x: center !important;
    background-position-y: top !important;
    background-repeat: no-repeat !important;
    background-size: 2700px 1400px !important;
    font-family: "Poppins", sans-serif;

    @media (max-width: 990px) {
      background-image: url(/img/education_solar_edu_playlist_mobile_bg.jpg) !important;
      background-position-x: center !important;
      background-position-y: 600px !important;
      background-repeat: no-repeat !important;
      padding-bottom: 0px;
    }
    .mainContent {
      padding-bottom: 200px;
      text-align: left;
      justify-content: center;
      width: 100%;
      @media (max-width: 990px) {
        padding-bottom: 0px;
      }
    }
    .videoDescriptionContainer {
      max-width: 1300px;
      height: auto;
      .currentVideoText {
        color: var(--darker-blue);
        font-size: 26px;
        font-weight: 600;
        letter-spacing: -0.25px;
        @media (max-width: 990px) {
          text-align: center;
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      .currentVideoTitle {
        color: var(--darker-blue);
        font-size: 48px;
        font-weight: 600;
        line-height: 1.2em;
        letter-spacing: -0.4px;
        margin-bottom: 0;
        @media (max-width: 990px) {
          text-align: center;
          font-size: 36px;
        }
      }
      .listTitle {
        color: var(--darker-blue);
        font-size: 17px;
        font-weight: 600;
        text-align: left;
        padding-bottom: 26px;
        padding-left: 8px;
        @media (max-width: 990px) {
          text-align: center;
          margin: auto;
          padding-bottom: 0px;
        }
      }
      .titleRow {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
      }
      .videoTextSection {
        color: var(--mineshaft-gray);
        border-left: 3px solid #203250;
        padding-left: 20px;
        margin: 40px 0;
        width: 92%;
        @media (max-width: 990px) {
          width: 100%;
        }
        .videoTextTitle {
          color: var(--mineshaft-gray);
          font-size: 1.7rem;
          line-height: 1.4;
          margin-bottom: 2px;
          font-weight: 600;
          @media (max-width: 990px) {
            font-size: 20px;
            margin-left: 0;
          }
        }
        .videoTextBody {
          line-height: 1.88;
        }
      }
      .shareText {
        font-size: 19px;
        overflow-wrap: normal;
        line-height: 1.2em;
        width: 100%;
        color: var(--mineshaft-gray);
        padding-top: 50px;
        @media (max-width: 990px) {
          font-size: 15px;
          line-height: 1.4em;
        }
      }
      .socialButtons {
        display: flex;
        justify-content: space-between;
        @media (max-width: 990px) {
          padding-top: 10px;
          padding-bottom: 30px;
        }
        .button {
          font-size: 10px;
          border: none;
          border-radius: 0.5em;
          color: var(--white);
          padding: 4px 10px;
          width: 90%;
          height: 45px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          span {
            font-family: "Carme", Sans-serif;
            font-size: 14px;
            letter-spacing: 0.8px;
          }
          .icon {
            width: 17px;
            height: 17px;
            background-size: cover;
            margin-right: 6px;
          }
          .antdIcon {
            font-size: 17px;
          }
        }
        .facebook {
          .button();
          background-color: var(--dark-lavender);
          .icon {
            background-image: url("/icons/icon_facebook.png");
            background-size: cover;
          }
        }
        .twitter {
          .button();
          background-color: var(--azure-blue);
        }
        .linkedin {
          .button();
          background-color: var(--deep-cerulean);
        }
      }
      .videoRow {
        display: flex;
        width: 100%;
        justify-content: space-around;
        @media (max-width: 990px) {
          padding: 0px 20px;
        }
        .iframeContainer {
          width: calc(100% - 440px);
          height: 100%;
          @media (max-width: 990px) {
            margin: 20px 0px 0px 0px;
            border-radius: 0;
            width: 100%;
            height: auto;
          }
        }
        iframe {
          background-color: black;

          // width: 748.5px; // origional ratio
          // height: 421.317px; // origional ratio
          min-height: 400px; //~NH check iframe alignment
          width: 92%;
          // height: 420px;

          margin-top: 50px;
          border-radius: 10px;
          @media (max-width: 990px) {
            margin: 20px 0px 0px 0px;
            border-radius: 0;
            width: 100%;
          }
        }

        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-track {
          background: var(--jungle-mist);
        }
        ::-webkit-scrollbar-thumb {
          background: var(--chilly-blue);
          border-radius: 10px;
        }
        .questionContainer {
          width: 400px;
          @media (max-width: 990px) {
            width: 100%;
          }
        }
        .questionSelect {
          display: flex;
          flex-direction: column;
          width: 400px;
          height: 400px; //~NH check iframe alignment
          overflow-y: auto;
          text-align: left;
          background-color: var(--white);
          @media (max-width: 990px) {
            width: 100%;
          }
          .button {
            margin-bottom: 1px;
            min-height: 63px;
            text-align: left;
            border: none;
            cursor: pointer;
            background-color: var(--anti-flash-white);
            &:hover {
              background-color: var(--hawkes-blue);
            }
            .text {
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
              font-size: 17px;
              font-weight: 400;
              line-height: 36px;
              letter-spacing: -0.25px;
              color: var(--darker-blue);
              padding: 13px 27px;
            }
            &.selected {
              background-color: var(--hawkes-blue);
            }
          }
        }
      }
    }
    .marketingFormContainer {
      background-color: var(--lightest-grey);
      width: 95%;
      display: flex;
      justify-content: center;
      border-radius: 7px;
      .chevronContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 100px 0 20px 0;
      }
      .h2 {
        color: var(--outer-space);
        font-family: "Poppins", Sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5em;
      }
      .numberAvatar {
        text-align: center;
      }

      .formContainerItem {
        flex: 1 1 500px;
        padding: 25px 40px 10px 40px;
        max-width: 800px;
        text-align: center;
      }

      .boxShadow {
        background-color: var(--white);
        box-shadow: 0 0 26.68px 2.38px rgba(11, 11, 11, 0.09);
        border: 1px solid #ebebeb;
        border-radius: 10px;
      }

      .topTenList {
        text-align: left;

        ul {
          padding: 0px;
        }

        li {
          list-style: none;
          display: flex;
          align-items: center;
          padding: 15px 0px 15px 0px;
        }

        li::before {
          content: "";
          display: inline-block;
          height: 28px;
          width: 28px;
          background-image: url("/icons/icon_yellow_circle_chevron_edu.svg");
          margin-right: 10px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

// SUBMIT REDIRECT STYLES
.submitRedirectPageContainer {
  padding-top: 30px;
  .backgroundContainer {
    background: url("/img/education_submit_bg.jpg");
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 1950px;
    @media (max-width: 776px) {
      height: 1650px;
      background-size: contain;
    }
    @media (max-width: 582px) {
      height: 1750px;
    }
    @media (max-width: 538px) {
      height: 1800px;
    }
    @media (max-width: 456px) {
      height: 1850px;
    }
    @media (max-width: 438px) {
      height: 1950px;
    }
    @media (max-width: 412px) {
      height: 2000px;
    }
    @media (max-width: 382px) {
      height: 2080px;
    }
    .row {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      max-width: 1050px;
      width: 90%;
      margin: 0 auto;
      .powurLogo {
        height: 50px;
        width: 177px;
        background: url("/img/powur_logo.svg");
        background-repeat: no-repeat;
        background-position: 50% 40%;
        background-size: 500px 200px;
      }
    }
    .header {
      text-align: center;
      color: var(--darker-blue);
      font-family: "Poppins", Sans-serif;
      font-size: 60px;
      font-weight: 600;
      line-height: 1.2em;
      letter-spacing: -0.4px;
      margin: 80px 0 25px 0;
      @media (max-width: 1024px) {
        font-size: 40px;
      }
    }
    .subHeader {
      color: var(--outer-space);
      font-family: "Poppins", Sans-serif;
      font-size: 33px;
      font-weight: 500;
      text-transform: none;
      line-height: 1.2em;
      letter-spacing: -0.4px;
      margin-bottom: 50px;
      @media (max-width: 1024px) {
        font-size: 24px;
      }
    }

    .contentContainer {
      background: url("/img/powur_overlay.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 400px 400px;
      background-color: var(--lighthouse-white);
      max-width: 890px;
      min-height: 690px;
      width: 90%;
      margin: 0 auto;
      border: 4px solid rgb(32, 194, 241);
      box-shadow: 0 13px 39px 7px rgba(11, 11, 11, 0.18);
      padding: 40px;
      @media (max-width: 1024px) {
        padding: 30px;
        min-height: 640px;
      }
      .paragraph {
        color: var(--outer-space);
        font-family: "Poppins", Sans-serif;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.4px;
        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }
      .paragraphBold {
        .paragraph();
        font-weight: 600;
        margin-bottom: 30px;
      }
      .paragraphNormal {
        .paragraph();
        font-weight: 300;
        margin-bottom: 40px;
      }
    }

    .quoteContainer {
      width: 90%;
      max-width: 530px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: var(--outer-space);
      font-size: 18px;
      font-weight: 400;
      font-style: italic;
      line-height: 30px;
      letter-spacing: -0px;
      margin-top: 70px;
      @media (min-width: 776px) {
        position: relative;
      }
      .quoteIcon {
        margin: 0 auto;
        margin-bottom: 25px;
        @media (min-width: 776px) {
          position: absolute;
          top: 0;
          left: -40px;
        }
        width: 100px;
        height: 80px;
        background: url("/img/quotes.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100px 80px;
      }
      .quoteText {
        z-index: 2;
      }
      .nameContainer {
        display: flex;
        justify-content: flex-end;
        font-weight: 600;
      }
      .avatarContainer {
        display: flex;
        justify-content: center;
        .avatar {
          height: 126px;
          width: 126px;
          background: url("/img/education_avatar.png");
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
        }
      }
    }
  }
}

// SHARED COMPONENTS

// Resizable Button
.resizableButtonContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .resizableButton {
    background-color: rgb(32, 194, 241);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 130%;
    z-index: 2;
  }
  .resizableButtonUnderlay {
    .resizableButton();
    position: absolute;
    animation: radar 2s infinite;
    z-index: 1;
  }
  .small {
    height: 60px;
    width: 210px;
    font-weight: 250;
    &:hover {
      height: 54px;
      width: 189px;
      font-size: 120%;
    }
  }

  .large {
    height: 80px;
    width: 400px;
    font-weight: 600;
    &:hover {
      height: 72px;
      width: 360px;
      font-size: 120%;
    }
  }
  .pulseAnim {
    animation: pulse 2s infinite;
  }
  .radarAnim {
    animation: radarPulse 2s infinite;
  }
}

@keyframes pulse {
  50% {
    transform: scale(1.1);
  }
}

@keyframes radarPulse {
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes radar {
  25% {
    transform: scale(0.9);
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    transform: scaleY(1.5) scaleX(1.2);
    opacity: 0;
  }
}

.animatedChevronsContainer {
  position: relative;
  padding: 8px;
  height: 40px;
  width: 40px;
  .chevron {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .large {
    height: 20px;
    width: 28px;
  }
  .small {
    height: 10px;
    width: 18px;
  }
  .chevron1 {
    position: absolute;
    top: 5px;
    .chevron();
    background-image: url("/icons/icon_chevron_edu.svg");
    animation: chevron1 2s infinite;
    &.small {
      top: 9px;
    }
  }
  .chevron2 {
    position: absolute;
    top: 20px;
    .chevron();
    background-image: url("/icons/icon_chevron_edu.svg");
    animation: chevron2 2s infinite;
  }
}

@keyframes chevron1 {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  20% {
    transform: translateY(0px);
    opacity: 1;
  }
  40% {
    opacity: 1;
    transform: translateY(0px);
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes chevron2 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translateY(-5px);
    opacity: 0;
  }
  70% {
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

// Page Footer Styles
.pageFooter {
  background-color: var(--white);
  width: 100%;
  font-family: "Poppins", Sans-serif;
  .upperSection {
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 40px;
    text-align: center;
    .header {
      font-size: 28px;
      font-weight: 600;
      color: var(--outer-space);
      letter-spacing: -0.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1000px) {
        font-size: 18px;
      }
    }
    .orgContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 832px) {
        flex-direction: column;
      }
      .orgCol {
        padding: 20px;
        width: 250px;
        p {
          margin: 0 auto;
          text-align: center;
          font-size: 12px;
          font-weight: 300;
          color: rgb(58, 58, 58);
          width: 75%;
          letter-spacing: 0.3px;
        }
        @media (max-width: 832px) {
          width: 100%;
        }
      }
    }
    .logoRow {
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        max-height: 130px;
      }
    }
  }

  .middleLine {
    border-bottom: 1px solid var(--light-grey);
  }

  .lowerSection {
    // border-top: 1px solid var(--light-grey);
    width: 95%;
    max-width: 1100px;
    margin: 0 auto;
    .logoRow {
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      .powurLogo {
        height: 50px;
        width: 177px;
        background: url("/img/powur_logo.svg");
        background-repeat: no-repeat;
        background-position: 50% 40%;
        background-size: 400px 160px;
      }
    }
    .copywriteText {
      margin: 0 auto;
      font-size: 14px;
      font-weight: 300;
      color: rgb(38, 50, 56);
      margin-bottom: 32px;
    }
  }
}

.emailInput {
  width: 100%;
  min-height: 200px;
  .center();
  flex-direction: column;
  background-color: var(--white);
  border-radius: 6px;
  border: 1px solid rgb(200, 200, 200);
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px 24px 6px 24px;

  @media (max-width: 768px) {
    padding-top: 48px;
    padding-bottom: 48px;
    overflow: visible;
  }

  .headerRow {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .arrowCol {
      height: 100%;
      width: 100%;
      .center();

      @media (max-width: 768px) {
        padding: 24px 0;
      }
    }
  }

  .inputRow {
    width: 100%;
    margin: 10px 0;
    height: 55px;

    @media (max-width: 768px) {
      height: auto;
    }

    .col {
      height: 100%;

      @media (max-width: 768px) {
        width: 100%;
        height: 55px;
      }
    }

    .inputCol {
      .col();
    }

    .buttonCol {
      .col();

      @media (max-width: 768px) {
        margin-top: 16px;
      }
    }

    .input {
      height: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      padding: 17px 15px;
    }
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: rgb(31, 50, 80);

    @media (max-width: 768px) {
      font-size: 18px;
      .center();
    }
  }

  .buttonContainer {
    width: 100%;
    height: 100%;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;