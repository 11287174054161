@import (reference) "styles/theme.less";

.subHeaderContainer {
  display: flex;
  margin-top: 16px;
  margin-bottom: 4px;
  align-items: center;

  .pill {
    width: 5px;
    height: 26px;
    min-width: 5px;
    margin-right: 10px;
    border-radius: 3px;
  }

  .smallPill {
    .pill();
    height: 16px;
  }

  .title {
    font-family: Poppins;
    display: block;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
  }

  .smallTitle {
    .title();
    font-size: 14px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;