@import (reference) "styles/theme.less";
@import "components/Tables/TableOverride.module.less";

@Poppins: "Poppins", "Barlow", sans-serif;

.popoverContent {
  color: var(--white);
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
}

.popOverride {
  :global(.ant-popover-arrow) {
    left: 60px !important;
  }
}

.tableContainer {
  .table();
  position: relative;

  :global(.ant-table-pagination) {
    display: flex;
    align-items: center;

    :global(.ant-pagination-total-text) {
      background: var(--white);
      border-radius: 67px;
      height: 28px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      padding: 0px 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--gothic-blue);
    }

    .sharedPagination {
      box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
      margin: 0 5px;
      background-color: red;
      border-radius: 50px;
      height: 28px;
      width: 28px;
      position: relative;

      svg {
        color: black;
      }
    }

    .leftRightArrow {
      margin: 0 5px;
      border-radius: 50px;
      height: 28px;
      width: 28px;
      background-image: url("/icons/icon_triangle_circle.svg");
      background-image: url("/icons/icon_triangle_circle.svg");
      background-size: 120%;
      background-position: center;
    }

    .sharedAfter {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 28px;
      height: 28px;
      padding-left: 4px;
      padding-top: 3px;
      background-image: url("/icons/icon_triangle_circle.svg");
      background-size: 120%;
      background-position: center;
      border-radius: 50%;
      z-index: 6;
    }

    :global(.ant-pagination-prev) {
      .leftRightArrow();
    }

    :global(.ant-pagination-next) {
      .leftRightArrow();
    }

    :global(.ant-pagination-next > button) {
      cursor: pointer;
    }

    :global(.ant-pagination-item) {
      margin: 3px;
      vertical-align: baseline;
      height: 28px;
      border: none;

      a {
        background-color: var(--white);
        box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        height: 28px;
        min-width: 28px;
        line-height: 28px;
        color: var(--primary-color);
        font-weight: 600;
      }
    }

    :global(.ant-pagination-item-active) {
      background-color: transparent;
      border: none;
      border-radius: 50px;

      a {
        background-color: transparent;
        color: var(--white);
        background-color: var(--primary-color);
      }
    }
  }

  :global(.ant-table-column-has-sorters) {
    background: var(--white);

    &:hover {
      background: var(--white) !important;
    }
  }

  .paginationContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0 6px 0;
    height: 50px;
    width: 100%;
  }

  .goldStatusText {
    font-size: 11px;
    font-weight: 600;
    line-height: 17px;
    color: var(--pumpkin);
  }

  .whiteBackground {
    background: var(--white);
  }

  .leadOfferContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 100px;

    .offerInfoButton {
      height: 22px;
      width: 22px;
      padding: 0;
      .center();
      background: transparent;
      border: none;
      margin-right: 4px;
      border-radius: 31px;
    }
  }

  :global(.ant-table-fixed-header) {
    background-color: transparent;
  }

  margin-top: 0;
  :global(.ant-table-thead) {
    border-radius: 5px;
    .columnHeader {
      color: var(--nepal-blue);
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0em;
      text-transform: uppercase;
    }

    .centeredColumnHeader {
      .columnHeader();
      text-align: center;
      padding: 0px;
    }

    .columnHeaderCentered {
      // .columnHeader();
      text-align: center;
      margin-left: -30px;
    }

    .blue {
      color: var(--nepal-blue);
    }
  }

  .netRevRow {
    display: flex;
    align-items: center;
    padding: 7px;
    width: 138px;
    .netRevCol {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .netRevEditCol {
      .netRevCol();
      .formItem {
        margin-bottom: 0;
      }
      padding-left: 2px;
    }
  }

  .primaryText {
    font-size: 12px;
    color: var(--primary-color);
  }

  .editMode {
    background-color: var(--table-edit-mode);
    border-radius: 4px;

    :global(.anticon) {
      margin: 1px 5px 1px 5px;
    }
    :global(.anticon-check-circle) {
      margin-top: 5px;
    }
    :global(.anticon-close-circle) {
      margin-bottom: 5px;
    }
  }

  .smallText {
    font-size: 12px;
    font-weight: 400;
    color: var(--table-grey);
  }

  .medText {
    .smallText();
    font-size: 14px;
  }

  .medTextStrike {
    .medText();
    color: var(--table-grey);
    text-decoration: line-through;
  }

  .disabledText {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: var(--gothic-blue);
  }

  .formItem {
    font-size: 11px;
    color: var(--primary-color);
    padding: 0px;

    .bold {
      font-weight: 600;
      color: var(--table-grey);
    }

    :global(.ant-form-item-control-input) {
      min-height: 21px;
    }

    :global(.ant-input) {
      background-color: var(--white);
      width: 90px;
      padding: 4px 6px 4px 6px;
      font-size: 12px;
      line-height: 17px;
      text-align: right;
      color: var(--table-grey);
      border: 1px solid var(--primary-color);
      border-radius: 4px;

      &::placeholder {
        color: var(--primary-color);
      }
      &:focus {
        color: var(--primary-color);
        background-color: var(--white);
      }
    }

    :global(.ant-picker) {
      background-color: rgba(255, 255, 255, 0);
      // margin-right: 10px;
      width: 95px;

      border: 0px;
      padding: 0px 1px 0px 0px;
      &:focus {
        color: var(--primary-color);
        border: 0px;
      }
    }
    :global(.ant-picker-focused) {
      -webkit-box-shadow: 0 0 0 0px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0px rgba(255, 255, 255, 0);
      border: 1px solid var(--primary-color);
      &:focus {
        color: var(--primary-color);
        border: 0px;
      }
    }

    :global(.ant-picker-input) {
      > input {
        padding: 0px 0px 0px 0px;
        font-size: 11px;
        // line-height: 17px;
        text-align: right;
        color: var(--table-grey);
        // bottom: -1px;
        &::placeholder {
          color: var(--primary-color);
        }
      }
      :global(.ant-picker-suffix) {
        padding: 0px 2px 0px 6px;
        margin: 0px 10px 0px 0px;
      }
      :global(.ant-picker-clear) {
        margin: 0px 10px 0px 0px;
      }
      .calendarIcon {
        height: 12px;
        width: 12px;
        background-image: url("/icons/icon_calendar.svg");
        background-size: cover;
      }
    }
  }

  .editOutlinedIcon {
    width: 21px;
    height: 21px;
    font-size: 10px;
    padding: 5.5px 6.5px 6.5px 5.5px;
    background-color: var(--table-edit-mode);
    color: var(--primary-color);
    margin: 5px 9px 5px 9px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    &:hover {
      background: var(--table-edit-mode);
      filter: brightness(90%);
      transition: background-color 0.2s ease-in-out;
    }
  }

  .checkCircleFilledIcon {
    width: 21px;
    height: 21px;
    font-size: 21px;
    background-color: var(--table-edit-mode);
    color: var(--primary-color);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    &:hover {
      color: var(--primary-color-highlighted);
      transition: color 0.2s ease-in-out;
    }
  }

  .closeCircleFilledIcon {
    width: 21px;
    height: 21px;
    font-size: 21px;
    background-color: var(--table-edit-mode);
    color: var(--table-button-cancel);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    &:hover {
      color: var(--table-button-cancel-highlighted);
      transition: color 0.2s ease-in-out;
    }
  }

  .tallRow {
    background-color: var(--white);
    :global(.ant-table-cell) {
      height: 84px;
    }
  }

  .statistics {
    position: absolute;
    top: 20px;
    right: -165px;
  }

  .column {
    height: 100%;
    padding: 8px 6px;
    overflow: hidden;
  }

  .whitePaddedColumn {
    .column();
    background-color: var(--white);
    padding-left: 8px !important;

    &:hover {
      background-color: var(--white);
    }
  }

  .avatarColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .userAvatar {
    .center();
    margin-right: 8px;
  }

  .insetColumn {
    height: 100%;
    padding: 8px 18px !important;
    overflow: hidden;
  }

  td.fixedHeightColumn {
    height: 80px;
    max-height: 80px;
    min-height: 80px;
    padding: 5px 5px 5px 20px !important;
  }

  td.centerColumn {
    height: 80px;
    max-height: 80px;
    min-height: 80px;
    padding: 10px !important;
  }

  td.fixedHeightAvatarColumn {
    height: 80px;
    max-height: 80px;
    min-height: 80px;
    padding: 5px !important;
  }

  th.fixedHeightColumn {
    padding: 16px 16px 16px 20px;
  }

  th.centerColumn {
    padding: 0px !important;
  }

  .leftColumn {
    .column();
    padding: 8px 6px 8px 16px !important;
  }

  .cellContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 6px 8px 8px;
  }

  .personCellContainer {
    .cellContainer();
    padding: 0;
  }

  .cellContainerCentered {
    .center();
  }

  .cellContainerLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 6px 8px 16px;
  }

  .centeredTextColumn {
    .column();
    text-align: center;
  }

  .shrunkenCol {
    .column();
    padding: 4px 16px;
  }

  .row {
    cursor: pointer;
  }

  .pendingRow {
    td:first-of-type {
      border-width: 0;
      border-left: 6px solid;
      border-color: var(--pumpkin);
    }
  }

  .acceptedRow {
    .pendingRow();
    td:first-of-type {
      border-color: var(--eggplant);
    }
  }

  .rowNoHover {
    &:hover > .column,
    &:hover > .centeredColumn,
    &:hover > .fixedHeightColumn {
      background-color: unset;
    }
  }

  .rowWhite {
    background-color: var(--white);
  }

  .darkRow {
    background-color: var(--lightest-grey) !important;
  }

  .shrunkenRow {
    padding: 2px 16px;
  }

  .cellRow {
    .ellipsis();
  }

  .centeredColumn {
    .column();
    .itemContainer {
      display: flex;
      justify-content: center;
    }
    .columnHeader {
      text-align: center;
    }
  }

  .rightColumn {
    padding-right: 8px;
  }
  .kind {
    text-transform: capitalize;
  }

  .performanceTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .columnHeader {
      margin-left: 4px;
    }
  }

  .columnHeader {
    color: var(--nepal-blue);

    .downArrow {
      cursor: pointer;
      margin-left: 4px;
    }
  }

  .cell {
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    max-width: 100%;
    .ellipsis();
  }

  .cellLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    .ellipsis();
  }

  .horizontalCell {
    .cell();
    flex-direction: row;
    text-align: center;
    align-items: center;
    cursor: default;
  }

  .cellHorizontal {
    .cell();
    flex-direction: row;
    text-align: center;
    align-items: center;
    cursor: default;
    span {
      padding-left: 14px;
    }
  }

  .noteContainer {
    width: 100%;
    height: 100%;

    .note {
      font-size: 10px;
    }
  }

  .name {
    font-size: 14px;
    .ellipsis();
  }

  .nameLarge {
    font-size: 14px;
    text-transform: capitalize;
  }

  .bold {
    .name();
    font-weight: 600;
  }

  .boldBlue {
    font-family: @Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--dark-blue);
  }

  .darkBlue {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--darker-blue);
  }

  .boldLarge {
    .name();
    font-size: 18px;
    line-height: 17px;
    font-weight: 600;
  }

  .email {
    .name();
    .ellipsis();
    color: var(--dark-blue);
    font-size: 12px;
    font-weight: 500;
  }

  .italic {
    font-style: italic;
  }

  .projectName {
    font-family: @Poppins;
    font-weight: 700;
    font-size: 14px;
    color: var(--darkest-blue);
  }

  .projectID {
    font-weight: 400;
    font-size: 12px;
    color: var(--primary-color);
  }

  .eventTitle {
    .email();
    display: inline-block;
  }

  .subRow {
    .name();
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    color: var(--table-grey);
  }

  .date {
    .name();
    display: flex;
    align-items: center;
    color: var(--table-grey);
    font-size: 14px;
  }

  .dateLarger {
    .date();
    font-size: 14px;
  }

  .address {
    .name();
    font-size: 14px;
    line-height: 17px;
    color: var(--table-grey);
  }

  .id {
    .name();
    color: darken(rgb(233, 234, 235), 20%);
  }

  .referrer {
    font-size: 10px;
    color: var(--slate-grey-a65);
  }

  .icon {
    font-size: 22px;
    cursor: pointer;
  }

  .certificationContainer {
    position: relative;
  }

  .certificationItem {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .certificationIcon {
    .icon();
    margin-left: 8px;
    color: var(--primary-color);
    font-size: 14px;

    &:hover {
      transform: scale(1.1, 1.1);
      transition: transform 0.2s ease-in-out;
    }
  }

  .certificationDatePicker {
    input {
      font-size: 12px;
    }
  }

  .actionButton {
    width: 75px;
  }

  .accountBadge {
    margin: 2px;
    width: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
    background-color: var(--table-badge-people);
    color: var(--darker-blue);
  }
}

.leadsTableContainer {
  :global(.ant-table-thead) {
    @media (max-width: 628px) {
      display: none;
    }
  }
}

.cardsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3px;

  .emptyContainer {
    width: 100%;
    margin-top: 5px;
    background-color: var(--white);
    border-radius: 5px;
  }

  .goldStatusText {
    font-size: 11px;
    font-weight: 600;
    line-height: 17px;
    color: var(--pumpkin);
  }

  .leadOfferContainer {
    // background-color: pink;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    justify-content: flex-start;
    align-items: center;

    .offerInfoButton {
      height: 22px;
      // width: 27px;
      padding: 0;
      .center();
      background: transparent;
      border: none;
      margin-right: 4px;
      border-radius: 31px;
    }
  }

  :global(.ant-pagination) {
    width: 100%;
    margin-top: 10px;
    align-items: baseline;
    :global(.ant-pagination-prev),
    :global(.ant-pagination-next) {
      margin: 0 5px;
      box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
      background-color: var(--white);
      border-radius: 50%;
      height: 28px;
      min-width: 28px;
      line-height: 28px;
      div {
        height: 28px;
      }
    }
    :global(.ant-pagination-next > button) {
      cursor: pointer;
    }
    :global(.ant-pagination-item) {
      margin: 5px 2px;
      vertical-align: baseline;
      border-radius: 50px;
      background-color: transparent;
      border: none;
      padding: 0 2px;
      div {
        box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
        background-color: var(--white);
        border-radius: 50px;
        height: 28px;
        min-width: 28px;
        line-height: 28px;
        padding: 0 4px;
      }
      @media (max-width: 430px) {
        margin: 5px 0px;
        div {
          padding: 0 2px;
        }
      }
    }
    :global(.ant-pagination-item-active) {
      background-color: transparent;
      border: none;
      border-radius: 50%;
      div {
        color: var(--white);
        height: 28px;
        background-color: var(--primary-color);
      }
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 75px;
    background-color: rgba(240, 242, 245, 0.45);
    z-index: 6;

    .spin {
      z-index: 7;
    }
  }

  .paginationContainer {
    width: 100%;
    margin-top: 10px;

    .pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 42px;
    }
  }

  .card {
    position: relative;
    width: 100%;
    max-width: 576px;
    min-height: 84px;
    margin: 10px 5px;
    margin-bottom: 0;
    border-radius: 5px;
    padding: 20px;
    padding-right: 25%;
    background-color: var(--white);
    cursor: pointer;
    .buttonShadow();

    .openDrawer {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      background: none;

      .openDrawerIcon {
        color: var(--primary-color);
        font-size: 12px;
      }

      .openDrawerText {
        font-size: 10px;
        text-align: center;
      }
    }

    .row {
      margin: 0;
      .ellipsis();
    }

    .consultantCol {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      // padding-left: 4px;
    }

    .peopleCol {
      .flex();
      .ellipsis();
      align-items: flex-start;
    }

    .cardId {
      .idBox();

      .id {
        font-size: 10px;
      }
    }

    .clickable {
      cursor: pointer;
    }

    .headline {
      font-size: 14px;
    }

    .text {
      font-size: 12px;
    }

    .email {
      .text();
      color: var(--primary-color);
    }

    .projectName {
      font-family: @Poppins;
      font-weight: 700;
      font-size: 18px;
      color: var(--darkest-blue);
    }

    .leadInfo {
      font-size: 16px;
      font-weight: 400;
      line-height: 17px;
    }

    .detail {
      font-size: 16px;
      font-weight: 400;
      color: var(--table-grey);
    }

    .detailBold {
      .detail();
      font-weight: 600;
      color: var(--table-grey);
    }

    .icon {
      font-size: 18px;
      color: var(--light-grey);
    }

    .flex {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;
    }

    .flexMiddle {
      .flex();
      align-self: center;
    }

    .flexMiddleNotCenter {
      display: flex;
      flex-direction: column;
      align-self: center;
      padding-right: 12px;
    }

    .editOutlinedIcon {
      width: 22px;
      height: 22px;
      font-size: 10px;
      padding: 5.5px 6.5px 6.5px 5.5px;
      background-color: var(--table-edit-mode);
      color: var(--primary-color);
      margin: 5px 9px 5px 9px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 18px;
      &:hover {
        background: var(--table-edit-mode);
        filter: brightness(90%);
        transition: background-color 0.2s ease-in-out;
      }
    }
  }

  .leadsCard {
    .card();
    min-height: 130px;
    @media (max-width: 435px) {
      min-height: 190px;
    }
  }
  .ambassadorCard {
    .card();
    padding: 12px;
    min-height: 120px;

    .centerCol {
      display: flex;
      align-items: center;

      .avatarContainer {
        .center();
      }
    }

    .value {
      font-size: 11px;
    }

    .rightRow {
      line-height: 14px;
    }

    .valueColSmallText {
      padding-left: 20px;
    }

    @media (max-width: 435px) {
      min-height: 180px;
    }
  }
  .projectCard {
    .card();
    max-width: unset;
    padding: 8px 16px;
    min-height: 100px;
  }

  .pendingCard {
    border-width: 0;
    border-left: 6px solid;
    border-color: var(--pumpkin);
  }

  .acceptedCard {
    .pendingCard();
    border-color: var(--eggplant);
  }

  .enterpriseLeadsCard {
    .card();
    min-height: 140px;
    padding-right: 10px;
    @media (max-width: 435px) {
      min-height: 140px;
    }
    .label {
      .label();
      color: var(--nepal-blue);
      font-size: 10px;
    }
  }
  .projectLeadsCard {
    .card();
    min-height: 100px;
    @media (max-width: 435px) {
      min-height: 140px;
    }
  }
  .projectEventsCard {
    .projectLeadsCard();
    min-height: 90px;
  }
}

.flexCentered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.counts {
  .flexColumn();
  flex-direction: column;
}

.count {
  margin: 2px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: var(--white);
  text-align: center;
  font-size: 11px;
  font-weight: 600;
}

.tableBadgeUnread {
  .count();
  background-color: var(--table-badge-unread);
}

.tableBadgeAssigned {
  .count();
  background-color: var(--pumpkin);
}

.yellow {
  .count();
  background-color: var(--pumpkin);
}

.primary {
  .count();
  background-color: var(--primary-color);
}

.tableBadgeTotal {
  .count();
  background-color: var(--table-badge-total);
}

.tableBadgeTagged {
  .count();
  background-color: var(--nepal-blue);
}

.peopleBadge {
  .count();
  display: inline-block;
  background-color: var(--table-badge-people);
  color: var(--font-color-eastern-blue);
  font-size: 9px;
  font-weight: 600;
  width: 16px;
  line-height: 16px;
  margin-right: 8px;
  text-transform: capitalize;
}

.teamBadge {
  .count();
  display: inline-block;
  background-color: var(--table-badge-team);
  color: var(--white);
  font-size: 9px;
  font-weight: 600;
  width: 16px;
  line-height: 16px;
  margin-right: 8px;
}

.tabletCounts {
  .counts();
  height: auto;
  margin-top: 4px;
  flex-direction: row;
  justify-content: center;
}

.repliesDisplay {
  display: flex;
  flex-direction: column;

  .text {
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .bold {
    .text();
    font-weight: 600;
    @media (max-width: 1080px) {
      font-size: 10px;
      line-height: 17px;
    }
  }

  .date {
    .text();
    @media (max-width: 1080px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}

.noReplies {
  width: 14px;
  height: 1px;
  background-color: var(--table-grey);
  margin: 10px 0px;
}

.rightJustifiedRepliesDisplay {
  .repliesDisplay();
  align-items: flex-end;
}

.metricsTableViewContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .categoryRow {
    height: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .category {
    color: var(--dark-blue);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 9px;
  }

  .categoryCol {
    height: 100%;
    &:nth-child(2) {
      padding-left: 8px;
    }
    &:nth-child(3) {
      padding-left: 16px;
    }
  }

  .metricsCol {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .metricRow {
    border-radius: 4px;
    max-width: 160px;
  }

  .metric {
    display: flex;
    justify-content: flex-start;
    line-height: 17px;
    color: var(--table-grey);
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }

  .activeMetric {
    &:hover {
      color: var(--nepal-blue);
      cursor: pointer;
    }
  }

  .value {
    .metric();
    justify-content: flex-end;
    align-items: center;
  }
}

.licenseTableContainer {
  max-width: 900px;

  .table {
    margin-top: 0;
  }

  .column {
    height: 100%;
    padding: 2px 16px !important;
  }

  .row {
    cursor: pointer;
  }

  .rowWhite {
    background-color: var(--white);
  }

  .centeredColumn {
    .column();
    span {
      justify-content: center;
    }
  }

  .subColumn {
    .centeredColumn();
    padding: 4px 16px;
  }

  .columnHeader {
    text-transform: uppercase;
    color: var(--nepal-blue);
    font-size: 10px;
    font-weight: 600;
    width: 100%;
  }

  .centeredColumnHeader {
    .columnHeader();
    text-align: center;
  }

  .cell {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    text-transform: capitalize;
    .ellipsis();
  }

  .centeredCell {
    .cell();
    align-items: center;
  }

  .avatar {
    width: 75px;
    height: 75px;
    border: 1px solid var(--slate-grey-a65);
    border-radius: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .nameContainer {
    padding-left: 8px;
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .roleContainer {
    font-size: 12px;
    font-weight: 600;
  }

  .name {
    font-size: 12px;
    .ellipsis();
  }

  .small {
    font-size: 12px;
    .ellipsis();
  }

  .bold {
    .name();
    font-weight: 600;
  }

  .email {
    .small();
    color: var(--primary-color);
  }

  .input {
    .email();
  }

  .datePicker {
    width: 100%;
    background-color: var(--lightest-grey);
    input {
      .input();
    }
  }

  .formItem {
    outline: none;
    margin: 0;
  }

  .icons {
    .cell();
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 4px;

    .icon {
      color: var(--primary-color);
      font-size: 16px;

      &:hover {
        transform: scale(1.2, 1.2);
        transition: all 0.15s linear;
      }
      &:nth-child(2) {
        margin-left: 5px;
      }
    }
  }
}

.addServiceAreaButtonContainer {
  width: 200px;
  margin-bottom: 12px;
}

.licenseCardsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .card {
    width: 100%;
    background-color: var(--white);
    padding: 16px;
    border-radius: 5px;
    margin-bottom: 10px;

    .inputRows {
      display: flex;
      flex-direction: column;
      .input {
        background-color: var(--lightest-grey);
      }
    }
  }

  .label {
    .label();
    font-size: 12px;
    margin-bottom: -4px;
  }
}

.categoryIconSuccess {
  color: var(--kelly-green);
  font-size: 24px;
}
.categoryIconInfo {
  color: var(--dodger-blue);
  font-size: 24px;
}
.categoryIconWarning {
  color: var(--sultan-gold);
  font-size: 24px;
}
.categoryIconError {
  color: var(--sunset-orange);
  font-size: 24px;
}

.idBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .id {
    font-size: 12px;
    line-height: 12px;
    color: var(--table-grey);
  }
}

.checkContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .greenCheck {
    background-image: url("/icons/icon_check_green.svg");
    background-color: var(--harlequin-green);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .reasonText {
    max-width: 85px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--font-color-eastern-blue);
  }
}

.popoverText {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--white);
  margin-top: 5px;
}

.iconContainer {
  width: 100%;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  .categoryIcon {
    color: var(--primary-color);
  }
}

.taggedIconContainer {
  .center();
  background-color: var(--nepal-blue);
  height: 20px;
  width: 20px;
  border-radius: 50%;

  .innerIcon {
    color: var(--white);
    .center();
    height: 12px;
    width: 12px;
  }
}
.expandableTable {
  :global(.ant-table-cell) {
    padding: 4px;
  }

  :global(.ant-table-row-expand-icon-cell) {
    width: 25px;
  }

  .lightRow {
    background-color: var(--white);
  }
  .darkRow {
    :global(.ant-table-cell) {
      background-color: var(--lightest-grey);
    }
  }

  .icon {
    color: var(--primary-color);
    font-size: 21px;

    svg {
      transition: transform 0.15s ease-in-out;
    }
  }

  .nestedTableContainer {
    background-color: var(--lightest-grey);

    .nestedTable {
      margin-left: 0;
      padding-left: 4px;
      :global(div.ant-spin-nested-loading > div.ant-spin-container > div.ant-table) {
        margin: 0;
      }
      :global(.ant-table-cell) {
        background-color: var(--lightest-grey);
        div {
          background-color: var(--lightest-grey);
        }
      }
      :global(.ant-table-row) {
        border: none;
      }

      .labelCol {
        font-weight: 600;
        color: var(--slate-grey);
        font-size: 9px;
        line-height: 12px;
        text-transform: uppercase;
      }
      .valueCol {
        display: flex;
        justify-content: flex-end;
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;