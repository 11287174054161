@import (reference) "styles/theme.less";

.changeEmailSectionContainer {
  background-color: var(--white);
  padding: 47px 0px;
  margin-top: 10px;
  border-radius: 4px;
  width: 100%;

  .label {
    font-size: 10px;
    text-transform: uppercase;
    color: var(--dark-blue);
  }

  .emailLabel {
    .label();
    margin: 14px 0 8px 0;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--nepal-blue);
  }

  .formItem {
    width: 100%;
    padding-bottom: 26px;
  }

  .formItemLessPadding {
    .formItem();
    padding-bottom: 6px;
  }

  .input {
    width: 100%;
    font-size: 14px;
    .blue();
    border-radius: 4px;
  }

  .emailInput {
    .input();
    color: var(--dark-blue);
  }

  .disabled {
    .input();
    cursor: not-allowed;
    margin-bottom: 6px;
  }

  .emailInputDisabled {
    .disabled();
    color: var(--dark-blue);
  }

  .emailInputProcessing {
    .emailInputDisabled();
    color: var(--nepal-blue);
  }

  .dropdown {
    .input();

    div {
      border-radius: 4px;
      .blue();
    }
  }

  :global(.ant-picker) {
    border-radius: 4px;
  }

  .datePicker {
    width: 100%;
    border-radius: 4px;

    input {
      .input();
    }
  }

  .textArea {
    .input();
    margin-bottom: 0;
  }

  .urlDisplay {
    position: absolute;
    bottom: 8px;
    left: 18px;
    font-size: 10px;
    color: var(--slate-grey);
    transition: opacity 0.3s linear;
    max-width: 100%;
    .ellipsis();

    a {
      color: var(--dark-blue);
      font-size: 10px;
    }
  }

  .buttonRow {
    display: flex;
    align-items: center;
    margin-top: 11px;

    .updateEmailBtn {
      background-color: var(--primary-color);
      color: var(--white);
      border: none;
      height: 32px;
      font-size: 16px;
      margin: 0 auto;
      margin-top: 14px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 36px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      padding-bottom: 6px;

      &:hover {
        background-color: var(--primary-color-highlighted);
      }

      @media (max-width: 572px) {
        margin-bottom: 8px;
      }
    }

    .verificationText {
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0em;
      color: var(--dark-blue);
      margin-top: -6px;
    }

    .inProgressButtons {
      display: flex;
      width: 100%;

      .disabledUpdateEmailBtn {
        .updateEmailBtn();
        background-color: var(--white);
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        margin-right: 12px;
        margin-bottom: 10px;
      }

      .cancelEmailBtn {
        .disabledUpdateEmailBtn();
        color: var(--scarlet);
        border: 1px solid var(--scarlet);
        margin-right: 6px;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;