@import (reference) "styles/theme.less";

.dropzone {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 280px;
  transition: all 0.3s ease-in-out;
  position: relative;
  border: 2px dashed var(--botticelli-blue);
  background-color: var(--white);
  border-radius: 4px;
  padding: 20px;
}

.inputLabel {
  font-size: 14px;
  color: var(--primary-color);
}

.input {
  display: none;
}

.inputLabel {
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  cursor: pointer;

  .inputLabelContents {
    position: relative;
    display: flex;
    flex-direction: column;
    .center();
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 12px;

    .row {
      width: 100%;
      margin-bottom: 8px;
      .center();
    }

    .iconRow {
      .row();
      height: 36px;

      .icon {
        height: 32px;
        width: 38px;
        background-image: url("/icons/icon_file_upload.svg");
        background-size: cover;
      }
    }

    .instruction {
      line-height: 15px;
    }

    .or {
      .instruction();
      color: var(--slate-grey);
    }

    .click {
      .instruction();
      border: 1px solid var(--primary-color);
      border-radius: 30px;
      height: 31px;
      width: 100px;
      .center();
    }

    .details {
      position: absolute;
      width: 100%;
      font-size: 10px;
      color: darken(rgba(38, 165, 210, 0.5), 20%);
      bottom: 0;
      text-align: center;
    }
  }
}

.inputLabelWithFiles {
  .inputLabel();
}

.submitButtonContainer {
  margin-top: 18px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  height: 32px;
  border-radius: 20px;
  background-color: var(--white);
  color: var(--primary-color);
  border: none;
}

.spinContainer {
  .inputLabel();
  background-color: var(--primary-color-a30);
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewContainer {
  width: 100%;
  overflow-x: hidden;
}

.previewGroup {
  width: 100%;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.previewRow {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  &:first-child {
    padding-top: 10px;
    height: 60px;
  }

  .thumb {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img {
      max-width: 100%;
      max-height: 100%;
      .buttonShadow();
    }

    .icon {
      font-size: 36px;
    }
  }

  .progressCol {
    width: 60%;
    height: 100%;
    position: relative;

    .progress {
      position: absolute;
      left: 0;
      top: 50%;
      bottom: 50%;
      margin: auto 0;
      width: 100%;
      height: 21px;
    }

    .filenameInputContainer {
      .progress();
      height: 100%;
      display: flex;
      align-items: flex-end;

      .filenameInputLabel {
        position: absolute;
        top: 5px;
        font-size: 10px;
        color: var(--slate-grey);
      }

      .filenameInput {
        width: 100%;
        height: 28px;
        border: none;
        color: var(--primary-color);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .icons {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;