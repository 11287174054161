@import (reference) "styles/theme.less";
@import "~animate.less/animate.less";

.siteInfoTab {
  margin-top: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .installerName {
    background: var(--white);
    border: 1px solid var(--botticelli-blue);
    width: 100%;
    height: 32px;
    padding: 5px 5px 5px 11px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;
    width: 100%;
    margin-bottom: 55px;
  }

  :global(.ant-checkbox-inner) {
    border-color: rgba(141, 177, 191, 1);
  }
  :global(.ant-checkbox-group) {
    display: flex;
    flex-direction: column;
  }

  .editLeadForm {
    width: 100%;
    display: flex;
    justify-content: center;

    .roofCheckCheckbox {
      margin-bottom: 0px;
      margin-left: 5px;

      .label {
        text-transform: uppercase;
        color: var(--nepal-blue);
        font-size: 10px;
        font-weight: 700;
        line-height: 9px;
      }
    }
  }

  .siteInfoContentRow {
    width: 100%;
  }

  .formContainer {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding-bottom: 120px;
    max-width: 860px;
    padding-top: 24px;

    .formColumn {
      height: 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  .uploaderItem {
    margin-bottom: 0;

    .label {
      text-transform: uppercase;
      color: var(--nepal-blue);
      font-size: 10px;
      font-weight: 600;
      line-height: 9px;
    }
  }

  .formSectionContainer {
    margin-top: 15px;
    margin-bottom: 25px;

    .cardContainer {
      width: 100%;
      padding: 33px 0px 0px 4px;
      margin-bottom: 20px;
      border-radius: 4px;
      background-color: var(--white) !important;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);

      .textarea {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        border-radius: 3px;
      }
    }
  }

  .error {
    padding-left: 4px;
    margin-top: -8px;
    font-size: 10px;
    color: var(--congo-pink);
  }

  .utilityBillDisplay {
    display: flex;
    flex-direction: column;

    .label {
      text-transform: uppercase;
      color: var(--nepal-blue);
      font-size: 10px;
      font-weight: 600;
      line-height: 9px;
    }

    .utilityBill {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 8px;

      .animatedButton {
        .animated();
        .tada();
      }

      .copyButton {
        background: var(--font-color-eastern-blue);
        border-radius: 0px 6px 6px 0px;
        font-weight: 500;
        font-size: 13px;
      }

      .utilityBillFilename {
        .ellipsis();
        display: flex;
        align-items: center;
        height: 32px;
        border: 1px solid var(--botticelli-blue);
        padding: 0px 7px 0px 7px;
        // margin-left: 4px;
        text-transform: none;
        color: var(--primary-color);
        font-size: 10px;
        font-weight: 600;
        line-height: 9px;

        .billLink {
          display: block;
          height: 100%;
          padding-top: 10px;
          line-height: 9px;
          color: var(--dark-blue);
          font-size: 14px;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .textArea {
    margin: 0 21px;
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 500;
    width: calc(100% - 51px);
  }

  .saveButton {
    margin-right: 31px;
  }

  .downloadButton {
    background-color: var(--font-color-eastern-blue);
    border-radius: 6px;
    border: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
  }

  .footer {
    background-color: var(--primary-color);
    padding: 12px 25px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 668px;
    @media (max-width: 575px) {
      height: auto;
      padding: 5px 10px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: flex-end;
    }

    .buttonContainer {
      margin-right: 10px;
      @media (max-width: 575px) {
        margin-right: 0;
      }
      .closeProjectButton {
        text-transform: uppercase;
        border-color: var(--white);
        background-color: rgba(255, 77, 80, 0.8);

        &:hover {
          background-color: rgba(255, 120, 117, 0.8);
        }
        &:active {
          background-color: rgba(217, 54, 62, 0.8);
        }
      }
    }

    .closedFooter {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .closeNotes {
        height: 50px;
        width: 100%;
        max-width: calc(100% - 140px); // reduce width to keep overflowed text from bullying reopen button aside
        display: flex;
        flex-direction: column;
        justify-content: center;

        .item {
          width: 100%;
          .ellipsis();
          color: var(--white);
          font-size: 12px;
          line-height: 15px;
        }
      }

      .reopenButton {
        .submitButton();
        border: 1px solid white;
      }
    }

    .requestProposal {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 575px) {
        height: auto;
      }

      .text {
        font-size: 14px;
        color: var(--white);
      }

      .proposalDetail {
        padding: 0 12px;
        min-width: 200px;
        @media (max-width: 575px) {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 400px) {
          max-width: unset;
        }
      }
    }
  }
}

.closeProjectModal {
  width: 100%;

  .itemContainer {
    position: relative;
    margin-bottom: 30px;

    .selectContainer {
      width: 100%;
      margin-top: 4px;
      display: flex;
      justify-content: center;

      .select {
        width: 100%;
        font-size: 12px;
        color: var(--primary-color);
      }
      .textArea {
        font-size: 12px;
        color: var(--primary-color);
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;