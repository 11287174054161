@import (reference) "styles/theme.less";

.modal {
  .icon {
    font-size: 16px;
    color: var(--primary-color);
  }

  :global(.ant-modal-header) {
    border-radius: 12px 12px 0 0;
    background-color: var(--athens-gray);
    border: none;
    padding: 10px 24px;
  }

  :global(.ant-modal-close) {
    height: 43px;
    display: flex;
    align-items: center;

    :global(.anticon-close) {
      color: var(--dark-blue);
    }
  }

  :global(.ant-modal-content) {
    border-radius: 12px;
  }

  :global(.ant-modal-footer) {
    display: flex;
    align-items: center;
    border-radius: 0 0 12px 12px;
    background-color: var(--athens-gray);
    padding: 18px 28px;
  }

  .header {
    display: flex;
    align-items: center;
    div {
      align-items: center;
    }

    .headerIcon {
      margin-right: 6px;
    }

    .heading {
      color: var(--dark-blue);
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }
  }

  .headerCentered {
    .header();
    .center();
  }

  .footerContainer {
    width: 100%;
    .footerText {
      color: var(--darker-blue);
      margin: 0px 0px 28px 0px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
    .footerSubText {
      color: var(--grey-blue);
      margin: 14px 0px 28px 0px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .button {
      height: 32px;
      min-width: 78px;
      border-radius: 6px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      .text {
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
      }
    }

    :global(.ant-btn-primary) {
      box-shadow: none !important;
    }

    .submit {
      .button();
      min-width: 163px;
      background-color: var(--font-color-eastern-blue);
      box-shadow: none;
      border: none;
      .text {
        color: var(--white);
      }
      &:hover {
        background: var(--font-color-eastern-blue-highlighted);
      }
      &:global(.ant-btn-primary[disabled]) {
        span {
          color: var(--white);
        }
      }
      :disabled,
      &[disabled] {
        background: var(--botticelli-blue) !important;
        background-color: var(--botticelli-blue) !important;
        color: var(--white) !important;
      }
    }

    .gradientSubmit {
      min-width: 163px;
    }

    .customCancel {
      .button();
      background-color: var(--white);
      border-color: var(--primary-color);
      color: var(--primary-color);
      .text {
        color: var(--primary-color);
      }
    }

    .defaultCancel {
      border: none;
      display: flex;
      .center();
      margin-right: -8px;
    }

    .gradientCancelButton {
      .button();
    }
  }
  .centeredFooter {
    .footer();
    justify-content: center;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;