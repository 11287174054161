@import (reference) "styles/theme.less";

.tab {
  position: relative;
  padding: 48px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .loading {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--primary-color-a30);
    .center();
    z-index: 2;
  }

  .banner {
    height: 32px;
    font-size: 24px;
    line-height: 24px;
    color: var(--primary-color);

    .stripe {
      display: inline-block;
      width: 69px;
      height: 100%;
      margin-left: 2px;
      margin-right: 11px;

      img {
        height: 120%;
        width: 120%;
        transform: translate(-2px, -2px);

        filter: invert(47%) sepia(99%) saturate(380%) hue-rotate(150deg) brightness(97%) contrast(91%);
      }
    }
  }

  .instructions {
    margin: 12px 0 24px 0;
    font-size: 12px;
    color: var(--slate-grey-a65);
    text-align: center;
  }

  .form {
    width: 80%;
    min-width: 275px;
  }

  .back {
    margin-top: 24px;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;

    &:hover {
      color: var(--primary-color-highlighted);
      transition: color 0.2s ease-in-out;
    }
  }
}

.formItem {
  width: 100%;
  margin-top: 16px;

  .input {
    width: 100%;
    font-size: 14px;
    height: 50px;

    input {
      font-size: 14px;
    }
  }

  .checkbox {
    font-size: 12px;
    .center();
  }

  .submitButton {
    height: 50px;
    font-size: 16px;
    .bold {
      margin-left: 4px;
      font-weight: 600;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;