/*
  use this for any global overrides to apply to all ant design components
*/

/* html {
  overflow: hidden;
} */

.ant-table-thead > tr > th {
  background-color: var(--white);
}

.ant-modal-mask {
  background-color: rgba(38, 165, 210, 0.3);
}

.ant-modal-header {
  background-color: #f9f9f9;
}

.ant-modal-footer {
  background-color: rgb(78, 79, 80);
}

/* TODO: Deprecate all ant-legacy items */
.ant-legacy-form-vertical .ant-legacy-form-item-label {
  padding-bottom: 4px;
}

.ant-form-item {
  margin-bottom: auto;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 4px;
}

.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}

.ant-btn > .anticon {
  line-height: 0px;
}

.ant-tabs-tabpane {
  z-index: 1;
}

.remove-table-footer-padding
  > div.ant-spin-nested-loading
  > div.ant-spin-container
  > div.ant-table
  > div.ant-table-footer {
  padding: 0;
}

/* Global override for ant spin colors */
.ant-spin-dot-item:first-of-type {
  background: var(--blue-ribbon);
}

.ant-spin-dot-item:nth-of-type(2) {
  background: var(--sweet-mint);
}

.ant-spin-dot-item:nth-of-type(3) {
  background: var(--picton-blue);
}

.ant-spin-dot-item:nth-of-type(4) {
  background: var(--goldenrod);
}

.ant-table-row {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03) !important;
}

/* set style rules for deeply nested DOM elements within ant design's Table component */

.scrolling-table-header-row
  > div.ant-spin-nested-loading
  > div.ant-spin-container
  > div.ant-table-fixed-header
  > div.ant-table-content
  > div.ant-table-scroll
  > div.ant-table-header
  > table
  > thead
  th,
.fixed-table-header-row
  > div.ant-spin-nested-loading
  > div.ant-spin-container
  > div.ant-table
  > div.ant-table-content
  > div.ant-table-body
  > table
  > thead
  th {
  /* make header row shorter */
  padding: 8px 16px;
}

.scrolling-table-header-row
  > div.ant-spin-nested-loading
  > div.ant-spin-container
  > div.ant-table-fixed-header
  > div.ant-table-content
  > div.ant-table-scroll
  > div.ant-table-header
  > table
  > thead
  > tr
  > th
  > span.ant-table-header-column,
.fixed-table-header-row
  > div.ant-spin-nested-loading
  > div.ant-spin-container
  > div.ant-table
  > div.ant-table-content
  > div.ant-table-body
  > table
  > thead
  > tr
  > th
  > span.ant-table-header-column {
  /* center-align text vertically within header row */
  display: flex;
}

/* shrink padding and center align text in header row for tighter grouping of column labels */
.scrolling-table-multiline-header-row
  > div.ant-spin-nested-loading
  > div.ant-spin-container
  > div.ant-table-fixed-header
  > div.ant-table-content
  > div.ant-table-scroll
  > div.ant-table-header
  > table
  > thead
  th {
  padding: 4px 16px;
}

.scrolling-table-multiline-header-row
  > div.ant-spin-nested-loading
  > div.ant-spin-container
  > div.ant-table-fixed-header
  > div.ant-table-content
  > div.ant-table-scroll
  > div.ant-table-header
  > table
  > thead
  > tr
  > th
  > span.ant-table-header-column {
  display: flex;
  max-width: 100%;
  align-items: center;
}

/* hide ant design's form error messages in favor of custom messages */
.hide-form-element-error-message
  > div.ant-legacy-form-item-control-wrapper
  > div.ant-legacy-form-item-control
  > div.ant-legacy-form-explain {
  display: none;
}

/* hide annoying scrollbars when popup is open and annoying floating box when popup is hidden, keep popup from being really huge and ugly */
.ant-menu-submenu-hidden {
  overflow: hidden;
}

.ant-menu-submenu-popup {
  overflow: auto;
}
.ant-menu-submenu-popup {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.ant-menu-vertical.ant-menu-sub {
  max-height: 400px;
  border-radius: 8px;
}

/* ant design defaults to overflow-x: hidden, want to hide overflow in both directions */
.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow: hidden;
}

/* remove bottom margin on ant design paragraph components */
div.ant-typography {
  margin-bottom: 0;
}

/* keep ant design form error messages but decrease font size */
/* .ensmallen-form-error-message
  > .ant-form-item-control-wrapper
  > .has-error
  > .ant-form-explain, */
.ensmallen-form-error-message > div > div > div {
  font-size: 10px;
}

/* override styles for sidebar autocomplete fields */
.sidebar-autocomplete-override
  > div.ant-select-selector
  > span.ant-select-selection-search
  > span.ant-select-selection-search-input {
  background-color: transparent;
  box-shadow: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #295ca0;
  padding: 0;
}

.sidebar-autocomplete-override
  > div.ant-select-selector
  > span.ant-select-selection-search
  > span.ant-select-selection-search-input
  > input {
  background-color: transparent;
  color: #29aae3;
}

.sidebar-autocomplete-override
  > div.ant-select-selector
  > span.ant-select-selection-search
  > span.ant-select-selection-search-input
  > span.ant-input-suffix {
  font-size: 10px;
  color: #295ca0;
}

:focus {
  outline: none;
}

.ant-select-dropdown {
  border-radius: 4px;
  z-index: 2000;
}

.ant-message {
  z-index: 3000;
}

.ant-popconfirm {
  z-index: 3000;
}

/* Blue line underneath subnav tabs */
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 3px;
}

/* Distance between tabs in subnav */
.ant-tabs-tab:not(:first-of-type) {
  margin: 0 0 0 40px !important;
}
