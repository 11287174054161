@import (reference) "styles/theme.less";

.badgeAvatarContainer {
  position: relative;
  .center();

  .badgeContainer {
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 122;
  }
}

.badgeAvatarTooltip {
  :global(.ant-tooltip-inner) {
    background: var(--dark-blue);
    border-radius: 8px;
    min-width: 340px;
    background-image: url("/img/conference_tooltip_bg.png"); // TEMP
    background-size: cover; // TEMP
  }
  :global(.ant-tooltip-arrow-content) {
    --antd-arrow-background-color: rgb(15, 80, 162) !important;
  }
}

.hidden {
  .badgeAvatarTooltip();
  display: none;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;