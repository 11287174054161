@import (reference) "styles/theme.less";

.tabsContainer > div {
  height: 100%;
}

.tab {
  padding: 48px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .banner {
    height: 32px;
    font-size: 24px;
    line-height: 24px;
    color: var(--primary-color);
  }

  .stripeButton {
    .roundButton();
    text-transform: none;
    font-size: 16px;
    padding: 0 30px;
    height: 48px;
    margin: 50px 0;
  }

  .stripe {
    width: 100px;
    margin-bottom: 30px;

    .text {
      color: var(--dark-blue);
      width: 100%;
      text-align: center;
      font-size: 12px;
      margin-bottom: -5px;
    }

    img {
      height: 100%;
      width: 100%;
      transform: translate(-2px, -2px);
    }
  }

  .instructions {
    margin: 8px 0 12px 0;
    font-size: 13px;
    color: var(--slate-grey-a65);
    text-align: center;
  }

  .prompt {
    .instructions();
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 20px;
    margin-top: -10px;
    color: var(--sahara-gold);
  }

  .form {
    width: 80%;
    min-width: 275px;
    .label {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      line-height: 9px;
      letter-spacing: 0em;
      color: var(--hit-gray);
    }
    .formItem {
      width: 100%;
      margin-top: 8px;

      .input {
        width: 100%;
        font-size: 14px;
        height: 50px;
        border-radius: 4px;
        color: var(--primary-color);
      }
      .autoCompleteInput > div {
        .input();
        display: flex;
        align-items: center;
      }
      .autoCompleteInput > div > span > input {
        height: 50px !important;
      }

      .submitButton {
        height: 50px;
        font-size: 16px;
        .bold {
          margin-left: 4px;
          font-weight: 600;
        }

        &:hover {
          background-color: var(--primary-color-highlighted);
        }
      }

      .upgradeButton {
        height: 54px;
        width: 336px;
        border-radius: 36px;
        background-color: var(--white);
        color: var(--blue-ribbon);
        border: 1px solid var(--blue-ribbon);
        margin: 45px auto 25px auto;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0em;

        &:hover {
          background: var(--athens-gray);
        }
      }
    }

    .formItemCentered {
      .formItem();
      display: flex;
      justify-content: center;
    }
    .formItemNoMargin {
      .formItem();
      margin-top: 0;
    }
  }

  .back {
    margin-top: 12px;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    line-height: 19px;

    &:hover {
      color: var(--primary-color-highlighted);
      transition: color 0.2s ease-in-out;
    }
  }
}

.referralTab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
  min-height: 500px;
  .header {
    padding: 24px 0;
    height: 175px;
    .center();
    flex-direction: column;
    position: relative;

    .title {
      color: var(--primary-color-highlighted);
      font-size: 24px;
    }

    .scoreboard {
      width: 100%;
      margin-top: 4px;
      padding: 0 20%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media (max-width: 576px) {
        padding: 0 15%;
      }
      @media (max-width: 425px) {
        padding: 0 16px;
      }

      .score {
        .center();
        flex-direction: column;
        text-transform: uppercase;

        .total {
          font-size: 36px;
          font-weight: 600;

          @media (max-width: 375px) {
            font-size: 32px;
          }
        }
        .payout {
          font-size: 12px;
          color: var(--slate-grey-a65);
        }
      }
    }

    .addReferralButton {
      position: absolute;
      bottom: -24px;
      right: 24px;
      height: 50px;
      width: 50px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        font-size: 24px;
      }
    }
  }

  .referrals {
    .bgGradientToWhite();
    position: relative;
    padding-top: 32px;
    height: calc(100% - 175px);
    overflow-y: auto;

    .fetching {
      position: absolute;
      width: 100%;
      height: calc(100% + 32px);
      background-color: var(--primary-color-a30);
      filter: brightness(130%);
      margin-top: -32px;

      .spin {
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }

    .referralStatus {
      width: 100%;
      padding: 8px 16px;
      display: flex;
      flex-direction: column;

      .name {
        font-size: 14px;
        font-weight: 600;
        padding-left: 8px;
        margin-bottom: 4px;
      }
      .updatedAt {
        width: 100%;
        font-size: 11px;
        text-align: end;
        color: var(--slate-grey-a65);
        margin-top: 4px;
        padding-right: 4px;
      }
      .complete {
        color: desaturate(gold, 20%);
        font-weight: 600;
      }

      .statusBar {
        width: 100%;
        border-radius: 25px;
        height: 40px;
        overflow: hidden;
        display: flex;

        .item {
          .center();
          box-sizing: border-box;
          width: calc(20% + 1px);
          height: 100%;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 9px;

          transform: skewX(-30deg);
          .display {
            transform: skewX(30deg);
          }

          @media (max-width: 376px) {
            font-size: 8px;
          }

          &:first-child {
            width: calc(20% + 4px);
            margin-left: -4px;
          }
          &:last-child {
            width: calc(20% + 4px);
            margin-right: -4px;
          }
          &:not(:first-child) {
            // border-left: 1px solid var(--slate-grey-a65);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            box-shadow: inset 12px 0 21px -8px var(--slate-grey-a65);
          }
        }

        .done {
          .item();
          background-color: var(--primary-color-highlighted);
        }
        .notDone {
          .item();
          color: var(--white);
          background-color: var(--old-grey-highlighted);
        }
      }
    }

    .noReferrals {
      margin: 32px 15%;
      text-align: center;
      font-size: 16px;
      color: var(--slate-grey-a65);

      .exampleButton {
        background-color: var(--primary-color);
        font-size: 12px;
        line-height: 12px;
        color: var(--white);
      }

      .shareAffiliateLink {
        margin-top: 25px;

        .socialContainer {
          margin-top: 16px;
        }
      }

      .referralLinkContainer {
        margin-top: 8px;
        width: 100%;
        text-align: left;
        .center();
        flex-direction: column;

        .linkButton {
          font-size: 12px;
          .ellipsis();
          &::after {
            content: "";
            display: inline-block;
            height: 12px;
            width: 12px;
            background-image: url("/icons/icon_copy_text.svg");
            margin-left: 6px;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }

      .redirectSection {
        margin-top: 25px;

        .linkToSellerLeads {
          color: var(--font-color-eastern-blue);
          cursor: pointer;
        }
      }
    }
  }

  .profileLinksContainer {
    position: absolute;
    right: 14px;
    top: 12px;
    z-index: 1;
    display: flex;

    @media (max-width: 475px) {
      flex-direction: column;
    }

    .link {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        background-position: center;
        background-size: contain;
        width: 24px;
        height: 24px;
      }

      .label {
        font-size: 8px;
        line-height: 11px;
        color: var(--primary-color);
        text-transform: uppercase;
      }
    }

    .consultantLink {
      .link();
      margin-right: 6px;

      @media (max-width: 475px) {
        margin-right: 0;
        margin-bottom: 4px;
      }

      .icon {
        background-image: url("/icons/icon_consultant.svg");
      }
    }

    .profileLink {
      .link();

      .icon {
        background-image: url("/icons/icon_ambassador.svg");
      }
    }
  }
}

.addReferralTab {
  .tab();
  padding-top: 24px;

  .banner {
    width: 70%;
    text-align: center;
    line-height: 32px;
    height: 100%;
  }

  .uploadButton {
    height: 100%;
    width: 100%;
    .center();
    flex-direction: column;
    color: darken(rgb(217, 217, 217), 10%);
    font-size: 14px;
    text-transform: uppercase;

    .uploadIcon {
      font-size: 24px;
    }
  }

  .fileUrl {
    color: var(--light-grey);
    font-size: 8px;
    .ellipsis();
    margin-bottom: 6px;
    margin-top: 4px;
    cursor: pointer;
  }
}

.payoutsTab {
  .tab();

  .table {
    width: 100%;
    margin: 24px 0;

    .col {
      padding: 8px 16px;
    }
    .labelCol {
      .col();
      font-size: 10px;
      text-transform: uppercase;
    }
    .valueCol {
      .col();
      font-size: 12px;
    }

    .valueCell {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .ellipsis();
    }

    .stripeCell {
      .valueCell();
      cursor: pointer;

      img {
        height: calc(75px / 2.15);
        width: 75px;

        // black => #29AAE3
        filter: invert(47%) sepia(99%) saturate(380%) hue-rotate(150deg) brightness(97%) contrast(91%);
      }

      .icon {
        font-size: 16px;
        color: var(--primary-color);
        margin-left: 4px;
      }
    }
  }

  .stripe {
    font-size: 16px;

    .imgContainer {
      display: inline-block;
      img {
        height: calc(75px / 2.15);
        width: 75px;

        // black => #29AAE3
        filter: invert(47%) sepia(99%) saturate(380%) hue-rotate(150deg) brightness(97%) contrast(91%);
      }
    }
  }
}

.profileTab {
  .tab();
  padding: 30px;
  // padding-top: 40px;
  @media (max-width: 500px) {
    padding: 30px 20px;
  }
  .banner {
    line-height: 32px;
    margin-bottom: 18px;

    .title {
      font-family: Poppins;
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: var(--primary-color);

      .bold {
        margin-left: 4px;
        font-weight: 600;
      }
    }
  }

  .back {
    margin-bottom: 10px;
  }

  .profileUploadContainer {
    width: 100px;
    margin: auto;
    margin-top: 16px;

    .avatarContainer {
      display: flex;
      flex-direction: column;
      .label {
        text-align: center;
      }
      .avatar {
        margin: 8px 0 10px 0;
        border-radius: 50px;
      }
    }

    .uploadButton {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      background-color: var(--primary-color);
      color: var(--white);
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      &:hover {
        background-color: var(--primary-color-highlighted);
      }
    }
  }

  .form {
    .form();
    padding: 0px;
    padding-top: 0px;
    width: 100%;
    position: relative;

    .formItem {
      width: 100%;

      .input {
        width: 100%;
        height: 32px;
      }

      .passwordInput {
        .input();
        margin-top: 2px;
      }

      .select {
        .input();

        .selectLabel {
          line-height: 14px;
          text-transform: capitalize;
          color: var(--primary-color);
        }
      }
      .disabled {
        .input();
        cursor: not-allowed;
      }

      .dropdown {
        .input();
      }
      .submitButton {
        height: 32px;
        font-size: 16px;
        margin: 0 auto;
        margin-top: 18px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 36px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        padding-bottom: 6px;
      }
    }
  }

  .fetching {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(130%);
    z-index: 2;

    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}

.headerButtons {
  display: flex;
  color: var(--white-a65);
  height: 24px;

  .headerButton {
    height: 100%;
    background-color: transparent;
    padding: 0 12px;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-size: 12px;
    color: var(--white-a65);

    &:hover,
    &:active {
      border: none;
      outline: none;
      color: var(--white);
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .divider {
    .center();
    height: 24px;
    font-size: 12px;
  }
}

.prefooter {
  margin-top: 8px;
  width: 100%;
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  .linkRow {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px 8px 18px;
    .linkTitle {
      display: flex;
      align-items: center;
      .name {
        margin-left: 9px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: var(--font-color-eastern-blue);
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background: var(--table-edit-mode);
  }
}

.footer {
  width: 100%;
  margin-top: 8px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px 16px 8px 8px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .helpContainer {
    position: relative;
    padding-left: 76px;
    cursor: pointer;

    .avatar {
      position: absolute;
      top: -32px;
      left: 8px;
      width: 64px;
      height: 64px;
      background-image: url("/img/ambassador/avatar-blank.png");
      background-size: cover;
      background-position: top center;
      border-radius: 50%;
    }

    .openHelp {
      font-size: 14px;
      font-style: italic;
      color: var(--white);
    }
  }

  .linkDisplay {
    margin-left: 10px;
    text-align: right;
    @media (max-width: 500px) {
      width: 50vw;
    }
    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: var(--table-badge-total);
    }

    .url {
      font-size: 12px;
      line-height: 14px;
      color: var(--font-color-eastern-blue);
      cursor: pointer;
      &::after {
        content: "";
        vertical-align: middle;
        display: inline-block;
        height: 12px;
        width: 12px;
        background-image: url("/icons/icon_copy_text.svg");
        margin-left: 6px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

.helpModal {
  .modalBody {
    color: var(--white);
    background-color: var(--slate-grey);
    position: relative;
    padding: 24px;
    padding-top: 48px;

    .tabs {
      .tab {
        padding: 4px;
      }
    }

    .consultant {
      position: absolute;
      top: -24px;
      left: -5px;
      height: 50px;
      width: 100%;
      padding-left: 32px;
      padding-top: 6px;

      .info {
        font-size: 12px;
        line-height: 14px;
        color: var(--light-grey);

        .value {
          color: saturate(var(--primary-color-highlighted), 50%);
        }
      }
    }

    .avatar {
      position: absolute;
      top: -48px;
      left: -42px;
      width: 64px;
      height: 64px;
      background-image: url("/img/ambassador/avatar-blank.png");
      background-size: cover;
      background-position: top center;
      border-radius: 50%;
    }

    .header {
      color: saturate(var(--primary-color-highlighted), 50%);
      font-size: 18px;
    }

    .list {
      list-style: none;
      padding: 0;
      margin-top: 16px;

      .item {
        position: relative;
        margin-top: 18px;
        padding-left: 24px;
        line-height: 18px;
        color: var(--white);
        cursor: pointer;
      }
      .item::before {
        content: "•";
        position: absolute;
        left: 0;
        color: saturate(var(--primary-color-highlighted), 50%);
        margin-right: 24px;
        transform: scale(3);
      }
    }

    .answer {
      margin-top: 24px;
      color: var(--white);
      font-size: 16px;
      line-height: 24px;
    }

    .additional {
      margin-top: 48px;
      margin-left: 24px;
      color: var(--table-badge-unread);
      font-size: 14px;

      .ask {
        cursor: pointer;
        color: rgb(210, 174, 80);
      }
    }
  }
}
.helpModal > div {
  background-color: var(--slate-grey);
}

.socialIcons {
  height: 100%;
  display: flex;
  align-self: flex-end;
  padding: 5px 0 5px 8px;
  .iconContainer {
    height: 32px;
    width: 32px;
    .center();
  }

  .facebookContainer {
    .iconContainer();
    background-color: var(--chambray-blue);
  }

  .twitterContainer {
    .iconContainer();
    background-color: var(--rockman-blue);
  }

  .linkContainer {
    .iconContainer();
    background-color: var(--slate-grey);
    cursor: pointer;
  }

  .icon {
    font-size: 18px;
    color: var(--white);
  }

  .roundIcon {
    border-radius: 50%;
    margin: auto;
    margin-right: 3px;
  }
}

.socialIconsBig {
  .socialIcons();
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  .iconContainer {
    height: 56px;
    width: 56px;
  }
  .icon {
    font-size: 36px;
  }

  .facebookContainer {
    .iconContainer();
    background-color: var(--chambray-blue);
  }

  .twitterContainer {
    .iconContainer();
    background-color: var(--rockman-blue);
  }

  .linkContainer {
    .iconContainer();
    background-color: var(--slate-grey);
    cursor: pointer;
  }
}

.ambassadorUpgradeModalHeading {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: var(--darkest-blue);
}

.ambassadorUpgradeModalBody {
  color: var(--queen-blue);
  font-weight: 600;
  padding: 35px 40px;
  text-align: center;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;