@import (reference) "styles/theme.less";

.container {
  margin-top: 46px;
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  min-height: calc(100vh - 46px);
  height: calc(100vh - 46px);
  bottom: 0;

  .logoContainer {
    position: absolute;
    z-index: 1010;
    height: 46px;
    width: 48px;
    .center();
    top: -45px;
    left: 0;
    cursor: pointer;
  }

  .logoContainerOpen {
    .logoContainer();
    background-color: var(--dark-blue);
  }

  .section {
    margin-bottom: 36px;

    .sectionTitle {
      width: 50px;
      .center();

      span {
        display: block;
        font-size: 9px;
        font-weight: 800;
        line-height: 12px;
        letter-spacing: 0em;
        text-transform: uppercase;
        color: var(--primary-color);
        margin-bottom: 10px;
      }

      .entTitle {
        margin-left: 18px;
        white-space: nowrap;
      }

      .smallerTitle {
        font-size: 8px;
      }
    }

    .supportSectionTitle {
      .sectionTitle();
      margin-left: 4px;
    }

    .sectionRow {
      height: 36px;
      width: 184px;
      margin-left: 6px;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      border-radius: 4px;
      flex-wrap: nowrap;
      margin-bottom: 1px;
      cursor: pointer;
      transition: 0.2s all;
      margin-right: 10px;

      .iconCol {
        max-width: 48px;
        .center();
      }

      .textCol {
        width: calc(100% - 48px);
        display: flex;
        align-items: center;
        padding-bottom: 2px;
        white-space: nowrap;
      }

      &:hover {
        background: var(--dark-navy);
      }

      @media (max-width: 500px) {
        width: calc(100% - 20px);
      }
    }

    .sectionRow:hover > .iconCol {
      path {
        fill: white;
      }
      .brainLines {
        fill: var(--dark-navy);
      }
      .enterpriseIcon {
        path {
          fill: var(--dark-navy);
        }
      }
    }

    .gettingStartedRow {
      .sectionRow();
      background: linear-gradient(95.7deg, var(--canary-yellow) 12.17%, var(--lemon-chiffon) 93.24%);

      .linkText {
        color: var(--primary-color);
      }

      &:hover {
        background: linear-gradient(95.7deg, var(--canary-yellow) 12.17%, var(--canary-yellow) 93.24%);

        .linkText {
          color: var(--blue-ribbon);
        }
      }
    }

    .collapsedRow {
      .sectionRow();
      width: 36px;
      margin-right: 0px;

      @media (max-width: 500px) {
        width: 0;
      }

      &:hover {
        background: var(--dark-navy);
      }
    }

    .activeRow {
      transition: 0.2s all;
      background: var(--primary-color);

      &:hover {
        background: var(--primary-color);
      }
    }

    .linkText {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: var(--white);
      margin-left: 4px;
    }
  }

  .collapsedSection {
    .section();
    @media (max-width: 500px) {
      width: 0;
      overflow: hidden;
      .sectionTitle {
        width: 0;
      }
    }
  }

  .sectionBottom {
    .section();
    margin-bottom: 16px;
  }

  .collapsedSectionBottom {
    .collapsedSection();
    margin-bottom: 16px;
  }

  .supportSection {
    .section();
    margin-bottom: 0;
    padding-bottom: 12px;
    bottom: 0;
    background-color: var(--dark-blue);
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-left: 9px solid transparent;
    background-clip: padding-box;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    border: none;
  }
}

.containerExpanded {
  .container();
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: var(--primary-color) transparent;

  box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.25);
  @media (max-width: 500px) {
    width: 100%;
  }
}

.preSalesSupportLinkModal {
  background-color: var(--white);
  height: 500px;
  border-radius: 12px;

  :global(.ant-modal-header) {
    height: 58px;
    background: var(--alabaster-white);
    border-radius: 12px 12px 0px 0px;
    padding: 9px 24px;
    display: flex;
    justify-content: left;

    :global(.ant-modal-title) {
      color: var(--dark-blue);
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 700;
      line-height: 17px;
      padding: 10px;
      text-align: center;
    }
  }

  :global(.ant-modal-close-x) {
    line-height: 39px;
    font-weight: 800;
    color: var(--dark-blue);
    width: 46px;
    height: 60px;
    padding-right: 10px;
    padding-top: 5px;
  }

  :global(.ant-modal-content) {
    width: 735px;
    height: 500px;
    box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    box-sizing: border-box;

    :global(.ant-modal-body) {
      padding: 20px 38px;
    }
  }

  .textRow {
    margin: 18px 0 46px 0;
  }

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    width: 100%;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .salesSupportTitle {
    width: 100%;
    margin-bottom: 4px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--dark-blue);
    text-align: left;
    margin-left: 3px;
  }

  .salesSupportInfoTitle {
    width: 136px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--dark-blue);
    text-align: right;
  }
  .supportModalHoursInfo {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark-blue);
  }
  .supportModalHoursContent {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: black;
  }

  .spacer {
    height: 14px;
    width: 100%;
  }
  .spacerMedium {
    height: 7px;
    width: 100%;
  }

  .link {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: var(--primary-color);
    margin-bottom: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    span,
    a {
      margin-right: 9px;
    }
  }
  .salesSupportLink {
    padding-bottom: 22px;
    height: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: var(--white);
    background: var(--dark-blue);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    &:hover {
      background: var(--darkest-blue);
    }
  }

  .salesSupportEmailBtn {
    width: 170px;
  }
  .salesSupportEmailBtnSp {
    width: 170px;
  }
  .salesSupportHoursBtn {
    width: 115px;
  }
  .salesSupportCalendarBtn {
    width: 158px;
  }
  .salesSupportChatBtn {
    cursor: pointer;
    width: 195px;
    padding-left: 12px;
  }
  .modalTooltip {
    left: 10px;
    color: var(--dark-blue);
  }
  .btnData {
    cursor: pointer;
  }

  .bolderLink {
    .link();
    font-weight: 600;
  }

  .subText {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: var(--sock-cushion-grey);
  }

  .buttonRow {
    display: flex;
    justify-content: center;

    .closeButton {
      width: 120px;
      height: 32px;
      background: var(--white);
      border: 0.5px solid var(--primary-color);
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 36px;
    }
  }
}

.bubbleNotification {
  :global(.ant-badge-count) {
    background: linear-gradient(46.04deg, var(--canary-yellow) 0.7%, var(--waterspout-blue) 98.95%);
    color: var(--adriatic-sea);
    font-weight: 700;
    font-size: 9.5px;
    line-height: 10px;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    position: absolute;

    left: 319%;
    bottom: -4%;
    width: 16px;
    height: 16px;
    z-index: 1;
    padding: 3px;
  }
}
.collapsedBubbleNotification {
  :global(.ant-badge-count) {
    background: linear-gradient(46.04deg, var(--canary-yellow) 0.7%, var(--waterspout-blue) 98.95%);
    color: var(--adriatic-sea);
    font-weight: 700;
    font-size: 9.5px;
    line-height: 10px;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    position: absolute;
    bottom: 250%;
    left: 1750%;
    width: 22px;
    height: 22px;
    padding: 5px;
  }
}
.inboxMainNav {
  height: 1px;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;