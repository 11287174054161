@import (reference) "styles/theme.less";

.content {
  padding-top: 64px;
  background-color: rgb(243, 246, 248);

  .solarIntro {
    position: relative;
    width: 100%;
    height: 720px;
    display: flex;
    justify-content: center;

    .videoContainer {
      width: 100%;
      z-index: 2;
      overflow: hidden;

      .video {
        object-fit: cover;
        object-position: center top;
        position: relative;
        height: 100%;
        width: 100%;
      }
    }

    .backgroundImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 1;

      @media (max-width: 768px) {
        background-position-x: 30%;
      }

      @media (max-width: 475px) {
        background-position-x: 37%;
        background-position-y: 0%;
      }
    }

    .innerContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 3;
      position: absolute;
      width: calc(100% - 128px);
      max-width: 1280px;
      height: 100%;
      padding: 0 64px;

      @media (max-width: 768px) {
        width: 90%;
        padding: 0 16px;
      }
    }

    .bannerHeader {
      color: var(--white);
      font-size: 80px;
      font-weight: 800;
      text-align: center;
      margin: 12px;

      @media (max-width: 400px) {
        font-size: 40px;
        line-height: 40px;
        margin: 12px;
        br {
          display: none;
        }
      }
    }

    .subHeading {
      color: var(--white);
      font-size: 26px;
      line-height: 27px;
      font-weight: 300;
      text-align: center;

      @media (max-width: 400px) {
        font-size: 20px;
        line-height: 27px;

        br {
          display: none;
        }
      }
    }

    .bannerButton {
      height: 40px;
      border-radius: 96px;
      background-color: var(--white);
      color: var(--primary-lite);
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      border: none;
      padding: 0 30px 0 30px;
      margin-top: 35px;
      &:hover {
        background-color: var(--clear-sky-lite);
      }
    }
  }
  .joinSection {
    background: rgba(1, 35, 67, 1);
    min-height: 289px;

    .bannerButton {
      height: 40px;
      border-radius: 96px;
      background-color: var(--primary-lite);
      color: var(--clear-sky-lite);
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      border: none;
      padding: 0 30px 0 30px;
      margin-top: 35px;
      &:hover {
        background-color: var(--bondi-blue);
      }
    }

    .title {
      font-family: Poppins;
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-weight: 700;
      letter-spacing: 0em;
    }

    .subheading {
      margin: 15px 0 15px 0;
      font-size: 28px;
      line-height: 36px;
      font-weight: 200;
    }

    .line {
      width: 36px;
      height: 3px;
      border-radius: 1px;
      background: var(--primary-lite);
    }
  }
  .infoPanels {
    display: flex;
    flex-direction: column;
    width: 100%;

    .infoPanel {
      display: flex;
      min-height: 400px;
      height: 50vw;
      max-height: 432px;
      display: flex;
      flex-wrap: wrap;
      background-color: var(--white);

      @media (max-width: 900px) {
        align-items: flex-start;
        justify-content: flex-start;
        max-height: none;
        height: 100%;
      }

      &:nth-child(1) {
        .infoImage > div {
          background-position-x: 50%;
          background-position-y: 20%;

          @media (max-width: 475px) {
            background-position-x: 30%;
          }
        }
      }

      &:nth-child(2) {
        .infoImage > div {
          @media (max-width: 475px) {
            background-position-x: 0%;
          }
        }
      }

      .imageContainer {
        width: 65%;
        height: 100%;
        position: relative;
        overflow: hidden;

        @media (max-width: 1150px) {
          width: 55%;
        }
        @media (max-width: 900px) {
          order: 1;
          flex: unset;
          width: 100%;
          height: 300px;
        }

        .image {
          background-repeat: no-repeat;
          background-position: 0px 0px;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 100%;
        }
      }

      .panel {
        position: relative;
        width: 35%;
        overflow: hidden;
        @media (max-width: 1150px) {
          width: 45%;
        }

        @media (max-width: 900px) {
          align-items: flex-start;
          justify-content: flex-start;
          padding: 60px;
          width: 100%;
          height: 100%;
          margin: 0px;
        }

        .angledAccent {
          width: 120%;
          height: 211px;
          background-color: var(--cultured-white);
          position: absolute;
          transform: rotate(-11deg);
          top: unset;
          left: unset;
          bottom: -168px;
          right: -28px;
          @media (max-width: 900px) {
            transform: rotate(-7deg);
            right: -28px;
            height: 111px;
            width: 200%;
            bottom: -130px;
          }
          @media (max-width: 500px) {
            bottom: -100px;
          }
        }

        @media (max-width: 900px) {
          order: 2;
          padding-left: 0;
          padding-right: 0;
          align-items: center;
        }

        .innerContainer {
          height: 100%;
          padding: 0 100px 0 80px;
          @media (max-width: 1150px) {
            padding: 0 80px 0 60px;
          }
          @media (max-width: 900px) {
            order: 3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 120px 0 60px;
          }
          @media (max-width: 450px) {
            padding: 0 40px 0 30px;
          }

          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;

          .title {
            font-family: Poppins;
            font-weight: bold;
            font-size: 14px;
            line-height: 25px;
            text-transform: uppercase;
            margin-bottom: 15px;
            font-weight: 700;
            letter-spacing: 0em;
          }

          .subheading {
            margin: 15px 0 15px 0;
            font-size: 28px;
            line-height: 36px;
            font-weight: 200;
          }

          .line {
            width: 36px;
            height: 3px;
            border-radius: 1px;
            background: var(--primary-lite);
          }

          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
          }
        }
      }
    }
  }
  .featuresContainer {
    @media (min-width: 1580px) {
      padding: 80px 150px 80px 150px;
    }
    padding: 80px;
    position: relative;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position-x: left;
    background-position-y: bottom;
    width: 100vw;

    @media (max-width: 900px) {
      padding: 40px;
      background-size: 70%;
      background-position-x: left;
      background-position-y: bottom;
    }

    .sectionTitleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 20%;

      .sectionTitle {
        font-family: Poppins;
        font-weight: 300;
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 20px;
        text-align: center;
      }

      .line {
        position: relative;
        width: 36px;
        height: 3px;
        border-radius: 1px;
        background: var(--primary-lite);
      }
    }

    .bulletsContainer {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 50px;

      @media (max-width: 900px) {
        margin: 50px 0 50px 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // margin: 35px;

        .bullets {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin: 30px;
          height: 20px;

          @media (max-width: 900px) {
            margin: 23px 0 23px 0;
          }

          .text {
            margin-left: 20px;
            color: var(--dark-blue);
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            @media (min-width: 1220px) {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;