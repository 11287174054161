@import (reference) "styles/theme.less";

.logoutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.button {
  border: 1px solid var(--whale-white);
  background-color: rgba(255, 255, 255, 0.1);
  height: 30px;
  font-size: 12px;
  margin-left: 25px;
  border-radius: 20px;
  @media (max-width: 576px) {
    margin-left: 10px;
  }
  @media (max-width: 375px) {
    margin-left: 5px;
  }
}

.modal {
  .icon {
    font-size: 16px;
    color: var(--primary-color);
  }

  .heading {
    font-size: 16px;
    margin-left: 5px;
  }

  .footer {
    .button {
      border-radius: 2px;
      height: 32px;
      width: 100px;
    }

    .submit {
      .button();

      .text {
        text-transform: uppercase;
        font-weight: 100;
        color: var(--white);
      }
    }

    .cancel {
      .button();
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);

      .text {
        text-transform: uppercase;
        font-weight: 100;
        color: var(--primary-color);
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;