@import (reference) "styles/theme.less";

.content {
  padding-top: 64px;
  background-color: rgb(243, 246, 248);

  h2 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 100;
  }

  h3 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 300;
  }

  h4 {
    font-family: "Poppins";
    position: relative;
    font-size: 14px;
    line-height: 25px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 32px;

    &:after {
      content: "";
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 30px;
      height: 3px;
      background-color: var(--primary-lite);
      border-radius: 4px;
    }

    &.centered {
      &:after {
        content: "";
        position: absolute;
        bottom: -16px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 30px;
        height: 3px;
        background-color: var(--primary-lite);
        border-radius: 4px;
      }
    }
  }

  h5 {
    font-family: "Poppins", "Barlow";
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.carouselContainer {
  position: relative;
  width: 100%;
  height: 720px;
  max-height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  background-color: #000;

  .videoContainer {
    width: 100%;
    z-index: 2;
    overflow: hidden;

    .video {
      object-fit: cover;
      object-position: center top;
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .imgContainer {
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;

    .panel {
      display: flex !important;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 720px;
      max-height: calc(100vh - 50px);

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(32, 32, 32, 0.4);
        z-index: 3;
      }
    }
  }

  .control {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 36px;
    font-weight: 100;
    color: var(--light-grey);
    cursor: pointer;
    z-index: 10;
    transition: all 0.15s ease-in-out;

    &:hover {
      color: var(--grey);
      transform: scale(1.05);
    }
  }

  .leftControl {
    .control();
    left: 24px;

    @media (max-width: 768px) {
      left: 12px;
    }
  }

  .rightControl {
    .control();
    right: 24px;

    @media (max-width: 768px) {
      right: 12px;
    }
  }

  .titleContainer {
    position: absolute;
    width: calc(100% - 128px);
    max-width: 1280px;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .inner {
      width: 100%;
      max-width: 50vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 100px;

      @media (max-width: 768px) {
        max-width: none;
        margin-left: 16px;
        margin-right: 16px;
      }
    }

    .heading {
      text-transform: uppercase;
      color: var(--white);
      font-family: "Poppins", "Barlow";
      font-size: 54px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: left;

      @media (max-width: 475px) {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .headingSmaller {
      .heading();

      @media (max-width: 768px) {
        font-size: 26px;
        line-height: 28px;
      }
    }

    .subHeading {
      margin-top: 16px;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 24px;
      color: var(--white);
      font-size: 21px;
      font-style: normal;
      font-weight: 300;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;

      .break {
        @media (max-width: 475px) {
          display: none;
        }
      }
    }
    .inputContainer {
      display: flex;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .zipInput {
      height: 40px;
      width: 200px;
      .center();
      border-radius: 2px;
      background-color: transparent;
      border: none;
      // box-shadow: 0 0 0 0.5px rgb(122,122,122);
      border: 1px solid var(--primary-lite);
      color: var(--primary-lite);
      background-color: var(--white);
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0em;
      margin-bottom: 20px;
      margin-right: 20px;
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;

      &::placeholder {
        color: var(--primary-lite);
      }
    }

    .zipButton {
      height: 40px;
      width: 200px;
      text-transform: uppercase;
      background-color: var(--primary-lite);
      border-color: var(--primary-lite);
      border-radius: 96px;
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;

      &:hover {
        background-color: var(--primary-lite-highlighted);
      }
    }
  }

  // react-transition-group classnames
  // ensure this variable is the same as the speed prop passed to react-slick caorusel
  @carousel-fade-timeout: 1000;
  @carousel-fade-timing: linear;
  @carousel-transition: opacity @carousel-fade-timeout @carousel-fade-timing;

  .fadeEnter {
    opacity: 0;
  }
  .fadeEnterActive {
    opacity: 1;
    transition: @carousel-transition;
  }
  .fadeEnterDone {
    opacity: 1;
  }
  .fadeExit {
    opacity: 1;
  }
  .fadeExitActive {
    opacity: 0;
    transition: @carousel-transition;
  }
  .fadeExitDone {
    opacity: 0;
  }

  // animation for current slide progress animation effect
  .dots {
    :global(li) {
      width: 64px;
    }

    :global(li.slick-active) {
      width: 96px;

      button {
        position: relative;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.3);
        z-index: 3;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.8);
          width: 0%;
          z-index: 4;
          opacity: 1;
          animation-duration: 7s;
          animation-name: progress;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }

        @keyframes progress {
          from {
            width: 0%;
          }

          to {
            width: 100%;
          }
        }
      }
    }

    // carousel container on hover causes dots animation to pause
    :global(li.slick-active-paused) {
      button::before {
        animation-play-state: paused;
      }
    }
  }
}

.counters {
  width: 100%;
  padding: 60px 16px;
  background-color: var(--cream);
  .center();

  .innerContainer {
    width: 100%;
    max-width: 1280px;
    flex-direction: column;
    .center();

    .heading {
      margin-bottom: 16px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
    }

    .subheading {
      text-align: center;
      max-width: 900px;
      margin-bottom: 70px;
      font-size: 28px;
      line-height: 36px;
      font-weight: 100;
    }
  }

  .countersContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .counterCard {
      flex: 1 0 320px;
      overflow: hidden;
      position: relative;
      padding: 26px;
      background-color: var(--white);
      height: 390px;
      min-width: 320px;
      max-width: 31%;
      border-radius: 4px;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.08);
      background-size: cover;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      @media (max-width: 1025px) {
        width: 100%;
        max-width: 600px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(var(--white) 40%, transparent 60%);
        z-index: 1;
      }

      .title {
        z-index: 2;
        margin-top: 30px;
      }

      .topContainer {
        width: 100%;
        position: relative;
      }

      .counterContainer {
        display: flex;
        z-index: 2;
        overflow: hidden;
      }

      .prefix {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-transform: uppercase;
        margin-right: 4px;
        z-index: 2;
      }

      .suffix {
        .prefix();
        font-size: 14px;
        line-height: 25px;
        vertical-align: baseline;
        position: absolute;
        bottom: -26px;
        right: -2px;
      }

      @counter-box-height: 47px;
      @counter-margin: 2px;
      @animate-timing: cubic-bezier(0.42, -0.65, 0.58, 1.58);

      .countBox {
        position: relative;
        z-index: 3;
        margin-right: @counter-margin;
        height: @counter-box-height;
        width: 33px;
        background-color: #000;
        overflow: hidden;
        border-radius: 4px;
      }

      .digit {
        position: absolute;
        top: 0;
        left: 0;
        .center();
        height: 100%;
        width: 100%;
        background-color: #000;
        color: var(--white);
        font-family: "Oswald", "Barlow", sans-serif;
        font-weight: 500;
        font-size: 34px;
        line-height: 22px;
      }

      .separator {
        margin-right: @counter-margin;
        height: @counter-box-height;
        font-family: "Oswald", "Barlow", sans-serif;
        font-weight: 500;
        font-size: 35px;
        z-index: 2;
      }

      .animateEnter {
        transform: translateY(@counter-box-height);
      }

      .animateEnterActive {
        transform: translateY(0);
        transition: transform 300ms @animate-timing;
      }

      .animateExit {
        transform: translateY(0);
      }

      .animateExitActive {
        transform: translateY(-@counter-box-height);
        transition: transform 300ms @animate-timing;
      }

      @media (max-width: 1280px) {
        padding: 0 16px;
        margin-bottom: 16px;
      }
    }

    @media (max-width: 1025px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.bgDarkenZoomOnHover {
  .bgDarkenZoomOnHover();
}

.infoPanels {
  display: flex;
  flex-direction: column;
  width: 100%;

  .infoPanel {
    display: flex;
    width: 100%;
    min-height: 750px;
    height: 50vw;
    background-color: var(--white);

    @media (max-width: 900px) {
      flex-direction: column;
      height: auto;
    }

    &:nth-child(1) {
      .infoImage > div {
        background-position-x: 50%;
        background-position-y: 20%;

        @media (max-width: 475px) {
          background-position-x: 30%;
        }
      }
    }

    &:nth-child(2) {
      .infoImage > div {
        @media (max-width: 475px) {
          background-position-x: 0%;
        }
      }
    }

    .infoImage {
      width: 55%;
      height: 100%;
      position: relative;
      overflow: hidden;

      @media (max-width: 900px) {
        order: 1;
        width: 100%;
        height: 500px;
      }
    }

    .panel {
      position: relative;
      width: 45%;
      height: 100%;
      padding-left: 60px;
      padding-right: 90px;
      .center();
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 900px) {
        order: 2;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        align-items: center;
        min-height: 500px;
      }

      .innerContainer {
        width: 100%;
        @media (max-width: 900px) {
          max-width: 500px;
          display: flex;
          flex-direction: column;
          padding: 26px 36px 100px;
        }
      }

      .title {
        position: relative;
        margin-bottom: 32px;
        padding-top: 90px;
        font-weight: 700;

        @media (min-width: 900px) and (max-width: 1280px) {
          padding-top: 32px;
        }
      }

      .subheading {
        margin-bottom: 40px;
      }

      .infoBullets {
        padding-left: 0;
        li {
          position: relative;
          list-style-type: none;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 24px;
          padding-left: 20px;
          @media (min-width: 900px) and (max-width: 1280px) {
            font-size: 14px;
            line-height: 16px;
          }
        }

        li::before {
          content: "■";
          position: absolute;
          color: var(--primary-lite);
          left: 0;
          top: -1px;
          font-size: 10px;
        }
      }

      .logos {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding-top: 10px;
        padding-left: 15px;
        margin-bottom: 40px;
        .logo {
          height: 90px;
          margin-right: 8%;
          background-size: contain;
          background-repeat: no-repeat;

          // &:not(:first-child) {
          //   margin-left: 50px;
          // }
          // &:not(:last-child) {
          //   margin-right: 50px;
          // }
        }

        .bbbLogo {
          .logo();
          width: 80px;
          // background-image: url("@{s3-asset-path}/img/home/logo_BBB_blk.svg");
          background-image: url("/img/bbb_logo.png");
          background-position: center center;
        }

        .bCorpLogo {
          .logo();
          width: 70px;
          // background-image: url("@{s3-asset-path}/img/home/logo_B-Corp_blk.svg");
          background-image: url("/img/b_corp_logo.png");
          background-position: center center;
        }

        .incLogo {
          .logo();
          width: 137px;
          // background-image: url("@{s3-asset-path}/img/home/logo_Inc1000.svg");
          background-image: url("/img/inc5000_logo_43.png");
          background-position: center center;
        }
      }

      .text {
        font-size: 12px;
        line-height: 21px;
        margin-bottom: 28px;
      }

      .zipInput {
        display: flex;

        .input {
          margin-right: 8px;
          height: 50px;
          width: 200px;
          .center();
          border-radius: 6px;
          background-color: transparent;
          border: 1px solid var(--loblolly-gray);
          text-transform: uppercase;
          text-align: center;
          font-size: 12px;
          line-height: 28px;
          color: var(--tangaroa);
          &:focus {
            border-color: var(--tangaroa);
          }
        }

        .zipButton {
          .buttonHover();
          width: 225px;
          height: 36px;
          background-color: var(--primary-lite);
          border-color: var(--primary-lite);
          font-size: 16px;
          font-weight: 500;
          height: 50px;
          border-radius: 96px;
          text-transform: uppercase;
        }
      }

      .stepBullets {
        list-style-type: none;
        padding: 0;
        margin-bottom: 72px;

        @child-count: 5;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          font-size: 16px;

          &::before {
            margin-right: 16px;
            background-color: var(--primary-lite);
            color: var(--white);
            border-radius: 3px;
            text-transform: uppercase;
            font-size: 12px;
            height: 20px;
            width: 50px;
            .center();
          }
        }

        .loop(@i, @label) when (@i <= @child-count) {
          &:nth-child(@{i}) {
            &::before {
              content: "@{label} @{i}";
            }
          }
          .loop(@i + 1, @label);
        }

        li.en {
          .loop(1, "Step");
        }

        li.es {
          .loop(1, "Paso");
        }
      }
    }
  }
}

.endorsements {
  width: 100%;
  height: 200px;
  background-color: var(--tangaroa);
  padding: 40px 16px;
  .center();

  @media (max-width: 768px) {
    display: block;
    height: auto;
    padding: 40px 26px;
  }

  .innerContainer {
    width: 100%;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .item {
    width: 250px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .logo {
      height: 30px;
      width: 100%;
      background-position: top center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 16px;
    }

    &:nth-child(1) {
      .logo {
        background-image: url("@{s3-asset-path}/img/home/logo_TechCrunch.svg");
      }
    }
    &:nth-child(2) {
      .logo {
        background-image: url("@{s3-asset-path}/img/home/logo_Inc5000.svg");
      }
    }
    &:nth-child(3) {
      .logo {
        background-image: url("@{s3-asset-path}/img/home/logo_EINPresswire_wht.png");
      }
    }

    .quote {
      color: var(--light-grey);
      width: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.jbMessage {
  position: relative;
  background-color: var(--cream);
  overflow: hidden;
  width: 100%;
  .center();
  padding: 50px 16px;

  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100vw;
    top: 0;
    left: 0;
    background-color: var(--white);
    transform: rotate(-8deg) translate(-100px, -98vw);
  }

  @media (max-width: 768px) {
    padding: 0 26px 100px;
  }

  .innerContainer {
    width: 100%;
    max-width: 1280px;
    margin-top: 70px;
  }

  .message {
    width: 100%;
    position: relative;
  }

  .previewImg {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    width: calc(100vw - 32px);
    max-width: 1000px;
    height: calc((100vw - 32px) * 0.525);
    max-height: 562px;
    z-index: 1;
    cursor: pointer;

    .bg {
      background-image: url("@{s3-asset-path}/img/home/home_messagefromceo.png");
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .card {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    margin: auto 36px auto 0;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background-color: var(--white);
    height: 360px;
    width: 320px;
    padding: 36px 46px;
    z-index: 3;

    @media (max-width: 1047px) {
      right: -15px;
    }
    @media (max-width: 768px) {
      position: relative;
      width: 100%;
      height: 250px;
      margin: 16px auto 0 auto;
    }

    .headline {
      font-size: 14px;
      margin-top: 10px;
      font-weight: 700;
    }

    .watchButton {
      margin-top: 40px;
      width: 200px;
      background-color: var(--primary-lite);
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      .center();
      border-radius: 96px;
      height: 40px;

      &:hover {
        background-color: var(--primary-lite-highlighted);
      }

      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
  }
}

.buttonHover {
  &:hover {
    background-color: var(--primary-lite-highlighted);
    border-color: var(--primary-lite-highlighted);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;