@import (reference) "styles/theme.less";

.dateRange {
  .label {
    color: var(--dark-periwinkle);
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    margin-bottom: 6px;
    font-family: "Barlow";
    font-style: normal;
  }

  :global(.ant-picker-clear) {
    opacity: 1;
    svg {
      fill: var(--nepal-blue);
    }
  }

  .iconAtTheBegining {
    :global(.ant-picker-suffix) {
      margin-left: 1px;
      margin-right: 10px;
      order: -1;
      border-right: 1px solid var(--botticelli-blue);
      height: 100%;
      padding: 7px 5px;
    }
    :global(.ant-picker-input) {
      width: 60px;
    }
  }

  .activeBar {
    :global(.ant-picker-active-bar) {
      margin-left: 35px;
    }
  }

  .standardViewPadding {
    padding: 10px 16px !important;
  }

  .rangePicker {
    background-color: transparent;
    border-radius: 4px;
    width: 100%;
    border: 1px solid var(--botticelli-blue);
    padding: 0;
    height: 32px;
    top: 40.81%;
    bottom: 56.88%;

    div > input {
      font-size: 12px;
      color: var(--font-color-eastern-blue);

      &::placeholder {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        padding-left: 5px;
        color: var(--dark-blue);
      }
    }

    :global(.ant-picker-range-separator) {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: var(--dark-blue);
    }

    :global(.anticon-calendar) {
      color: var(--font-color-eastern-blue);
      padding-bottom: 1px;
      padding-left: 1px;
    }
  }

  .noBorder {
    border: none !important;
  }

  .largeText {
    div > input {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      height: 24px;
      &::placeholder {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
      }
    }
  }
}

.calendar :global td.ant-picker-cell-in-range::before {
  background-color: darken(#f0fdff, 10%);
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;