.HeaderAlertMessageContainer {
  min-height: 40px;
  width: 100%;

  .warning {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, var(--ferrari-red) 0%, var(--pumpkin) 100%);
    border: none;
    height: 100%;
    padding: 0px 15px;
    border-radius: 0;

    :global(.ant-alert-content) {
      display: flex;
      align-items: center;
      height: 100%;
    }

    :global(.ant-alert-message),
    :global(.ant-alert-description),
    :global(.ant-alert-action) {
      color: var(--white);
      display: flex;
      align-items: center;
      margin: 0px;
    }

    :global(.ant-alert-message) {
      font-size: 16px;
      margin-right: 45px;
      > div {
        padding: 0px;
        display: flex;
      }
    }

    :global(.ant-alert-description) {
      font-size: 14px;
    }

    :global(.ant-alert-action) {
      > button {
        color: var(--white);
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;