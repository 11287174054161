@import (reference) "styles/theme.less";

.stepsRow {
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  height: 50vw;
  min-height: 600px;
  .stepsInfo {
    height: 100%;
    width: 49%;
    // z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0 100px 125px;
    @media (max-width: 1200px) {
      // padding-right: 80px;
    }
    @media (max-width: 992px) {
      padding: 90px 0px 90px 100px;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 50%;

      padding: 75px;
    }
    @media (max-width: 576px) {
      padding: 50px;
    }
    @media (max-width: 480px) {
      padding: 35px;
    }
  }
  .tabletCol {
    width: 49%;
    height: 50vw;
    min-height: 600px;
    padding: 100px 0 70px 0;
    position: relative;
    overflow: visible;
    .tablet {
      height: 100%;
      width: 142%;
      right: -146px;
      top: 20px;
      position: absolute;
      background-image: url("@{s3-asset-path}/img/ambassador-join/ambassador_tablet.png");
      background-repeat: no-repeat;
      background-position: 60% 50%;
      background-size: 100%;
      overflow: visible;
      @media (max-width: 1200px) {
        right: -100px;
      }
      @media (max-width: 992px) {
        width: 160%;
        top: 15px;
        // right: -100px;
      }
      @media (max-width: 768px) {
        width: 700px;
        height: 400px;
        left: -135px;
        top: 30px;
      }
      @media (max-width: 576px) {
        // width: 100%;
        left: -160px;
      }
      @media (max-width: 480px) {
        // background-size: contain;
        left: -175px;
      }
    }
    @media (max-width: 768px) {
      padding: 0;
      width: 100%;
      height: 400px;
      min-height: 400px;
    }
    @media (max-width: 576px) {
      height: 30%;
    }
    @media (max-width: 480px) {
      height: 20%;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    min-height: 800px;
  }
}

.bullet {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 77px;
  background: var(--primary-lite);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulletRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 48px;
  @media (max-width: 1200px) {
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
  }
  @media (max-width: 530px) {
    margin-top: 15px;
  }
}

.bulletText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--table-grey);
  margin: 0 0 0 17px;
}

.stepsSub {
  font-style: normal;
  font-weight: 100;
  font-size: 28px;
  line-height: 36px;

  text-align: left;

  color: var(--table-grey);
  width: 100%;
  margin-bottom: 2px;
  @media (max-width: 390px) {
    margin: 0px;
  }
}

.stepsHeading {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  margin: 0px 0 15px 0;
  /* identical to box height, or 179% */

  text-align: left;
  text-transform: uppercase;

  color: var(--table-grey);

  @media (max-width: 390px) {
    margin: 0px 0 5px 0;
  }
}

.stepsHyphen {
  left: 167px;
  width: 30px;
  height: 3px;
  min-height: 3px;
  background-color: var(--primary-lite);
  border-radius: 4px;
  margin-bottom: 20px;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;