@import (reference) "styles/theme.less";

.defaultFromText {
  width: 100%;
  margin: 0 auto;
  padding-right: 11px;
  span {
    font-size: 9px;
    color: var(--primary-color);
    display: block;
    margin: 4px 0;
  }
}

.teamMemberFilter {
  .label {
    color: var(--nepal-blue);
    text-transform: uppercase;
    font-size: 10px;
    line-height: 9px;
    font-weight: 700;
  }
  .notice {
    margin-top: 10px;
    font-size: 10px;
    color: var(--dark-blue);
    button {
      margin-top: 10px;
      color: var(--primary-color);
      background-color: transparent;
      border: none;
      text-align: left;
      cursor: pointer;
    }
  }

  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background-color: transparent;
    border-radius: 0px;
    padding-left: 0px;
    border: 0;
    box-shadow: none !important;
  }

  :global(.ant-select-arrow) {
    right: 30px;
    font-size: 10px;
    line-height: 10px;
    color: transparent;
    text-transform: uppercase;
    margin-top: -9px;
    :global(.ant-select-suffix) {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 10px;
        height: 10px;
        padding-left: 4px;
        padding-top: 3px;
        background-image: url("/icons/icon_triangle.svg");
        background-size: 100%;
        background-position: center;
        border-radius: 50%;
        z-index: 6;
      }
    }
  }
}

:global(.teamMemberDropdown) {
  border-radius: 8px;

  :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
    background-color: var(--twilight-blue);
    color: var(--primary-color);
  }
}

.resetFiltersContainer {
  margin: 24px 0 28px 0;
  display: flex;
  justify-content: center;
  .resetFiltersButton {
    height: 24px;
    width: 146px;
    background-color: var(--white);
    color: var(--cyan-blue-azure);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 18px;

    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-shadow: none;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;