@import (reference) "styles/theme.less";

.drawer {
  :global(.ant-drawer-body) {
    padding: 0;
    padding-top: 50px;
    background-color: var(--dark-charcoal);
  }
}

.menuContainer {
  background-color: var(--dark-charcoal);
  width: 100%;
  padding: 50px 32px;
  display: flex;
  flex-direction: column;
}

.bigLinksList {
  list-style-type: none;
  margin-bottom: 48px;
  padding: 0;

  li {
    padding: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button,
  a {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    color: var(--white);
    font-size: 24px;
    line-height: 27px;
    font-weight: 600;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border: none;
      outline: none;
      color: var(--primary-lite);
    }

    &.phoneLink {
      color: var(--primary-lite);
    }
  }
}

.zipContainer {
  display: flex;
  flex-direction: column;
  width: 227px;
  margin-bottom: 48px;

  .zipInput {
    width: 100%;
    margin-bottom: 10px;
    height: 32px;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 28px;
    color: var(--white);
  }

  .zipButton {
    width: 100%;
    background-color: var(--primary-lite);
    border-radius: 96px;
    text-transform: uppercase;
    .center();
    font-size: 14px;
    line-height: 27px;
    font-weight: 600;
  }
}

.divider {
  width: 100%;
  height: 1px;
  border-color: var(--tundora-gray);
  margin-bottom: 24px;
}

.littleLinksList {
  .bigLinksList();

  li {
    margin-bottom: 0;
    height: 32px;
    display: flex;
    align-items: center;
  }

  button,
  a {
    color: var(--loblolly-gray);
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    text-transform: none;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;