@import (reference) "styles/theme.less";

:global(.personPopover) {
  .backgroundColor {
    background-color: var(--darkest-blue);
  }

  :global(.ant-popover-content) {
    letter-spacing: 0.3px;
    top: 15px;

    :global(.ant-popover-arrow) {
      .backgroundColor();
      outline-color: var(--dark-blue);
      transform: rotate(45deg);
      border-radius: 0px;
      width: 12px;
      height: 12px;
      z-index: 20;
      left: 27px;
      bottom: -5px;
      :global(.ant-popover-arrow-content) {
        bottom: 10px;
      }
    }
    :global(.ant-popover-inner) {
      border-radius: 8px;
      .backgroundColor();
      :global(.ant-popover-inner-content) {
        padding: 11px 16px;
      }

      .mainRow {
        display: flex;
        flex-wrap: nowrap;
      }

      .title {
        line-height: 12px;
        color: var(--nepal-blue);
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        padding: 0;
        margin: 0px 0 3px 0;
        line-height: 14px;
      }

      .ticket {
        line-height: 17px;
        color: var(--botticelli-blue);
        font-weight: 600;
        font-size: 14px;
        padding: 0;
        cursor: pointer;
        &:hover {
          color: var(--canary-yellow);
        }
      }

      .contentRow {
        color: var(--botticelli-blue);
        font-weight: 400;
        font-size: 12px;
        .nameText {
          font-family: "Barlow";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;

          color: var(--white);
          text-transform: capitalize;
        }
      }

      .contactRow {
        display: flex;
        align-items: center;
        .emailContainer {
          margin-top: 1px;
        }
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;