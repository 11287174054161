@import (reference) "styles/theme.less";

.successMsgUpload {
  display: flex;
  height: 100%;
  align-items: center;

  @media (max-width: 521px) {
    left: 0;
    position: relative;
    width: 100%;
    justify-content: center;
  }

  span {
    padding-left: 10px;
  }
}

.uploadWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 96px;

  &:hover .uploadButton > .contents {
    color: var(--white);
  }

  .uploadButton {
    display: inline-block;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;

    .contents {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .icon {
        font-size: 48px;
      }

      .textBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .direction {
          color: var(--white);
        }
        .disclaimer {
          .direction();
          font-size: 10px;
          font-style: italic;
        }
      }
    }
  }

  .upload {
    cursor: pointer;
    font-size: 150px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;