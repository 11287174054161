@import (reference) "styles/theme.less";

.phoneRow {
  span {
    display: flex;
    align-items: center;
  }

  .phone {
    font-size: 14px;
    margin-bottom: 0;
    cursor: pointer;
    color: var(--primary-color);
    display: inline;
  }

  .withHover {
    &:hover {
      color: var(--canary-yellow) !important;
    }
  }

  .icon {
    margin: 0px 0px 0 0px;
    cursor: pointer;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;