@import (reference) "styles/theme.less";

.content {
  padding-top: 50px;
  background-color: rbg(243, 246, 248);

  // Hero Image
  .heroImageContainer {
    position: relative;
    .heroImage {
      width: 100%;
      height: 720px;
      background-image: url("@{s3-asset-path}/img/careers/career_Header.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .titleOverlay {
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      z-index: 3;
      display: flex;
      align-items: center;

      .textContainer {
        height: 100%;
        padding-bottom: 132px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: auto;
        .mainText {
          font-family: Poppins;
          font-size: 54px;
          font-weight: 600;
          line-height: 50px;
          letter-spacing: 0em;
          text-align: center;
          color: var(--white);
          margin-bottom: 13px;
          text-transform: uppercase;
          width: 100%;
          max-width: 660px;
          @media (max-width: 505px) {
            font-size: 40px;
          }
        }
        .subText {
          font-size: 21px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          color: var(--white);
          width: 100%;
          max-width: 600px;
          text-align: center;
          @media (max-width: 505px) {
            font-size: 16px;
          }
        }
        .zipButton {
          .center();
          border-radius: 6px;
          text-transform: uppercase;
          background-color: var(--primary-lite);
          height: 40px;
          min-width: 144px;
          border-radius: 96px;
          border: none;
          font-size: 14px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          &:hover {
            background-color: var(--primary-lite-highlighted);
          }
        }
      }
    }
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    height: 250px;
    margin: auto;
    padding: 40px 40px 0px 40px;
  }

  .heading {
    font-family: Poppins;
    margin-bottom: 33px;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    font-weight: 700;

    &:after {
      content: "";
      width: 36px;
      height: 3px;
      background: var(--picton-blue);
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      border-radius: 1px;
    }
  }

  .subheading {
    color: var(--table-grey);
    font-size: 28px;
    line-height: 36px;
    font-weight: 100;
    width: 100%;
    max-width: 980px;
    margin-bottom: 32px;
    text-align: center;
  }

  .careersSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: calc(40%);
    position: relative;
    @media (max-width: 715px) {
      flex-direction: column;
      height: 100%;
      padding-left: 0;
    }
    input {
      margin: 3px 4px 3px 0;
      border-radius: 4px;
      color: var(--primary-color);
      border: none;
      padding: 2px 2px 2px 9px;
      width: 100%;
      border: 1px solid var(--botticelli-blue);
    }
    .careerImage {
      position: absolute;
      left: 0;
      width: calc(40%);
      height: 100%;
      background-image: url("@{s3-asset-path}/img/careers/careers_OpenRoles.png");
      background-position: 66% center;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: 715px) {
        position: relative;
        width: 100%;
        height: 340px;
        background-position: center 60%;
      }
    }
    .listings {
      background-color: var(--cream);
      width: 100%;
      height: 100%;
      padding-left: 100px;
      padding-bottom: 72px;
      @media (max-width: 715px) {
        padding-left: 72px;
      }
    }
    .listing {
      margin-bottom: 36px;
      padding-right: 36px;
      h4 {
        line-break: anywhere;
      }
      @media (max-width: 850px) {
        margin-bottom: 30px;
      }
    }
    .heading {
      .heading();
      margin-top: 72px;
      margin-bottom: 70px;
      text-align: left;
      width: auto;
      &:after {
        content: "";
        left: 0px;
        margin: 0;
      }
      @media (max-width: 715px) {
        width: 100%;
        text-align: center;
        padding-right: 100px;
        margin: 50px 0;
        &:after {
          content: "";
          left: -100px;
          margin: auto;
        }
      }
    }
    .newPostingForm {
      margin-top: 12px;
      width: 75%;
      input {
        height: 30px;
      }
      :global(.ant-select-arrow) {
        margin-top: -4px;
      }
      :global(.ant-select-selection-item) {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        display: flex;
        align-items: center;
      }
      :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
        margin-top: 4px;
        border-radius: 4px;
        color: var(--primary-color);
        border: 1px solid var(--botticelli-blue);
        padding: 0 9px;
        ::placeholder {
          color: red;
        }
      }
      .submitButton {
        margin-top: 12px;
        height: 26px;
        .center();
        border: none;
        background: var(--primary-color);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 36px;
        span {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          color: var(--white);
        }
      }
    }
    .subtextContainer {
      display: flex;
      align-items: center;
      .editingLocation {
        display: flex;
        align-items: center;
      }
      .updateButton {
        margin-left: 12px;
        margin-top: 6px;
        height: 22px;
        .center();
        border: none;
        background: var(--primary-color);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 36px;
        span {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          color: var(--white);
        }
      }
      .deleteButton {
        .updateButton();
        background: var(--scarlet);
        margin-left: 12px;
      }
      .cancelButton {
        .updateButton();
        margin-left: 6px;
        background: var(--white);
        border: 0.5px solid var(--primary-color);
        span {
          color: var(--primary-color);
        }
      }
    }

    .subtext {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: var(--table-grey);
      margin-top: 4px;
    }
    .subheading {
      .subheading();
      text-align: left;
      position: relative;
      margin-bottom: 0;
      transition: color 0.2s ease-in-out;
      &:before {
        content: url("/icons/icon_chevron_right.svg");
        padding-left: 9px;
        color: var(--white);
        font-size: 20px;
        line-height: 22px;
        border-radius: 61px;
        width: 24px;
        height: 24px;
        background: var(--primary-lite);
        position: absolute;
        top: 6px;
        left: -40px;
        right: 0;
        transition: background-color 0.2s ease-in-out;
      }
      &:hover {
        color: var(--picton-blue);
        transition: color 0.2s ease-in-out;
        &:before {
          background-color: var(--picton-blue);
          transition: background-color 0.2s ease-in-out;
        }
      }
      @media (max-width: 850px) {
        font-size: 26px;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;