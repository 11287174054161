@import (reference) "styles/theme.less";

.header {
  display: flex;
  div {
    align-items: center;
  }
  .heading {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: var(--darker-blue);
  }
}

.modal {
  :global(.ant-modal-content) {
    min-width: 680px;
    @media (max-width: 768px) {
      min-width: 0px;
    }
  }
  :global(.ant-modal-body) {
    padding: 0;
  }
  width: 620px !important;
  :global(.ant-modal-footer) {
    height: 68px;
  }
  :global(.ant-modal-close) {
    height: 36px;
    :global(.ant-modal-close-x) {
      font-size: 14px;
    }
  }
}

.conferenceModal {
  width: 100%;
  height: 390px;
  position: relative;

  .backgroundVideo {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    background: black;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.2;
  }

  .content {
    padding-top: 20px;
  }

  .logo {
    z-index: 2;
    position: relative;
    .center();
    margin-bottom: 20px;
    img {
      max-width: 100%;
      width: 50%;
    }
  }

  .textRow {
    z-index: 2;
    position: relative;
    width: 90%;
    margin: 0 auto;
    .center();
    span {
      text-align: center;
    }
  }

  .subText {
    .textRow();
    margin-bottom: 2px;
    span {
      color: var(--white);
      font-family: Poppins;
      font-size: 20px;
      font-weight: 800;
      line-height: 21px;
    }
  }

  .paragraphText {
    .textRow();
    width: 70%;
    margin-bottom: 39px;
    span {
      color: var(--white);
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
    @media (max-width: 500px) {
      width: 80%;
    }
  }

  .dateText {
    .textRow();
    margin-bottom: 2px;
    span {
      color: var(--mint);
      font-family: Poppins;
      font-size: 20px;
      font-weight: 700;
      line-height: 21px;
    }
  }

  .locationText {
    .textRow();
    padding-bottom: 46px;
    span {
      color: var(--mint);
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;