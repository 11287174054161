@import (reference) "styles/theme.less";

.singleFileDropzone {
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  border: 1px dashed var(--graham-grey);
  background-color: var(--white);
  border-radius: 3px;
  padding: 20px;
  min-height: 90px;
}

.widthShort {
  .singleFileDropzone();
  width: 70%;
}

.dropzoneSolidBorder {
  .singleFileDropzone();
  border: 1px solid var(--botticelli-blue);
}

.singleFileUploadInput {
  display: none;
}

.singleFileInputLabel {
  font-size: 14px;
  color: var(--primary-color);
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  cursor: pointer;

  .inputLabelContents {
    position: relative;
    display: flex;
    flex-direction: column;
    .center();
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 12px;

    .row {
      width: 100%;
      margin-bottom: 8px;
      .center();
    }

    .iconRow {
      .row();
      height: 36px;
    }

    .instruction {
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: var(--blue-ribbon);
    }

    .details {
      font-size: 14px;
      color: var(--graham-grey);
      font-weight: 500;
      margin-top: 5px;
    }
  }
}

.inputLabelWithFiles {
  .singleFileInputLabel();
}

.spinContainer {
  .singleFileInputLabel();
  height: 138px;
  background-color: var(--primary-color-a30);
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewContainer {
  width: 100%;
  overflow-x: hidden;
}

.previewGroup {
  width: 100%;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.previewRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fileDetails {
    display: flex;
    align-items: center;
    flex-grow: 2;
    margin-right: 24px;

    .documentIcon {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }

  .buttonSection {
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: 12px;
    color: var(--dark-blue);

    .deleteButton {
      cursor: pointer;
    }
  }

  .fileName {
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 600;
      font-size: 16px;
      color: var(--dark-blue);
    }

    .size {
      font-weight: 500;
      color: var(--nepal-blue);
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;