@import (reference) "styles/theme.less";

.emailRow {
  line-height: 20px;

  .email {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: var(--dark-blue);
    cursor: pointer;
  }

  .withHover {
    &:hover {
      color: var(--canary-yellow) !important;
    }
  }

  .icon {
    .email();
    position: relative;
    top: 2px;
    font-size: 16px;
    margin-left: 3px;
    margin-right: 7px;
  }

  .mailOutlinedContainer {
    margin-right: 8px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;