@import (reference) "styles/theme.less";

.joinModal {
  max-width: 720px !important;
  width: 90% !important;
  :global(.ant-modal-header) {
    min-height: 164px;
    text-align: center;
    padding-top: 44px;
    background-color: var(--primary-lite);
    border-radius: 8px 8px 0px 0px;
    @media (max-width: 575px) {
      padding: 16px 20px;
      padding-top: 44px;
    }
  }
  :global(.ant-modal-content) {
    border-radius: 8px;
  }
  :global(.ant-checkbox-group-item) {
    span {
      color: var(--dark-blue);
      font-size: 14px;
      line-height: 17px;
    }
  }
  .headerContainer {
    max-width: 550px;
    width: 80%;
    margin: 0 auto;
    .title {
      font-weight: 600;
      color: var(--white);
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0em;
    }
    .paragraph {
      margin-top: 10px;
      font-weight: 300;
      font-size: 16px;
      line-height: 23px;
      color: var(--white);
    }
    @media (max-width: 720px) {
      .title {
        font-size: 16px;
      }
      .paragraph {
        font-size: 12px;
      }
    }
    @media (max-width: 575px) {
      .title {
        font-size: 14px;
      }
      .paragraph {
        font-size: 10px;
      }
    }
  }
  .subHeaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 24px 0;
    .subHeader {
      text-transform: uppercase;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
    }
    .underline {
      height: 3px;
      width: 36px;
      border-radius: 1px;
      background-color: var(--primary-lite);
      margin-top: 6px;
    }
  }
  .formContainer {
    width: 80%;
    margin: 0 auto;
    :global(.ant-legacy-form-item) {
      margin-bottom: 12px;
    }
    :global(.ant-form-item-explain-error) {
      position: absolute;
      bottom: -18px;
      font-size: 10px;
      width: 100%;
      text-align: right;
    }
    :global(.ant-form-item-has-error) {
      input {
        border: 1px solid var(--sunset-orange) !important;
      }
      :global(.ant-input-group-addon) {
        border-top: 1px solid var(--sunset-orange);
        border-left: 1px solid var(--sunset-orange);
        border-bottom: 1px solid var(--sunset-orange);
      }
    }
    .inputLabel {
      font-size: 10px;
      font-weight: 600;
      line-height: 9px;
      letter-spacing: 0em;
      color: var(--hit-gray);
      text-transform: uppercase;
    }

    :global(.ant-input-group-addon) {
      background-color: var(--white);
      width: 42px;
      border-top: 1px solid var(--botticelli-blue);
      border-left: 1px solid var(--botticelli-blue);
      border-bottom: 1px solid var(--botticelli-blue);
    }
    .inputPrefix {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .input {
      color: var(--table-grey);
      margin-top: 3px;
      border-radius: 3px;
      height: 36px;
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      caret-color: var(--primary-lite);
      border: 1px solid var(--botticelli-blue);
      margin-bottom: 8px;
      input {
        height: 36px;
        border: 1px solid var(--botticelli-blue);
      }
    }
    .iconInput {
      .input();
      border: none;
      :global(.ant-input) {
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
      }
    }
    .select {
      .input();
      border: none;
      font-weight: 400;
      :global(.ant-select-arrow) {
        height: 36px;
        width: 42px;
        top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        border-left: 1px solid var(--botticelli-blue);
      }
      :global(.ant-select-selector) {
        border: 1px solid var(--botticelli-blue);
        height: 36px;
      }
      :global(.ant-select-selection-item) {
        height: 36px;
        display: flex;
        align-items: center;
      }
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 47px 0 24px 0;
    .button {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      width: 288px;
      @media (max-width: 720px) {
        font-size: 16px;
        width: 248px;
      }
      @media (max-width: 575px) {
        font-size: 14px;
        width: 228px;
      }
    }
    .joinButton {
      .button();
      height: 54px;
      background: var(--primary-lite);
      border-radius: 96px;
      color: var(--white);
      line-height: 25px;
      text-transform: uppercase;
    }
    .cancelButton {
      .button();
      border: none;
      margin-top: 24px;
      color: var(--primary-lite);
      box-shadow: none;
    }
  }
}

.successModal {
  .joinModal();
  max-width: 720px;
  width: 90%;
  height: 369px;
  max-height: 369px;
  :global(.ant-modal-header) {
    min-height: 82px;
    text-align: center;
    padding-top: 24px;
    background-color: var(--primary-lite);
    @media (max-width: 575px) {
      padding: 16px 20px;
      padding-top: 44px;
    }
  }
  .title {
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    color: var(--white);
  }
  .paragraphContainer {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 40px;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--table-grey);
    @media (max-width: 775px) {
      width: 90%;
      padding: 10px;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-bottom: 33px;
    .button {
      width: 170px;
      height: 36px;
      background: var(--picton-blue);
      border-radius: 96px;
      text-transform: uppercase;
      &:hover {
        background-color: var(--primary-lite-highlighted);
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;