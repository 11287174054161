@import (reference) "styles/theme.less";
@import "~animate.less/animate.less";

.dashboardUpdatesModal {
  :global(.ant-modal-header) {
    background-color: var(--lightest-grey);
    border-bottom: none;
  }

  :global(.ant-modal-body) {
    min-height: 175px;
    max-height: 50vh;
    scroll-behavior: auto;
    overflow-y: auto;

    :global(.ql-snow) {
      border: none;
    }

    // Custom SCROLLBAR > firefox:
    scrollbar-color: var(--blue-ribbon)-a15 transparent;
    scrollbar-width: thin;
    // Custom SCROLLBAR > other browsers:
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--blue-ribbon)-a15;
    }
  }

  :global(.ant-modal-footer) {
    background-color: var(--lightest-grey);
    padding: 10px 24px;
    border-top: none;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    .headerIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
    }

    .headerCategory {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: var(--blue-ribbon);
      margin-right: 7px;
    }

    .headerCreatedSince {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      text-transform: uppercase;
      color: var(--hammerhead-grey);
    }

    .headerHot {
      margin-right: 7px;
    }

    .headerTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: var(--darkest-blue);
    }
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    div {
      width: 33.33%;
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .navigate {
      :global(.ant-btn) {
        border: 0px;
        box-shadow: none;
        padding: 0px;
      }

      display: flex;
      align-items: center;
      justify-content: right;

      .spacer {
        width: 10px;
        margin-left: 10px;
      }

      .increment {
        border-image-source: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);

        color: var(--picton-blue);
        background: linear-gradient(95.7deg, var(--blue-ribbon) 12.17%, var(--picton-blue) 93.24%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
      .decrement {
        color: var(--picton-blue);
        background: linear-gradient(275.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    }
  }
}

.fadeDashboardModalUp {
  transform: translateY(100%) rotate3d(0, 1, 0, 90deg);
  opacity: 0;
  transition: 150ms;
}

.fadeDashboardModalMiddle {
  transform: translateY(0%) rotate3d(0, 0, 0, 0deg);
  opacity: 1;
  transition: 150ms;
}

.fadeDashboardModalDown {
  transform: translateY(-100%) rotate3d(0, 1, 0, 90deg);
  opacity: 0;
  transition: 150ms;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;