@import (reference) "styles/theme.less";

.dataGroups {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 700px;
  overflow-y: auto;
  padding: 0 8px 8px 10px;

  .loading {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .noDataGroups {
    font-style: italic;
    font-weight: 600;
    font-size: 12px;
  }

  .controlPanel {
    position: absolute;
    top: 47px;
    left: calc(100% - 158px);
    display: flex;
    flex-direction: column;
  }

  .controlRow {
    margin: 12px 0;
  }

  .deleteButton {
    .controlButton();
    .removeButton();
    .buttonShadowSmaller();
  }

  .dataGroup {
    position: relative;
    width: 100%;
    padding: 12px;
    padding-top: 0;
    background-color: var(--white);
    .buttonShadow();
    border-radius: 3px;
    overflow: hidden;

    &:not(:first-child) {
      margin-top: 12px;
    }

    .multiItemRow {
      width: 100%;
    }

    .groupLabelRow {
      width: 100%;
    }

    .expandRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 11px;
      color: var(--slate-grey);
      text-transform: uppercase;
    }

    .titleContainer {
      height: 33px;
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 100%;
    }

    .title {
      color: var(--slate-grey);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      max-width: calc(100% - 100px);
      .ellipsis();
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      width: 100px;
    }

    .subTitle {
      .title();
      font-size: 12px;
    }

    .expandIcon {
      top: 6px;
      right: 12px;
      font-size: 12px;
      color: var(--slate-grey-a65);
      transition: transform 0.15s ease-in-out;
      cursor: pointer;
    }

    .metaFieldRow {
      .buttonShadowSmaller();
      background-color: var(--light-baby-blue);
      padding: 4px 16px;
      width: 100%;
      border-radius: 3px;

      &:not(:first-child) {
        margin-top: 10px;
      }

      form {
        width: 100%;
      }

      .formItem {
        .formItem();
      }
    }
  }
}

.workflows {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 8px;

  .workflow {
    padding: 20px 30px;
    margin-top: 20px;
    margin: 20px 5px 0 5px;
    // background-color: var(--lighter-grey-a65);
    // .buttonShadow();
    border-radius: 3px;
    // height: 100%;

    @media (max-width: 768px) {
      padding: 20px 2px;
    }

    .workflowActions {
      .actions();
      align-items: center;
      height: 100%;

      .workflowAction {
        .action();
        padding: 0 12px;
      }

      @media (max-width: 668px) {
        flex-direction: column;
        align-items: flex-end;
        .workflowAction {
          padding: 6px 2px;
        }
      }
    }

    .workflowTitleSection {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .workflowTitle {
        font-weight: 600;
        margin-bottom: 0;
        font-size: 24px;
        text-transform: uppercase;
        color: var(--slate-grey);
      }

      .editIcon {
        margin-bottom: 0.7em;
        margin-left: 20px;
        cursor: pointer;
      }
    }

    .workflowsDescription {
      max-width: 500px;
    }

    .buttonRow {
      display: flex;
      justify-content: space-between;

      .deleteButton {
        .roundButton();
        .removeButton();
        border: 1px solid var(--sunset-orange);
      }
    }

    .addButton {
      .buttonShadow();
      .roundButton();
      margin-top: 6px;
      margin-bottom: 4px;
      padding: 4px 12px;
      height: 34px;
      text-transform: uppercase;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      .action {
        cursor: pointer;
        text-transform: uppercase;
        color: saturate(var(--primary-color), 20%);
        font-weight: 600;
        font-size: 13px;
        &:hover {
          color: saturate(var(--primary-color), 60%);
          font-weight: 600;
        }
      }
    }

    .phase {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 30px;

      .phaseHeader {
        width: 100%;
        margin: auto;
        padding: 12px 6px;
        background: var(--white);
        color: var(--slate-grey);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0px 1px 1px var(--slate-grey-a65);

        .phaseHeaderText {
          text-transform: uppercase;
          font-weight: 600;
        }

        .phaseActions {
          .actions();

          .phaseAction {
            .action();
            margin-left: 10px;
            padding: 2px 6px;
          }
        }

        .dragphaseIcon {
          font-size: 16px;
          font-weight: 900;
          cursor: move;
        }

        @media (max-width: 827px) {
          padding: 10px 6px;
          padding-left: 20px;
        }

        @media (max-width: 576px) {
          padding-left: 10px;
          .phaseActions {
            flex-direction: column;
            align-items: flex-end;
          }
        }
      }

      .addMilestoneContainer {
        // width: 95%;
        margin: auto;
        display: flex;
        justify-content: flex-end;

        .addMilestoneButton {
          .addButton();
          margin-top: 10px;
        }
      }

      .milestones {
        overflow-x: auto;
      }

      .milestoneRow {
        flex-flow: row;
        padding-bottom: 2px;

        .milestoneCol {
          // width: 200px;
          min-width: 150px;
        }
      }

      .milestoneCardSmall {
        min-height: 90px;
        width: 160px;
        margin-top: 40px;
        margin-left: 24px;
        padding: 14px 0px;
        border-radius: 4px;
        box-shadow: 0px 1px 5px var(--slate-grey-a65);
        font-size: 11px;
        background-color: var(--white);
        color: var(--slate-grey-a65);

        .milestoneActions {
          .actions();
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-start;
          padding-right: 16px;
          padding-bottom: 10px;

          .milestoneAction {
            .action();
            margin-left: 10px;
            margin-bottom: 10px;
            margin-top: 2px;
          }
        }

        .cardCol {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 15px;
            font-weight: 600;
            padding-left: 10px;
          }

          .optional {
            font-size: 10px;
            color: var(--slate-grey-a65);
            font-style: oblique;
            padding-left: 10px;
            margin-top: 2px;
            margin-bottom: -2px;
          }

          .required {
            font-size: 1.1em;
          }
        }

        .prereqTitle {
          font-size: 1.1em;
        }

        .prereq {
          font-size: 1.2em;
          color: var(--table-badge-unread);
        }

        .dragIcon {
          display: flex;
          justify-content: center;
          font-size: 15px;
          font-weight: 900;
          color: black;
          cursor: move;
        }
      }
    }

    .milestoneCard {
      min-height: 65px;
      margin-top: 20px;
      margin-left: 20px;
      padding: 16px 0px;
      border-radius: 4px;
      box-shadow: 0px 1px 5px var(--slate-grey-a65);
      font-size: 11px;
      background-color: var(--white);
      color: var(--slate-grey-a65);

      .milestoneActions {
        .actions();
        padding-right: 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 10px;

        .milestoneAction {
          .action();
          margin-left: 10px;
        }

        @media (max-width: 668px) {
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-end;
          padding-bottom: 10px;
        }
      }

      .cardCol {
        display: flex;

        .title {
          font-size: 15px;
          font-weight: 600;
          padding-left: 10px;
        }

        .description {
          font-size: 13px;
          padding-left: 10px;
          color: var(--slate-grey-a65);
        }

        .required {
          font-size: 1.1em;
        }
      }

      .prereqTitle {
        font-size: 1.1em;
      }

      .prereq {
        font-size: 1.2em;
        color: var(--table-badge-unread);
      }

      .dragIcon {
        display: flex;
        justify-content: center;
        font-size: 15px;
        font-weight: 900;
        color: black;
        cursor: move;
      }
    }
  }
}

.dragUp {
  border-top: 10px solid var(--slate-grey-a65);
  padding: 5px 0;
}

.dragDown {
  border-bottom: 10px solid var(--slate-grey-a65);
  padding: 5px 0;
}

.loading {
  width: 100%;
  height: 100%;
  font-size: 20px;
}

.formItem {
  padding-bottom: 0 !important;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;

  .input {
    .blue();
  }

  .select {
    .blue();
  }
}

.addButton {
  .roundButton();
  .blue();
  .buttonShadow();
  height: 32px;

  .buttonIcon {
    font-size: 16px;
    margin-right: 4px;
  }
}

.controlButton {
  .addButton();
  .blue();
  height: 12px;
  box-shadow: none;
  outline: none;

  .text {
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }
}

.removeButton {
  .addButton();
  background-color: var(--white);
  color: var(--sunset-orange);

  &:hover,
  &:active,
  &:focus {
    color: lighten(#ff4d4f, 15%);
    border-color: lighten(#ff4d4f, 15%);
  }
}

.addDataGroupButton {
  .addButton();
  .buttonShadowSmaller();
  margin-left: -16px;

  .icon {
    margin-left: -5px;
  }
}

.addFieldForm {
  width: 100%;
  .switchItem {
    .formItem();
    padding-left: 25px;
  }

  .lookup {
    .lookupOptionRow {
      margin-bottom: 4px;
    }
  }

  .deleteFieldRow {
    // margin: 6px 0 12px 0;
    margin-bottom: 12px;
  }

  .addFieldButton {
    .addButton();
    .buttonShadowSmaller();
    font-size: 12px;
  }

  .deleteButton {
    .removeButton();
    .buttonShadowSmaller();
  }
}

.drawerForm {
  margin: 20px 0;
  max-width: 350px;

  .sectionHeader {
    font-weight: 600;
    font-size: 16px;
  }

  .input {
    .blue();
  }

  .select {
    .blue();
  }

  .submitButton {
    margin-top: 30px;
    height: 34px;
  }

  .drawerFormItem {
    margin-top: 16px;
  }
}

.dataGroupOption {
  padding: 8px 20px;
  .ellipsis();

  .title {
    font-size: 14px;
    font-weight: 600;
  }
  .info {
    font-size: 12px;
    color: var(--slate-grey-a65);
  }
  .disclaimer {
    font-size: 10px;
    color: var(--slate-grey-a65);
    text-transform: uppercase;
    font-style: italic;
  }
}

.milestoneDataGroups {
  .dataGroupCard {
    margin: 4px 0;
    border: 1px solid var(--light-silver);
    max-width: 300px;
    .buttonShadowSmaller();

    .card > div:first-child {
      padding: 6px 12px;
    }

    .bodyRow {
      display: flex;
      align-items: center;
    }

    .dragIcon {
      font-size: 14px;
      cursor: move;
      transform: rotate(180deg);
    }

    .titleColumn {
      .title {
        font-size: 14px;
        font-weight: 600;
        .ellipsis();
      }
    }
  }

  .dragWrapper {
    border-width: 2px;
    border-style: dotted;
    border-color: transparent;
    box-sizing: border-box;
  }

  .dragUp {
    border-top-color: var(--slate-grey);
  }

  .dragDown {
    border-bottom-color: var(--slate-grey);
  }
}

.sidebar {
  width: 100%;
  height: 100%;
  // padding-top: 50px;

  .sidebarTitleText {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
  }

  .sidebarText {
    font-size: 12px;
    font-weight: 400;
    color: var(--slate-grey-a65);
  }

  .select {
    .blue();
    width: 100%;
    margin-top: 20px;
  }

  .buttonContainer {
    // width: 90%;
    // margin: auto;
    margin-bottom: 10px;
    margin-top: 20px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;

    .addWorkflowButton {
      width: 100%;
    }

    .icon {
      // font-size: 18px;
      transition: transform 0.2s linear;
    }
  }
}

.confirmModal {
  display: flex;
  flex-direction: column;

  .groupsList {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 32px;
    margin: 12px 0;
    .ellipsis();
  }

  .text {
    font-size: 12px;
  }

  .title {
    .text();
    font-weight: 600;
  }

  .disclaimer {
    font-size: 10px;
    font-style: italic;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;