.container {
  height: 50px;
  cursor: pointer;
  .svg {
    max-width: 117px;
    max-height: 36px;
    min-width: 80px;
    height: 100%;
    width: 100%;
    .path {
      transition: fill 300ms;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;