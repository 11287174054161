@import (reference) "styles/theme.less";

.activateAccountModal {
  position: relative;

  :global(.ant-modal-header) {
    display: none;
  }

  :global(.ant-modal-body) {
    background-image: url("/img/background-lines-top-right-3.png");
    background-size: 325px;
    background-position: right top;
    background-repeat: no-repeat;
    border-radius: 12px;
    padding: 0;
  }

  .container {
    padding: 79px 66px 40px 66px;
    z-index: 322;

    .title {
      color: var(--blue-ribbon);
      font-weight: 700;
      font-size: 32px;
      font-family: Poppins;
    }

    .text {
      color: var(--dark-blue);
      font-weight: 600;
      font-size: 16px;
      font-family: Barlow;
      max-width: 300px;
    }

    .footer {
      width: 100%;
      justify-content: center;
      display: flex;
      margin-top: 63px;

      :global(.ant-btn-primary) {
        box-shadow: none !important;
      }

      .closeButtonContainer {
        margin-right: 15px;
      }

      .submit {
        .center();
        height: 32px;
        width: 378px;
        border-radius: 6px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

        span {
          color: var(--white);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          padding-bottom: 2px;
        }

        .arrowContainer {
          .center();
          margin-left: 8px;
          margin-top: 2px;
        }

        min-width: 250px;
        height: 56px;
        background: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
        box-shadow: none;
        border: none;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;