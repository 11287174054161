@import (reference) "styles/theme.less";

.evolutionTooltipContent {
  color: var(--white);
  padding: 8px 8px 4px 8px;
  min-width: 330px;

  .top {
    display: flex;
    align-items: center;
    height: 50px;
    .left {
      height: 45px;
      min-width: 45px;
      .center();
      margin-right: 8px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .bottom {
    .top();
    height: 30px;
    overflow: hidden;

    .left {
      min-width: 45px;
      .center();
      margin-right: 8px;
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: transparent;
      border: none;
      color: var(--white);
      svg {
        margin-top: 2px;
      }
    }
  }

  .title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    white-space: nowrap;
  }

  .date {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }

  .learn {
    .date();
    font-weight: 700;
    margin-right: 3px;
    cursor: pointer;
    transition: 0.4s all;
    display: flex;
    align-items: center;
    svg {
      margin-left: 3px;
    }
    &:hover {
      color: var(--canary-yellow);
      svg > path {
        fill: var(--canary-yellow);
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;