@import (reference) "styles/theme.less";

.inputLabeledContainer {
  width: 100%;

  .inputText {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--dark-blue);
  }

  .labelRow {
    display: flex;
    justify-content: space-between;
  }

  .label {
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    color: var(--hit-gray);
    text-transform: uppercase;
    margin-bottom: 9px;
  }

  :global(.ant-input-affix-wrapper-disabled) {
    background: var(--botticelli-green-a50);
  }

  :global(.ant-input-prefix) {
    .center();
    height: 32px;
    width: 32px;
    margin-top: -5px;
    margin-left: -12px;
    margin-right: 8px;
    border-right: 1px solid var(--botticelli-blue);
  }

  .input {
    .inputText();
    height: 32px;
    border-radius: 3px;
    border: none;
    border: 1px solid var(--botticelli-blue);

    &:hover {
      border: 1px solid var(--botticelli-blue);
    }

    input {
      .inputText();
    }

    .prefix {
      .inputText();
      margin-top: 2px;
      margin-left: 2px;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;