@import (reference) "styles/theme.less";

.termsAndConditionsContainer {
  padding: 0px 0px 5px 25px;
  margin-top: -5px;

  .termsAndConditionsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .verificationLabel {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 20px;
      color: var(--blue-ribbon);
    }

    .openNewWindow {
      text-align: right;
      font-size: 16px;
      font-family: "Barlow";
      font-style: italic;
      font-weight: 500;
      line-height: 30px;
      margin-top: -10px;
      color: var(--dark-blue);

      .newWindowLink {
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }

  .termsText {
    height: 245px;
    margin: 8px 0px;

    .termsPdf {
      border-radius: 3px;
    }
  }

  .mobileTermsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .createAccountButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      width: 280px;
      height: 45px;

      .loadingSpinnerContainer {
        margin-right: 24px;
        margin-left: -24px;
      }
    }

    .termsCheckbox {
      font-family: "Poppins";
      color: var(--text-dark);
      margin: 15px;
    }
  }

  .selectTermsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 20px -20px 20px;

    .createAccountButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 0px 0px -10px;
      width: 280px;
      height: 45px;
      font-size: 16px;
      font-family: "Poppins";

      .loadingSpinnerContainer {
        margin-right: 24px;
        margin-left: -24px;
      }
    }

    .termsCheckbox {
      display: flex;
      justify-content: flex-end;
      font-family: "Barlow";
      color: var(--dark-blue);
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;