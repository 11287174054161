@import (reference) "styles/theme.less";

.impersonateHeaderContainer {
  width: 100%;
  height: 32px;
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .observingTag {
    font-weight: 600;
    color: var(--picton-blue);
    font-size: 14px;
    margin: 0;
  }

  .impersonateButtonContainer {
    @media (min-width: 800px) {
      position: absolute;
    }
    right: 46px;
    display: flex;
    flex-direction: row;
  }

  .impersonateHeaderButton {
    padding-left: 12px;
    padding-right: 12px;
    height: 20px;
    border-radius: 3px;
    margin: 6px 0px 6px 12px;
    background-color: var(--picton-blue);
    border-color: var(--picton-blue);
    font-size: 10px;
    line-height: 13.62px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);

    &:hover {
      background: var(--picton-blue-highlighted);
      border-color: var(--picton-blue-highlighted);
      color: var(--white);
    }

    @media (max-width: 800px) {
      margin: 6px 0px 6px 9px;
    }
  }

  .bypassButton {
    .impersonateHeaderButton();
  }

  .logbackButton {
    .impersonateHeaderButton();
    font-weight: 500;
  }

  .returnText {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
}

.logbackModal {
  z-index: 2000;
  position: absolute;
  width: 260px;
  height: 200px;
  left: calc(50vw - 130px);
  top: calc(50vh - 100px);
  padding: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  .logbackText {
    position: relative;
    width: 273px;
    height: 24px;
    margin-top: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: var(--darker-blue);
  }

  .logbackUser {
    position: relative;
    width: 273px;
    height: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--darker-blue);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;