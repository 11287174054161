@import (reference) "styles/theme.less";

.section {
  width: 100%;
  height: 50vw;
  min-height: 600px;
  position: relative;
  .counters {
    // opacity: 0.9;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    justify-content: space-around;
    padding-bottom: 15%;
    @media (max-width: 1440px) {
      justify-content: flex-start;
    }
  }
  .treeDiv {
    background-image: url("@{s3-asset-path}/img/ambassador-join/ambassador_tree_bg.png");
    background-position: 50% 0%;
    background-size: cover;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0px;
    @media (max-width: 1300px) {
      background-size: 115%;
    }
    @media (max-width: 1160px) {
      background-size: 135%;
    }
    @media (max-width: 992px) {
      background-size: 155%;
    }
    @media (max-width: 914px) {
      background-size: 180%;
    }
    @media (max-width: 768px) {
      background-size: 250%;
    }
    @media (max-width: 628px) {
      background-size: 275%;
    }
    @media (max-width: 576px) {
      background-size: 350%;
    }
    @media (max-width: 480px) {
      background-size: 400%;
    }
    @media (max-width: 420px) {
      background-size: 485%;
    }
  }
  @media (max-width: 914px) {
    min-height: 700px;
  }
  @media (max-width: 768px) {
    min-height: 800px;
  }
}

.heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  margin: 40px 0;
  /* identical to box height, or 179% */

  text-align: center;
  text-transform: uppercase;

  color: var(--table-grey);
  &:after {
    position: absolute;
    content: "";
    margin-top: 35px;
    left: 49vw;
    width: 30px;
    height: 3px;
    background-color: var(--primary-lite);
    border-radius: 4px;
  }
}

.subheading {
  font-style: normal;
  font-weight: 100;
  font-size: 28px;
  line-height: 36px;
  width: 68%;
  /* or 129% */

  text-align: center;

  color: var(--table-grey);
}

.counterSubtext {
  width: 370px;
  height: 20px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: var(--table-grey);
}

.countersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;