@import (reference) "styles/theme.less";

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  font-family: "Poppins";
  border: 1px solid transparent;
  border-radius: 9px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background: linear-gradient(var(--white), var(--white)) padding-box,
    linear-gradient(to right, var(--picton-blue), var(--blue-ribbon)) border-box;

  &:hover {
    border: 1px solid transparent;
    background: linear-gradient(var(--white), var(--white)) padding-box,
      linear-gradient(to right, var(--blue-ribbon), var(--blue-ribbon)) border-box;

    .buttonText {
      color: var(--blue-ribbon);
    }
  }

  .icon {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }

  .buttonText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-transform: capitalize;
    background: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

.socialButton {
  height: 45px;
  width: 100%;
  color: var(--white);
  border: none;
  border-radius: 4px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;