@import (reference) "styles/theme.less";

.ratingModal {
  background-color: transparent;

  :global(.ant-modal-content) {
    border-radius: 12px;
    overflow: hidden;
  }

  .content {
    padding: 0;
  }

  .header {
    width: 100%;
    background-color: var(--table-edit-mode);
    height: 40px;
    .center();
    color: var(--dark-blue);
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
  }

  .innerContent {
    width: 100%;
    padding: 26px 35px 16px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 576px) {
      padding: 10px 10px 10px 10px;
      :global(.ant-rate-star) {
        margin-right: 1px;
      }
    }

    .message {
      width: 85%;
      text-align: center;
      color: var(--dark-blue);
      font-size: 16px;
      line-height: 25px;

      .homeowner {
        color: #000;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;
      }
    }

    .ratingMessage {
      .message();
      width: 100%;
    }

    .mentorDisplay {
      width: 80%;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .userContainer {
        display: flex;
      }

      .user {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 10px;
          line-height: 14px;
          color: var(--table-grey);
        }
        .userName {
          font-size: 16px;
          line-height: 17px;
          color: var(--table-grey);
          font-weight: 600;
        }
        .city {
          font-size: 12px;
          line-height: 16px;
          color: var(--table-grey);
        }

        &:nth-child(2) {
          margin-left: 8px;
        }
      }
    }

    .ratingSelection {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      .heading {
        font-size: 10px;
        line-height: 17px;
        font-weight: 600;
        color: var(--bahama-blue);
        text-transform: uppercase;
      }

      .rateSelect {
        font-size: 42px;
        line-height: 42px;
        color: var(--primary-color);
      }
    }

    .additionalInfo {
      margin-top: 30px;
      width: 100%;
      height: 90px;

      .label {
        font-size: 10px;
        line-height: 17px;
        color: var(--bahama-blue);
        text-transform: uppercase;
      }

      .input {
        resize: none;
        height: 80px;
      }
    }

    .buttonsContainer {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      .buttonBase {
        border-radius: 36px;
        width: 49%;
        height: 32px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        font-size: 14px;
      }
      .cancelButton {
        .buttonBase();
        color: var(--primary-color);
      }
      .submitButton {
        .buttonBase();
      }
      .cancelButtonBig {
        .cancelButton();
        width: 100%;
        @media (max-width: 420px) {
          font-size: 12px;
        }
      }

      .buttonsRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 12px;
      }

      .buttonsRowSingle {
        width: 100%;
      }
    }

    .ratingsButtonsContainer {
      .buttonsContainer();
      margin-top: 16px;
      width: auto;
      min-width: 300px;
      .buttonsRow {
        width: auto;

        button:first-child {
          margin-right: 12px;
        }
      }
    }

    .disclaimer {
      width: 100%;
      margin-top: 15px;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: var(--pewter-blue);
    }
  }
}

.ratingShow {
  display: flex;
  flex-direction: row;

  :global(.ant-rate-star) {
    margin-right: 4px;
  }

  .count {
    font-size: 12px;
    color: var(--pewter-blue);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;