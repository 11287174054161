@import (reference) "styles/theme.less";
@import "components/Pagination/SearchFilterPagination/SearchFilterCSV/SearchFilterCSV.module.less";

.filtersDropdownMenu {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15) !important;
  border-radius: 3px;
  max-width: 98vw;
  min-width: 280px;

  .headerText {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    color: var(--nepal-blue);
  }

  .clearText {
    .headerText();
    font-weight: 400;
    cursor: pointer;
  }

  .clearButton {
    .center();
    height: 22px;
    background: var(--slate-turquoise);
    border-radius: 18px;
    color: var(--white);
    margin-left: 9px;
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    border: none;

    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }
  }
  // Header
  .header {
    height: 40px;
    width: 100%;
    border-radius: 3px 3px 0px 0px;
    background: var(--table-edit-mode);
    padding: 8px 12px 8px 18px;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
    }
  }

  .bodyContainer {
    display: flex;
    min-height: 250px;

    .tabs {
      width: 33%;
      min-width: 212px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background: var(--table-edit-mode);
      margin: 2px 0 4px 0;

      .tab {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        padding: 0 24px;
        background: var(--table-edit-mode);
        cursor: pointer;

        .tabText {
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          color: var(--dark-blue);
        }

        .disabledText {
          .tabText();
          color: var(--old-grey);
        }

        .countText {
          .tabText();
          line-height: 17px;

          .slashText {
            font-weight: 400;
          }
        }

        .countContainer {
          display: flex;
          align-items: center;
          .caretRightContainer {
            margin-left: 10px;
          }
        }

        &:last-of-type {
          border-bottom: 4px solid white;
        }

        @media (max-width: 680px) {
          border-top: none;
        }
      }

      .selectedTab {
        .tab();
        background: var(--athens-gray);
      }

      .disabledTab {
        .tab();
        cursor: not-allowed;
        background: var(--grey);
      }

      @media (max-width: 680px) {
        width: 100%;
        margin: 2px 0 0 0;
        margin-top: 4px;
      }
    }

    .content {
      width: 67%;
      min-width: 300px;
      max-height: 50vh;
      overflow-y: auto;
      padding: 50px;
      background-color: var(--white);

      .bodyHidden {
        display: none;
      }

      .subheaderText {
        font-size: 10px;
        font-weight: 700;
        line-height: 9px;
        color: var(--gothic-blue);
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      @media (max-width: 768px) {
        padding: 25px;
      }

      @media (max-width: 680px) {
        width: 100%;
      }
    }

    @media (max-width: 680px) {
      min-height: unset;
    }
  }

  .footer {
    padding: 12px 12px 16px 18px;
    background: var(--table-edit-mode);
    border-radius: 0px 0px 3px 3px;

    .top {
      display: flex;
      justify-content: space-between;
      height: 32px;

      .filterSetButton {
        .clearButton();
        background: var(--frostproof);
        color: var(--dark-blue);
        width: 125px;
      }
    }

    .filterSet {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .filterTag {
        .filterTagShared();
      }
    }
  }

  @media (max-width: 680px) {
    width: 300px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;