@import (reference) "styles/theme.less";

.content {
  padding-top: 64px;
  background-color: rgb(243, 246, 248);
}

.bgDarkenZoomOnHover {
  .bgDarkenZoomOnHover();
}

.bannerContainer {
  position: relative;
  width: 100%;
  height: 720px;
  display: flex;
  background-color: var(--dark-veil-black);
  color: var(--white);

  .inner {
    width: 100%;
    height: 100%;
    margin: auto;

    .videoContainer {
      width: 100%;
      height: 100%;
      background-image: url("@{s3-asset-path}/img/faq/faq_Header.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .textContainer {
      height: 100%;
      position: absolute;
      top: 0;
      width: calc(100%);

      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 128px);
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 64px;
      }

      .bannerText {
        width: 100%;
        text-transform: uppercase;
        font-size: 54px;
        line-height: 50px;
        font-family: "Poppins", "Barlow", sans-serif;
        font-weight: 600;
      }

      .bannerSubtext {
        margin: 20px 0px;
        font-weight: normal;
        font-size: 21px;
        line-height: 27px;
      }

      .bannerButton {
        height: 40px;
        width: 200px;
        text-transform: uppercase;
        color: var(--white);
        background-color: var(--primary-lite);
        border-radius: 96px;
        font-size: 14px;
        line-height: 28px;
        font-weight: 600;
        text-transform: uppercase;
        border: none;
        &:hover {
          background-color: var(--primary-lite-highlighted);
        }
      }
    }
  }
}

.videoCardsContainer {
  margin-top: 60px;
  margin-bottom: 72px;
  width: 100%;
  background-color: rgb(245, 247, 249);

  .hoverAnimation {
    transition: all 0.2s ease-in-out;
  }

  .inner {
    max-width: 1400px;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0px 50px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @media (max-width: 500px) {
      padding: 0px 20px;
    }
    .videoCard {
      cursor: pointer;
      flex: 1 0 30%;
      z-index: 10;
      color: var(--white);
      min-width: 290px;
      max-width: 47.5%;
      height: 508px;
      margin: 0px 11px;
      margin-bottom: 20px;
      border-radius: 4px;
      background-color: black;
      position: relative;
      overflow: hidden;
      filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.15));
      @media (max-width: 738px) {
        width: 100%;
        max-width: unset;
      }

      .image {
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: top center !important;
        height: calc(110%);
        z-index: 0;
        border-radius: 4px;

        &::before {
          // remove grey overlay for these image hovers,
          // looks terrible with .overlay
          background-color: transparent;
        }

        .overlay {
          height: 90%;
          background: linear-gradient(transparent 50%, black);
        }
      }

      .cta {
        position: absolute;
        bottom: 30px;
        height: 94px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background: linear-gradient(transparent 60%, black);
        max-width: 400px;
        padding: 0px 40px;
      }
      .text {
        .hoverAnimation();
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        font-family: "Poppins", "Barlow", sans-serif;
      }
      .logo {
        .hoverAnimation();
        margin-left: -16px;
        width: 70px;
        height: 36px;
        background-image: url(/img/powur-logo.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left 8px;
      }
      .line {
        .hoverAnimation();
        width: 36px;
        height: 3px;
        border-radius: 1px;
        background: var(--primary-lite);
        margin-bottom: 6px;
        margin-top: 4px;
      }
    }
  }
}

.faqContainer {
  width: 100%;
  background-color: var(--cream);

  .titleContainer {
    width: 100%;
    height: 84px;
    background: var(--dark-charcoal);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    .title {
      font-weight: 300;
      font-size: 27px;
      line-height: 36px;
      color: var(--white);
    }
  }
  .inner {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 60px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .question {
      font-weight: 300;
      font-size: 27px;
      line-height: 36px;
      margin: 9px 0px 24px 0px;
    }
    .text {
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 30px;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;