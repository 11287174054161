@import (reference) "styles/theme.less";

.removeButton {
  display: flex;
  align-items: center;
  color: var(--nepal-blue);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 0 12px;
  height: 22px;
  border: 1px solid var(--botticelli-blue);
  border-radius: 6px;
  background: var(--white);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: var(--ghost-white);

    span {
      color: var(--nepal-blue);
    }
  }

  .icon {
    background-color: var(--nepal-blue);
    height: 9px;
    width: 9px;
    border-radius: 50%;
    margin-right: 5px;
    display: flex;
    align-items: center;

    .line {
      margin: auto;
      background-color: var(--white);
      border-radius: 0.5px;
      width: 5px;
      height: 1px;
    }
  }
}

.removeButtonInverse {
  color: var(--white);
  background: var(--pewter-blue);

  &:hover {
    background: var(--pewter-blue);
  }

  .iconInverse {
    background: var(--white);
  }

  .line.lineInverse {
    background-color: var(--pewter-blue);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;