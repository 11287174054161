@import (reference) "styles/theme.less";

.dropdownContainer {
  padding: 4px;

  .dropdownButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 200px;
    background: linear-gradient(270.54deg, var(--picton-blue) 49.88%, var(--blue-ribbon) 135.73%);
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    border: none;

    &[disabled] {
      background: var(--botticelli-blue) !important;
      color: var(--white);
    }
  }
}

.menuItem {
  &:hover {
    background-color: var(--clear-sky-lite) !important;
    color: var(--font-color-eastern-blue);
  }
}

.dangerItem {
  color: var(--halt-red-orange);
  &:hover {
    background-color: var(--clear-sky-lite) !important;
    color: var(--pomegranate-red);
  }
}

.alertModalContent {
  padding: 24px 56px 76px 56px;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;