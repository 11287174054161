@import (reference) "styles/theme.less";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--table-grey);

  .infoRows {
    margin-left: 9px;

    .relToTicket {
      font-size: 8px;
      line-height: 11px;
    }
    .name {
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
    }
    .disabledName {
      .name();
      color: var(--table-badge-total);
    }
    .relToProject {
      font-size: 8px;
      line-height: 11px;
      color: var(--table-badge-total);
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;