@import (reference) "styles/theme.less";

.defaultCloseButton {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  width: 32px;
  height: 32px;
  border-radius: 6px;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--white);
    border: 1px solid transparent;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;