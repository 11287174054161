@import (reference) "styles/theme.less";

.gradientButton {
  background-image: linear-gradient(101deg, rgba(32, 194, 241, 1), rgba(0, 112, 244, 1));
  width: 100%;
  height: 100%;
  box-shadow: 2px 1000px 1px var(--white) inset;
  border: solid 1px transparent;
  border-radius: 6px;
  display: flex !important;
  background-origin: border-box;
  align-items: center;
  justify-content: center;
}
.disabledGradientButton {
  background-image: linear-gradient(101deg, rgba(214, 225, 240, 1), rgba(214, 225, 240, 1));
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 2px 1000px 1px var(--white) inset;
  border: solid 1px transparent;
  border-radius: 6px;
  display: flex !important;
  background-origin: border-box;
  align-items: center;
  justify-content: center;
  .disabledCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;