@import (reference) "styles/theme.less";

.updateLeadMapContainer {
  position: relative;
  // google maps needs its container to explicitly define its height and width. width is defined in the javascript and is based on the width of the drawer.

  @media (max-width: 700px) {
    max-height: 500px;
    margin-bottom: 60px;
    .latLongInputContainer {
      margin-top: 15px;
    }
  }

  .mapCard {
    width: 100%;
    .map > div {
      border-radius: 12px;
    }
  }

  .confirmLocationAlert {
    display: flex;
    flex-direction: column;
    padding: 8px 32px;
    background-color: var(--darkest-blue);
    border-radius: 6px;
    color: var(--white);
    font-size: 16px;
    top: -4px;
    position: absolute;
    left: 200px;

    .confirmLocationTitle {
      text-transform: uppercase;
      font-weight: 600;
    }
    .confirmLocationInstruction {
      font-weight: 400;
    }
  }

  .latLongInputCard {
    .disclaimer {
      position: absolute;
      background-color: var(--white);
      padding: 4px;
      border-radius: 3px;
      border: 1px solid black;
      bottom: 116px;
      left: 195px;
      font-size: 10px;
      color: var(--guardsman-red);
    }

    .latLongInputRow {
      display: flex;
      align-items: flex-end;
      height: 75px;

      .latLongInputContainer {
        display: flex;
        flex-direction: column;
        min-width: 160px;
        margin-right: 16px;

        .inputLabel {
          margin-bottom: 8px;
          color: var(--nepal-blue);
          text-transform: uppercase;
          font-size: 10px;
          line-height: 10px;
          font-weight: 700;
        }

        .untouchedLabel {
          .inputLabel();
          color: var(--safety-orange);
        }

        .input {
          border-radius: 4px;
          font-weight: 500;
          color: var(--dark-blue);
          border: 1px solid var(--botticelli-blue);
        }

        .untouchedInput {
          .input();
          color: var(--safety-orange);
          width: 170px;
        }
      }
    }

    .latLongInputCol {
      .latLongInputRow();
      flex-wrap: wrap;

      .latLongInputContainer {
        max-width: 100%;
        margin-bottom: 10px;
        flex-grow: 1;

        .input {
          width: 100%;
        }

        .untouchedInput {
          .input();
        }
      }
    }

    .map {
      @media (max-width: 700px) {
        max-height: 500px;
      }

      max-height: 764px;
      margin-bottom: 50px;
      div:first-of-type {
        border-radius: 4px;
      }
    }
  }

  .resetGeocodeButton {
    position: absolute;
    bottom: 107px;
    left: 8px;
    font-size: 12px;
  }

  .confirmPinLocation {
    position: absolute;
    bottom: 120px;
    right: 60px;
    font-size: 12px;
  }

  .noMapData {
    position: absolute;
    width: 400px;
    text-align: center;
    font-size: 12px;
    color: var(--guardsman-red);
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: var(--white);
    border: 1px solid black;
    border-radius: 3px;
    padding: 10px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;