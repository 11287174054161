@import (reference) "styles/theme.less";

.table {
  :global(.ant-table-fixed-header) {
    background-color: transparent;
  }

  :global(.ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
    display: none;
  }

  Table {
    border-spacing: 0 8px;
    background-color: var(--table-edit-mode);
    max-width: 100%;
    width: 100%;
    :global(.ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
      display: none;
    }
    :global(.ant-table-thead > tr > th) {
      border-bottom: unset;
      background: var(--white);
      color: var(--nepal-blue);
    }
    thead {
      :global(tr:first-child th:first-child) {
        overflow: visible;
        z-index: 2;
      }

      :global(.ant-table-selection-column) {
        :global(.ant-table-selection) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: nowrap;
          padding-left: 11px;
        }

        :global(.ant-table-selection-extra) {
          margin-left: 4px;
          position: relative;

          svg {
            fill: var(--primary-color);
          }
        }
      }

      tr:first-child th:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      tr:last-child th:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    :global(.ant-table-tbody),
    :global(.ant-table-tbody) {
      overflow-y: unset !important;
      :global(.ant-table-cell) {
        padding: 0;
        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      .row {
        cursor: pointer;
        background-color: var(--white);
      }

      .rowNoHover {
        &:hover > .column,
        &:hover > .centeredColumn,
        &:hover > .fixedHeightColumn {
          background-color: unset;
        }
      }

      .rowWhite {
        .row();
        background-color: var(--white);
      }

      .darkRow {
        .row();
        background-color: var(--lightest-grey) !important;
      }
    }
  }
}
