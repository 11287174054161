@s3-asset-path: "https://powur-public2.s3-us-west-1.amazonaws.com/assets";

/* can be used as the outer container for a standard `page section` panel  */
.baseOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* media queries too pls */
}

/* can be used as the inner container for a standard `page section` panel  */
.baseInnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: @max-container-width;
  min-height: 650px;
  padding: 100px 20px;
  margin: 0 auto;

  /* media queries too pls */
}

/***** FONTS *******************************************/

.headerText {
  font-size: 24pt;
  line-height: 28pt;
  text-align: center;
  font-weight: 400;
  color: var(--text-dark);
  padding-bottom: 25px;
}

.subHeaderText {
  text-align: center;
  font-size: 18pt;
  line-height: 22pt;
  font-weight: 300;
  color: var(--text-dark);
}

.bodyText {
  font-size: 12pt;
  font-weight: 300;
  text-align: center;
  color: var(--text-dark);
}

/***** Other style mixins
*****************************************/

.buttonShadow {
  -webkit-box-shadow: 0px 2px 3px 0px rgba(120, 120, 120, 0.5);
  -moz-box-shadow: 0px 2px 3px 0px rgba(120, 120, 120, 0.5);
  box-shadow: 0px 2px 3px 0px rgba(120, 120, 120, 0.5);
}

.buttonShadowSmaller {
  -webkit-box-shadow: 0px 1px 2px 0px rgba(120, 120, 120, 0.5);
  -moz-box-shadow: 0px 1px 2px 0px rgba(120, 120, 120, 0.5);
  box-shadow: 0px 1px 2px 0px rgba(120, 120, 120, 0.5);
}

.buttonShadowSmallest {
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.messageShadow {
  -webkit-box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.045);
  -moz-box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.045);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.045);
}

.messageButtonShadow {
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.hideWhenSmall {
  @media (max-width: 576px) {
    display: none;
  }
}

.roundButton {
  height: 50px;
  text-transform: uppercase;
  border-radius: 25px;
}

.label {
  font-size: 10px;
  color: var(--slate-grey-a65);
  // text-transform: uppercase;
}

.tableLabel {
  font-size: 12px;
  color: var(--table-grey);
  // text-transform: uppercase;
}

.blue {
  color: var(--primary-color);
}

.bold {
  font-weight: 600;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.border {
  // ONLY FOR TESTING PURPOSES, DON'T ACTUALLY USE THIS BC IT LOOKS LIKE SHIT
  border: 1px solid hotpink;
}

.bgGradientToGrey {
  background: linear-gradient(var(--table-badge-unread) 0%, var(--bleached-silk) 10px, var(--bleached-silk) 100%);
}

.bgGradientToWhite {
  background: linear-gradient(var(--old-grey-highlighted) 0%, white 10px, white 100%);
}

.bgGradientToPurple {
  background-image: linear-gradient(
    95.7deg,
    var(--heliotrope-magenta),
    var(--trusted-purple),
    var(--trusted-purple),
    var(--trusted-purple)
  );
}

.mention {
  background-color: var(--white);
  padding: 1px 3px;
  border-radius: 3px;
  margin: 1px 0;
  color: var(--dark-blue);
  font-weight: 600;
  display: inline-block;

  &:before {
    content: "@";
  }
}

.submitButton {
  font-size: 12px;
}

.sendButton {
  .submitButton();
  .buttonShadow();
}

.submitButtonBlue {
  .submitButton();
  color: var(--primary-color);
}

.disabledButton {
  &[disabled],
  &:disabled {
    background: var(--nepal-blue);
    color: var(--white);

    &:hover {
      background: var(--nepal-blue);
      color: var(--white);
    }
  }
}

.closeButton {
  .submitButton();
  background-color: transparent;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.error {
  position: absolute;
  font-size: 10px;
  color: darken(red, 10%);
  width: calc(100% - 8px);
  background-color: transparent;
}

.columnHeader {
  text-transform: uppercase;
  color: var(--nepal-blue);
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
}

.drawerMask {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-a30);
  overflow: hidden;
  z-index: 1001;
}

.test {
  border: 1px solid red;
}

:global(.ant-btn-primary) {
  text-shadow: none;
}

.bgLightenZoomOnHover {
  background: #000;
  /*
  for consistent behavior for image zoom on hover. ensure that the parent
  container has

    position: relative
    overflow: hidden

  and has as a child element with classname .bg, and specify the background
  image url in that child element.
  */

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    transition: transform 500ms ease-in-out 0s;

    &:hover,
    &:focus {
      transform: scale(1.05);
    }

    &::before {
      content: "";
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      transition: opacity 0.45s ease-in-out;
      background-color: var(--light-grey);
    }
  }

  &:hover .bg:before {
    opacity: 0.3;
  }
}

.bgDarkenZoomOnHover {
  .bgLightenZoomOnHover();

  .bg {
    &::before {
      background-color: #000;
    }
  }

  &:hover .bg:before {
    opacity: 0.1;
  }
}
