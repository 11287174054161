@import (reference) "styles/theme.less";
@import "~animate.less/animate.less";

.closeProjectModal {
  width: 100%;

  .itemContainer {
    position: relative;
    margin-bottom: 30px;

    .label {
      text-transform: uppercase;
      color: var(--dark-blue);
      font-size: 10px;
      font-weight: 600;
      line-height: 9px;
    }

    .selectContainer {
      width: 100%;
      margin-top: 4px;
      display: flex;
      justify-content: center;

      .select {
        width: 100%;
        font-size: 12px;
        color: var(--primary-color);
      }

      .textArea {
        font-size: 12px;
        color: var(--primary-color);
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;