@import (reference) "styles/theme.less";

.tabContainer {
  position: relative;

  .funds {
    font-weight: 500;
    font-size: 18px;
    margin-right: 16px;
    line-height: 21px;
  }

  .actions {
    margin-top: 30px;
    margin-bottom: -46px;

    button:disabled,
    button[disabled] {
      background: var(--nepal-blue);
      color: var(--white);
      &:hover {
        background: var(--nepal-blue);
        color: var(--white);
      }
    }
  }

  .actionsNoData {
    .actions();
    margin-bottom: 16px;
  }

  .actionsMobile {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .projectActions {
    margin-bottom: 20px;
  }

  .actionButton {
    .roundButton();
    z-index: 10;
    margin-left: 6px;
    height: 30px;
    text-transform: none;
    padding: 2px 16px;
    font-size: 12px;
  }

  .actionButtonMobile {
    margin-left: 0;
  }

  .leftButton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .centerButton {
    border-radius: 0;
  }
  .rightButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .smallButton {
    .buttonShadowSmaller();
    height: 20px;
    font-size: 10px;
    line-height: 11px;
    border-radius: 10px;
  }

  .flexCol {
    display: flex;
    flex-direction: column;
  }

  .headerRow {
    margin: 10px 0;
  }
}

.sidebar {
  .filters {
    margin-top: 16px;
  }
}

.pickerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  width: 450px;
  background-color: var(--botticelli-blue);
  border-radius: 4px;

  .rangePicker > div > input {
    font-size: 12px;
  }

  .calendar :global td.ant-picker-cell-in-range::before {
    background-color: darken(#f0fdff, 10%);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;