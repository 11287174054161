@import (reference) "styles/theme.less";

.multiSelectContainer {
  .label {
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    color: var(--nepal-blue);
    text-transform: uppercase;
    margin-bottom: 9px;
  }

  .selectContainer {
    position: relative;

    .caretIcon {
      position: absolute;
      top: 7px;
      right: 12px;
      z-index: 2;
      pointer-events: none;
    }

    .multiSelect {
      :global(.ant-select-selector) {
        height: 32px;
        background: var(--white);
        border: 1px solid var(--botticelli-blue);
        border-radius: 4px;
        padding-right: 38px; // account for caretIcon
        padding-left: 10px; // account for caretIcon
      }

      :global(.ant-select-selection-search-input) {
        margin-left: -5px !important;
      }

      .closeIcon {
        .center();
        height: 100%;
      }

      :global(.ant-select-selection-overflow) {
        height: 32px;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
      }

      :global(.ant-select-selection-item) {
        height: 32px;
        background: transparent;
        border: none;
        border-radius: 4px;
        padding: 0;
        margin: 0;
        margin-top: -4px;
        margin-right: 4px;
      }

      :global(.ant-select-selection-placeholder) {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: var(--dark-blue);
      }

      :global(.ant-select-selection-item-content) {
        .center();
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: var(--dark-blue);
        margin: 0;

        &:not(:last-of-type)::after {
          content: ",";
        }
      }

      :global(.ant-select-selection-item-remove) {
        display: none;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;