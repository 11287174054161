@import (reference) "styles/theme.less";
@import "~animate.less/animate.less";

.seatRequestModal {
  .header {
    width: 75%;
    margin: 0 auto;
    margin-top: 21px;
    margin-bottom: 39px;
    text-align: center;
    span {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
      color: var(--primary-color);
    }
    @media (max-width: 500px) {
      width: 90%;
    }
  }

  .body {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    width: 86%;
    margin: 0 auto;
    margin-bottom: 33px;
    color: var(--darker-blue);
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .body2 {
    .body();
    margin-bottom: 14px;
  }

  .bolded {
    .body();
    font-weight: 700;
  }
}

.seatRequestModalHeader {
  .center();
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  color: var(--dark-blue);
  height: 24px;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;