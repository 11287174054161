@import (reference) "styles/theme.less";

.header {
  height: 64px;
  background: linear-gradient(90.94deg, var(--royal-peacock-blue) 29.17%, var(--holiday-blue) 78.39%);

  .innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: 1280px;

    .logo {
      position: relative;
      top: 2px;
    }

    .supportButton {
      border: none;
      outline: none;
      box-shadow: none;
      text-transform: uppercase;
      background-color: transparent;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
  background-color: var(--cream);
  overflow-x: hidden;
  flex-direction: column;
}

@media (max-height: 849px) {
  .content {
    justify-content: unset;
  }
}

.main {
  width: 432px;
  max-width: 100vw;
  margin: 0 16px;
  padding-top: 48px;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proTip {
  width: 432px;
  max-width: 100vw;
  min-height: 96px;
  margin: 28px 16px 0px 16px;
  background-color: var(--botticelli-green-a25);
  border-radius: 12px;
  display: flex;
  align-items: center;

  .heading {
    align-items: center;
    color: var(--dark-blue);
    font-size: 18px;
    font-weight: 600;
  }
  .body {
    color: var(--dark-blue);
    font-size: 14px;
    padding-right: 19px;
  }
  .bold {
    font-weight: 600;
  }
}

.powur {
  width: 375px;
  height: 80px;
  background-image: url("/img/powur_logo.svg");
  background-size: cover;
  background-position: center 40%;
  background-repeat: no-repeat;
}

.loginContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tabs {
  width: 100%;
  height: 100%;

  .tab {
    width: 100%;
    padding: 32px;
  }
}

.form {
  .formItem {
    margin-bottom: 16px;

    .icon {
      color: var(--botticelli-blue);
    }

    .formLabel {
      font-size: 10px;
      line-height: 9px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--nepal-blue);
    }

    label {
      width: 100%;
    }

    .input {
      border-radius: 3px;
      border: 1px solid var(--botticelli-blue);

      :global(.ant-input-group-addon) {
        background-color: transparent;
      }
    }
  }

  .linkButton {
    color: var(--blue-ribbon);
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
  }

  .controlsRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
  }

  .formItemInline {
    .formItem();
    margin-bottom: 0;
    color: var(--table-grey);
    font-size: 14px;
    line-height: 17px;

    .checkbox {
      .checkLabel {
        margin-right: 9px;
        color: var(--font-color-eastern-blue);
      }
    }
  }

  .loginRow {
    width: 100%;
    .center();

    .submitButton {
      font-family: "Poppins";
      width: 100%;
      height: 42px;
      border-radius: 9px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .returnRow {
    margin-top: 24px;
    width: 100%;
    .center();
  }

  .instructions {
    font-size: 12px;
    line-height: 18px;
    font-style: italic;
    margin-bottom: 8px;
  }

  .oauthRow {
    div > div {
      margin: 15px 0;
    }
  }
}

.resetPasswordForm {
  .form();

  :global(.ant-input-affix-wrapper) {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .validationRules {
    padding: 11px 15px;
    background-color: var(--botticelli-blue-50);
    border: 1px solid var(--botticelli-blue);
    border-radius: 3px;
    margin: 24px 0px;

    .ruleText {
      color: var(--nepal-blue);
      font-weight: 500;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;