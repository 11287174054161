@import (reference) "styles/theme.less";

.header {
  display: flex;
  div {
    align-items: center;
  }
  .heading {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: var(--darker-blue);
  }
}

.modal {
  :global(.ant-modal-content) {
    min-width: 680px;
    @media (max-width: 768px) {
      min-width: 0px;
    }
  }
  :global(.ant-modal-body) {
    padding: 0;
  }
  width: 620px !important;
  :global(.ant-modal-footer) {
    height: 68px;
  }
  :global(.ant-modal-close) {
    height: 36px;
    :global(.ant-modal-close-x) {
      font-size: 14px;
    }
  }
}
.popconfirmContainer {
  :global(.ant-popover-arrow) {
    display: none;
  }
  :global(.ant-popover-inner) {
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  }
  :global(.ant-popover-message-title) {
    padding-left: 0;
  }
  :global(.ant-popover-inner-content) {
    padding: 0;
    border-radius: 12px;
  }
  :global(.ant-popover-buttons) {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
  :global(.ant-btn) {
    height: 32px;
    min-width: 78px;
    border-radius: 36px;
    min-width: 163px;
    border-color: var(--royal-peacock-blue);
    color: var(--royal-peacock-blue);
  }
  :global(.ant-btn-primary) {
    color: var(--white);
    margin-right: 8px;
  }
}

.acceptedPopconfirmContainer {
  .popconfirmContainer();
  :global(.ant-btn-primary) {
    display: none;
  }
}

.popconfirm {
  color: var(--darker-blue);
  .title {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 100%;
    background-color: var(--alabaster-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
  }
  .subtitle {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 28px 0;
  }
  .info {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    display: flex;
    justify-content: center;
    max-width: 80%;
    margin: auto;
    padding: 8px 0;
    &:last-of-type {
      padding-bottom: 36px;
    }
  }
  .bigBold {
    .bold();
    font-size: 18px;
  }
  .checkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 10px;

    .check {
      background-image: url("/icons/icon_green_check.svg");
      background-color: var(--harlequin-green);
      width: 51px;
      height: 51px;
      border-radius: 50%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }
}

.advertModal {
  width: 100%;
  background-image: url("/img/powur-live.png");
  background-position: -1px 0;

  .mainText {
    width: 80%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    text-align: center;
    color: var(--white);
    margin: auto;
    padding-top: 80px;
  }

  .subText {
    width: 90%;
    margin: auto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--white);
    padding-top: 12px;
    padding-bottom: 80px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--light-cyan);
    margin: 0px 14px;
    padding-bottom: 30px;

    .column {
      padding-top: 7px;
      border-top: 2px solid var(--light-cyan);
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;