@import (reference) "styles/theme.less";

@transition: all 0.1s ease-in-out;

.searchStyle {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--font-color-eastern-blue);
  border-radius: 0;
  padding: 0;
  width: 175px;

  &:focus,
  &:focus-within {
    box-shadow: none;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--font-color-eastern-blue);
  }
}

.inputStyle {
  width: 100%;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  border: none;
  margin-top: 4px;
  color: var(--font-color-eastern-blue);
  font-size: 12px;
  font-weight: 600;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.tinyButtonStyle {
  height: 18px;
  width: 18px;
  background-color: var(--font-color-eastern-blue);
  border: none;
  border-radius: 3px;
  .center();
  transition: @transition;

  .icon {
    color: var(--white);
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
  }
}

.searchContainer {
  margin-bottom: 40px;
  width: 100%;

  .label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    color: var(--nepal-blue);
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .search {
    .searchStyle();
  }

  .search > input {
    .inputStyle();
  }

  .inlineSubmitButton {
    .tinyButtonStyle();
  }

  .inlineClearButton {
    .inlineSubmitButton();
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    transition: @transition;

    .closeIcon {
      color: var(--dark-blue);
      font-size: 10px;
      transition: @transition;
    }
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      width: 75px;
      border-radius: 25px;
      font-size: 12px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    }

    .searchButton {
      .button();
      max-height: 24px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    }

    .clearButton {
      .button();
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      color: var(--primary-color);
      text-transform: uppercase;
      padding: 12px;
      max-width: 24px;
      max-height: 24px;
    }
  }
}

.searchBadge {
  display: inline-block;
  position: relative;
  top: -5px;
  margin: -1px 0px 0px 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: var(--dark-blue);
  text-align: center;
  font-size: 8px;
  line-height: 10px;
  background-color: var(--botticelli-blue);
  cursor: pointer;
}

:global(.searchTip) {
  :global(.ant-popover-content) {
    letter-spacing: 0.3px;

    :global(.ant-popover-arrow) {
      background-color: var(--dark-blue);
      border: 0px solid var(--dark-blue);
      outline-color: var(--dark-blue);
      border-radius: 0px;
      width: 12px;
      height: 12px;
      z-index: -1;
      position: absolute;
      top: 10px !important;
      left: 15px;
      transform: rotate(45deg);
    }

    :global(.ant-popover-inner) {
      border-radius: 2px;
      background-color: var(--dark-blue);

      .title {
        font-weight: 600;
        color: var(--web-orange);
        font-size: 12px;
        text-transform: uppercase;
      }

      .contentRow {
        color: var(--white);
        font-weight: 400;
        font-size: 12px;

        .bold {
          font-weight: 600;
        }
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;