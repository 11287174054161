@import (reference) "styles/theme.less";

.item {
  padding-bottom: 0;
  margin-bottom: 0 !important;

  .label {
    text-transform: uppercase;
    color: var(--nepal-blue);
    font-size: 10px;
    font-weight: 700;
    line-height: 9px;
  }

  :global(.ant-legacy-form-item-label > label::after) {
    display: none;
  }
}

.error {
  position: absolute;
  font-size: 10px;
  color: var(--congo-pink);
  width: calc(100% - 8px);
  background-color: transparent;
}

.inputItem {
  .item();

  .input {
    background-color: var(--white);
    border: 1px solid var(--botticelli-blue);
    border-radius: 4px;
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    height: 32px;
    width: 100%;
  }

  :global(.ant-input-number-input) {
    font-weight: 500;
  }

  .select {
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    border: 1px solid var(--botticelli-blue);
    height: 32px;
    border-radius: 3px;
    text-transform: capitalize;
    width: 100%;

    .caretIcon {
      color: var(--font-color-eastern-blue);
      padding-left: 4px;
    }

    :global(.ant-select-arrow) {
      :global(.anticon:not(.ant-select-suffix)) {
        pointer-events: none;
      }
    }

    :global(.ant-select-selection-item) {
      display: flex;
      align-items: center;
      margin-right: 5px;
      height: 24px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      border: none;
      :global(.ant-select-selection-item-content) {
        margin-right: 8px;
      }
    }

    :global(.ant-select-selection-item-remove) {
      svg {
        fill: var(--dark-blue);
      }
    }

    :global(.ant-select-suffix) {
      color: var(--font-color-eastern-blue);
    }

    :global(.ant-select-selector) {
      border: none;
      height: 30px;
    }
  }
}

.selectItem {
  .item();
}

.autoCompleteContainer {
  .autoComplete {
    width: 100%;
  }

  .input {
    input {
      color: var(--font-color-eastern-blue);
      font-size: 12px;
      font-weight: 600;
    }

    .prefix {
      margin-top: 2px;
      margin-left: 2px;
    }
  }
}

.autoCompleteDropdownUserDisplay {
  width: 100%;
  display: flex;

  .avatarContainer {
    margin-right: 8px;
  }

  .nameContainer {
    color: var(--slate-grey);
    font-size: 12px;
    font-weight: 600;
  }
}

.formSectionContainer {
  margin-top: 15px;
  margin-bottom: 25px;
  position: relative;

  .cardContainer {
    width: 100%;
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 0px 0px 12px 12px;
    background-color: var(--white) !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);

    .formRow {
      position: relative;
      width: 100%;
      margin-bottom: 4px;
    }
  }
  .cardContainerNoPadding {
    .cardContainer();
    padding: 0 20px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;