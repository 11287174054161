@import (reference) "styles/theme.less";

.loadingSpinner {
  .center();
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color-a10);
  z-index: 5;

  .spinner {
    z-index: 6;

    :global(.ant-spin-dot-item) {
      &:first-of-type {
        background: var(--blue-ribbon);
      }
      &:nth-of-type(2) {
        background: var(--sweet-mint);
      }
      &:nth-of-type(3) {
        background: var(--picton-blue);
      }
      &:nth-of-type(4) {
        background: var(--goldenrod);
      }
    }
  }
}

.bgWhite {
  background-color: var(--white);
}

.loadingSpinnerNoMask {
  .loadingSpinner();
  background-color: unset;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;