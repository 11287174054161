@import (reference) "styles/theme.less";

.invitesTab {
  margin-top: 20px;
}

.modal {
  z-index: 1001;

  .titleContainer {
    .titleIcon {
      font-size: 18px;
      color: var(--primary-color);
      font-weight: 600;
    }

    .title {
      font-size: 16px;
      margin-left: 5px;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  .leftCol {
    .col();
    align-items: flex-end;
    padding-right: 10px;
  }

  .rightCol {
    .col();
  }

  .value {
    font-weight: 600;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 32px;
  }

  .button {
    text-transform: uppercase;
    height: 32px;
    width: 150px;
    margin: 5px;
  }

  .cancelButton {
    .button();
    background: transparent;
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;