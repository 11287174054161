@import (reference) "styles/theme.less";

@font-face {
  font-family: "Bebas";
  src: url(/fonts/Bebas-Regular.otf);
}

.counterContainer {
  @counter-box-height: 106.04px;
  @counter-margin: 2px;
  @animate-timing: cubic-bezier(0.42, -0.65, 0.58, 1.58);

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--white);
  font-family: "Bebas";
  font-size: 75px;
  padding: 40px 0px;

  .animateEnter {
    transform: translateY(@counter-box-height);
  }

  .animateEnterActive {
    transform: translateY(0);
    transition: transform 300ms @animate-timing;
  }

  .animateExit {
    transform: translateY(0);
  }

  .animateExitActive {
    transform: translateY(-@counter-box-height);
    transition: transform 300ms @animate-timing;
  }
}

.errorMessage {
  font-size: 14px;
  font-family: monospace;
}

.box {
  position: relative;
  z-index: 3;
  margin-right: 5px;
  width: 75.21px;
  height: 106.04px;
  background-color: var(--raisin-black);
  overflow: hidden;
  border-radius: 4px;
  display: flex;

  max-height: 112px; // fontsize 75px * lineheight 1.5 = 112px
  @media (max-width: 992px) {
    max-height: 97px;
    width: 68.8px;
  }
  @media (max-width: 768px) {
    max-height: 77.55px;
    width: 55px;
  }
  @media (max-width: 600px) {
    height: 56.39px;
    width: 40px;
    max-height: 80px;
  }
  @media (max-width: 480px) {
    max-height: 46.52px;
    width: 33px;
    margin-right: 3px;
  }
}

.num {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  color: var(--white);
  font-family: "Oswald", "Barlow", sans-serif;
  font-weight: 500;
  font-size: 75.91;
  line-height: 49px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--raisin-black);

  margin-right: 5px;
  border-radius: 4.46549px;
  .center();

  @media (max-width: 992px) {
    font-size: 65px;
  }
  @media (max-width: 768px) {
    font-size: 55px;
  }
  @media (max-width: 600px) {
    font-size: 41.56px;
  }
  @media (max-width: 480px) {
    font-size: 35px;
  }
}

.comma {
  width: 17.81px;
  height: 115.86px;

  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 78.146px;
  line-height: 120px;
  /* identical to box height */

  text-transform: uppercase;

  color: var(--raisin-black);
  margin: 0;
  margin-right: 3px;
  @media (max-width: 992px) {
    max-height: 97px;
    line-height: 110px;
    font-size: 70.146px;
  }
  @media (max-width: 768px) {
    max-height: 82px;
    line-height: 82px;
    font-size: 62.146px;
    width: 15px;
  }
  @media (max-width: 600px) {
    max-height: 75px;
    line-height: 62px;
    font-size: 42.146px;
    width: 11px;
  }
  @media (max-width: 480px) {
    max-height: 52px;
    line-height: 50px;
    margin-right: 1px;
    font-size: 37px;
    width: 10px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;