@import (reference) "styles/theme.less";

.filterTagShared {
  display: flex;
  justify-content: space-between;
  padding-left: 13px;
  padding-right: 7px;
  align-items: center;
  height: 28px;
  background: var(--white);
  box-shadow: none;
  border-radius: 36px;
  margin: 0 8px 8px 0;
  cursor: pointer;

  span {
    line-height: 15px;
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 500;
    margin-right: 16px;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .closeIcon {
    cursor: pointer;
    height: auto;
    .center();
  }
}

.searchActive {
  width: 148px;
  height: 28px;
  background-color: var(--cotton-boll-highlighted);
  border-radius: 3px;
  margin-right: 12px;
  box-shadow: none;
  border: none;
  padding-left: 8px;

  .lineContainer {
    display: flex;
    align-items: center;
    .verticalLine {
      background: var(--font-color-eastern-blue);
      height: 20px;
      width: 1px;
      margin-right: 8px;
      margin-left: 7px;
      border-radius: 2px;
    }
  }

  input {
    background-color: var(--cotton-boll-highlighted);
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px var(--cotton-boll-highlighted) inset;
  }
}

.search {
  .searchActive();
  background-color: var(--cotton-boll);

  &:hover {
    background: var(--cotton-boll-highlighted);
    input {
      background: var(--cotton-boll-highlighted);
    }
  }

  input {
    background: var(--cotton-boll);
    &:hover {
      background: var(--cotton-boll-highlighted);
    }
  }

  input::placeholder {
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
}

.smallSearch {
  .center();
  width: 28px;
  height: 28px;
  background: var(--table-edit-mode);
  box-shadow: none;
  border-radius: 3px;
  transition: 0.2s all;
  margin-right: 6px;
}

.smallSearchOpen {
  .smallSearch();
  background: var(--dark-blue);
}

.smallSearchLight {
  .smallSearch();
  background: var(--white);
  border: 0.5px solid var(--primary-color);

  svg {
    * {
      fill: var(--primary-color);
    }
  }
}

.searchFilterCSV {
  max-width: 100%;
  .mainRow {
    display: flex;
    align-items: center;

    .filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--cotton-boll);
      width: 148px;
      height: 28px;
      box-shadow: none;
      border-radius: 3px;
      cursor: pointer;
      padding: 8px;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          padding-top: 5px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: var(--font-color-eastern-blue);
          margin-left: 6px;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .line {
          height: 20px;
          width: 1px;
          border-radius: 32px;
          background: var(--font-color-eastern-blue);
          margin-left: 5px;
        }

        .downCaret {
          .center();
          height: 100%;
          margin-left: 8px;
        }

        .number {
          color: var(--font-color-eastern-blue);
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          padding-top: 1px;
          margin-left: 5px;
        }
      }

      @media (max-width: 855px) {
        width: unset;
      }
    }
    .filterHover {
      background: var(--cotton-boll-highlighted);
      transition: 0.2s all;

      .left {
        .icon {
          padding-top: 5px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: var(--dark-blue);
          margin-left: 6px;
        }
      }
      .leftHover {
        .icon {
          padding-top: 5px;
        }

        span {
          color: var(--dark-blue);
        }
      }

      .right {
        display: flex;
        align-items: center;

        .line {
          height: 20px;
          width: 1px;
          border-radius: 32px;
          background: var(--dark-blue);

          margin-left: 5px;
        }

        .downCaret {
          .center();
          height: 100%;
          margin-left: 8px;
        }

        .number {
          color: var(--dark-blue);
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          padding-top: 1px;
          margin-left: 5px;
        }
      }
      .rightHover {
        .line {
          background: var(--dark-blue);
        }

        .downCaret {
          .center();
        }

        .number {
          color: var(--dark-blue);
        }
      }
      @media (max-width: 855px) {
        width: unset;
      }
    }
    .filterActive {
      background: var(--dark-blue);

      .left {
        .icon {
          padding-top: 5px;
        }

        span {
          color: white;
        }
      }
      .right {
        .line {
          background: white;
        }

        .number {
          color: white;
        }
      }
    }
    .filterHoverActive {
      background: var(--dark-blue-highlighted);

      .left {
        .icon {
          padding-top: 5px;
        }

        span {
          color: white;
        }
      }

      .right {
        .line {
          background: white;
        }

        .downCaret {
          .center();
        }

        .number {
          color: white;
        }
      }

      @media (max-width: 855px) {
        width: unset;
      }
    }

    .filterOpen {
      .filter();
      background: var(--dark-blue);
    }

    .csvButton {
      width: 104px;
      height: 28px;
      background: var(--table-edit-mode);
      box-shadow: none;
      border-radius: 80px;
      border: none;
      margin-left: 24px;
      .center();

      span {
        color: var(--font-color-eastern-blue);
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
      }

      @media (max-width: 775px) {
        width: 56px;
        margin-left: 12px;
      }

      @media (max-width: 500px) {
        margin-left: 8px;
      }
    }
  }

  .smallSearchContainer {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  .customSearch {
    margin-right: 6px;
  }

  .filterSetRow {
    padding: 2px 0px 5px 0px;
    margin-top: 9px;
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
      background: transparent;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--table-edit-mode);
      border-radius: 8px;
    }

    .filterTag {
      .filterTagShared();
      background: var(--cotton-boll);
      margin: 0 7px 0 0;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;