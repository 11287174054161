@import (reference) "styles/theme.less";

.enterpriseProjectsSidebar {
  .filters {
    margin-bottom: 32px;
  }
}

.contractsCSV {
  margin: 50px 0;
  width: 100%;

  .label {
    padding-top: 4px;
    font-size: 9px;
    font-weight: 700;
    line-height: 12px;
    color: var(--gothic-blue);
    text-transform: uppercase;
  }

  .subLabel {
    .label();
    // font-style: oblique;
  }

  .input {
    width: 100%;
    padding: 0;
    margin-top: 2px;
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--primary-color);

    input {
      background: transparent;
      color: var(--font-color-eastern-blue);
      font-size: 14px;
      font-weight: 600;
      line-height: 31px;
    }

    .inputIcon {
      color: var(--dark-blue);
      svg {
        height: 10px;
        width: 10px;
      }
    }

    &::placeholder {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: var(--nepal-blue);
    }

    &:global(.ant-input-affix-wrapper) {
      box-shadow: none;
      outline: none;
    }
  }

  .button {
    .center();
    margin-top: 6px;
    height: 24px;
    background: var(--font-color-eastern-blue);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    color: var(--white);
  }
}

:global(.searchTip) {
  :global(.ant-popover-content) {
    letter-spacing: 0.3px;
    :global(.ant-popover-arrow) {
      background-color: var(--dark-blue);
      border: 0px solid var(--dark-blue);
      outline-color: var(--dark-blue);
      border-radius: 0px;
      width: 12px;
      height: 12px;
      z-index: -1;
      top: 4px;
      left: 15px;
      transform: rotate(45deg);
    }
    :global(.ant-popover-inner) {
      border-radius: 2px;
      background-color: var(--dark-blue);

      .title {
        font-weight: 600;
        color: var(--web-orange);
        font-size: 12px;
        text-transform: uppercase;
      }

      .contentRow {
        color: var(--white);
        font-weight: 400;
        font-size: 12px;

        .bold {
          font-weight: 600;
        }
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;