@import (reference) "styles/theme.less";

.paymentModal {
  position: relative;

  .header {
    justify-content: center;
    color: var(--white);
    height: 82px;
    background: linear-gradient(90deg, var(--ferrari-red) 0%, var(--pumpkin) 100%);
    display: flex;
    align-items: center;
    > span:first-child {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  :global(.ant-modal-header) {
    padding: 0px;
    overflow: hidden;
  }

  :global(.ant-modal-body) {
    border-radius: 12px;
    padding: 0;
  }

  .contentRow {
    padding: 40px 127px 100px 127px;
    flex-direction: column;
    > div:last-child {
      text-align: center;
      font-size: 20px;
      font-family: "Barlow";
    }
    > div:nth-child(2) {
      text-transform: capitalize;
      font-weight: 700;
      margin-top: 20px;
      font-size: 20px;
      font-family: "Barlow";
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;