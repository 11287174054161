/* enter and exit animations for preview rows */

.file-uploader-preview-animation-classes-4RV1B-enter {
  transform: translate(-100%);
}

.file-uploader-preview-animation-classes-4RV1B-enter-active {
  transform: translate(0);
  transition: all 300ms ease-in-out;
}

.file-uploader-preview-animation-classes-4RV1B-exit {
  transform: translate(0);
}

.file-uploader-preview-animation-classes-4RV1B-exit-active {
  transform: translate(100%);
  transition: all 300ms ease-in-out;
}

/* switch animation between filename input and progress bar  */
.file-uploader-filename-input-progress-switch-animations-U7VV2-enter {
  opacity: 0;
}

.file-uploader-filename-input-progress-switch-animations-U7VV2-enter-active {
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.file-uploader-filename-input-progress-switch-animations-U7VV2-exit {
  opacity: 1;
}

.file-uploader-filename-input-progress-switch-animations-U7VV2-exit-active {
  opacity: 0;
  transition: all 300ms ease-in-out;
}
