@import (reference) "styles/theme.less";

.sideBarContainer {
  position: relative;
  .collapse {
    position: absolute;
    top: 38px;
    right: -7px;
    width: 16px;
    height: 16px;
    border: 1px solid var(--light-grey);
    color: rgba(0, 0, 0, 0.65);
    border-radius: 100%;
    .center();
    z-index: 10;
    cursor: pointer;
    box-shadow: none;

    &:hover {
      box-shadow: 0px 0px 1px 0px var(--nepal-blue);
      transition: box-shadow 0.2 ease-in-out;
    }
  }
  .closedCollapse {
    .collapse();
    right: -14px;
  }
}
.sideBar {
  position: relative;
  background-color: var(--table-edit-mode);
  height: 100%;
  border-right: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;

  &::-webkit-scrollbar {
    width: 3px;
    background: var(--athens-gray);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--light-grey);
    border-radius: 10px;
  }
}

.collapseSmallScreen {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .buttonShadow();

  .icon {
    font-size: 24px;
    color: var(--white);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;