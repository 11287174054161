@import (reference) "styles/theme.less";

.certificationBannerContainer {
  width: 100%;
  height: 32px;
  background: var(--white);
  cursor: pointer;

  .inner {
    height: 100%;
    width: 100%;
    background: linear-gradient(95.7deg, rgba(203, 255, 243, 0.83) 12.17%, rgba(81, 242, 203, 0.83) 93.24%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px 0 11px;

    .sharedText {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
    }

    .mortar {
      padding-top: 5px;
    }

    .left {
      display: flex;
      align-items: center;

      .title {
        background: linear-gradient(95.7deg, var(--maya-blue) 12.17%, var(--blue-ribbon) 93.24%);
        font-family: Poppins;
        .sharedText();
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 4px;
      }
    }

    .right {
      .title {
        .sharedText();
        color: var(--dark-blue);
      }

      .arrow {
        margin-left: 7px;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;