@import (reference) "styles/theme.less";

.languageLabel {
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 14px;
  line-height: 25px;
  color: var(--table-grey);
  font-weight: 300;
}

.optionContent {
  display: flex;
  align-items: center;
}

.languageDropdown {
  background: var(--athens-gray);
  border: 1px solid var(--loblolly-gray);
  border-radius: 4px;

  :global(.ant-select-item-option) {
    &:not(:last-child) {
      border-bottom: 1px solid var(--loblolly-gray);
    }
  }

  :global(.ant-select-item-option-active:not(.ant-select-item-option-disabled)) {
    background: var(--athens-gray);
  }
}

.languageSelect {
  .selectLabel {
    display: flex;
    align-items: center;
    height: 32px;
    width: 90px;

    .icon {
      width: 16px;
      height: 16px;
      color: var(--loblolly-gray);
      background-image: url("/icons/icon_language_select.svg");
      margin-right: 6px;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;