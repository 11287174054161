@import (reference) "styles/theme.less";

.autoComplete {
  margin-bottom: 20px;
  margin-top: 4px;

  div {
    background-color: transparent;
  }
}

.label {
  text-transform: uppercase;
  line-height: 12px;
  letter-spacing: 0em;
  font-size: 10px;
  font-weight: 700;
  color: var(--nepal-blue);
}

.input {
  background-color: transparent;

  input {
    padding-left: 0;
    border: none;
    border-bottom: 1px solid var(--primary-color);
    border-radius: 0;
    color: var(--font-color-eastern-blue) !important;
    font-size: 12px;
    font-weight: 600;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;